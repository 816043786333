import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyCouponsListComponent} from './my-coupons-list/my-coupons-list.component';
import {IvyCarouselModule} from "angular-responsive-carousel";
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import { MyCouponComponent } from './my-coupons-list/my-coupon/my-coupon.component';

@NgModule({
  declarations: [
    MyCouponsListComponent,
    MyCouponComponent
  ],
    imports: [
        CommonModule,
        IvyCarouselModule,
        CtSkeletonModule,
        CtButtonModule,
        CtFrameworkModule
    ],
  exports: [
    MyCouponsListComponent
  ]
})
export class MyCouponsListModule {
}
