import { TemplateRef } from "@angular/core";

export class CtExpansionPanelParameter {
    private _content!: string;
    public get content(): string {
        return this._content;
    }
    public set content(value: string) {
        this._content = value;
    }
    private _expand!: boolean;
    public get expand(): boolean {
        return this._expand;
    }
    public set expand(value: boolean) {
        this._expand = value;
    }


    private _templateRef: TemplateRef<any> | null;
    public get templateRef(): TemplateRef<any> | null {
        return this._templateRef;
    }
    private set templateRef(value: TemplateRef<any> | null) {
        this._templateRef = value;
    }

    private _title?: string | undefined;
    public get title(): string | undefined {
        return this._title;
    }
    private set title(value: string | undefined) {
        this._title = value;
    }

    private _description?: string | undefined;
    public get description(): string | undefined {
        return this._description;
    }
    private set description(value: string | undefined) {
        this._description = value;
    }

    private constructor(content:string){
        this._content = content;
        this._expand = false;
        this._templateRef = null;
    }

    static Create(content:string):CtExpansionPanelParameter{
        return new CtExpansionPanelParameter(content);
    }

    setExpand(value:boolean):CtExpansionPanelParameter{
        this.expand = value;
        return this;
    }

    setTemplateRef(value: TemplateRef<any>): CtExpansionPanelParameter{
        this.templateRef = value;
        return this;
    }

    setTitle(value: string): CtExpansionPanelParameter{
        this.title = value;
        return this;
    }

    setDescription(value: string): CtExpansionPanelParameter{
        this.description = value;
        return this;
    }



    headerShow():boolean{
        if (this.title || this.description)
            return true;
        return false;
    }
}
