<div class="complete-personal-data-entry-cntr">

  <div class="d-flex justify-content-between align-content-center mb-4">

    <h1 class="primary-color mt-auto mb-auto" [innerHTML]="'SIGN-IN.COMPLETE-DATA-TITLE' | translate"></h1>

     <mat-icon class="primary-color close-info-window-icon mt-auto mb-auto" (click)="close()">close</mat-icon>

  </div>

  <h3 [innerHTML]="'SIGN-IN.DATA-ENTRY' | translate"></h3>

    <div class="text-center mt-4">

      <ct-button [parameters]="editProfileButton"></ct-button>

    </div>
  
</div>
