import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICategory} from "../../../../../core/interfaces/category";
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {CategoryController} from "../../../../../core/controllers/category.controller";
import {CategoriesService} from "../../categories.service";

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  @Output() update: EventEmitter<ICategory | null> = new EventEmitter<ICategory | null>();
  @Input() filtersPanel: boolean = false;

  categoryListButtons: Array<CtButtonParameters> = [];

  viewModel = {

    loading: false

  }

  constructor(
    private categoryController: CategoryController,
    private categoriesService: CategoriesService,) {
  }

  ngOnInit(): void {

    this.getCategories();

  }

  getCategories() {

    this.categoryListButtons = [];
    this.viewModel.loading = true;

    this.categoryController
      .list()
      .then((categories: ICategory[]) => {

        categories.forEach((element: ICategory) => {

          this.categoryListButtons
            .push(this.categoriesService.getButton('CATEGORY.' + element.Code, element.Code, () => this.select(element)));

        });

        if (!this.filtersPanel) {

          this.categoryListButtons
            .push(this.categoriesService.getButton("CTBUTTON.SHOW", "group-points", () => this.select(null)));

        }

        this.viewModel.loading = false;

      });

  }

  select(value: ICategory | null) {

    if (!value) {

      this.getCategories();

    } else {

      this.setValue(value.Code);

    }

    this.update.emit(value);

  }

  setValue(code: string) {

    this.categoryListButtons
      .forEach((button: CtButtonParameters) => this.categoriesService.removeActiveClass(button));

    const toActivate: CtButtonParameters | undefined = this.categoryListButtons.find((button: CtButtonParameters) => button.cssStyle.includes(code));

    if (toActivate) {

      this.categoriesService.activate(toActivate, code);

    }

  }

}
