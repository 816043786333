import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignUpComponent} from './sign-up.component';
import {RegistrationFormModule} from './registration-form/registration-form.module';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {RouterModule} from '@angular/router';
import {RegistrationWelcomeModule} from './registration-welcome/registration-welcome.module';
import {RegistrationWelcomeComponent} from "./registration-welcome/registration-welcome.component";

@NgModule({
  imports: [
    CommonModule,
    RegistrationFormModule,
    RegistrationWelcomeModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: 'welcome',
        component: RegistrationWelcomeComponent
      },
      {
        path: '',
        component: SignUpComponent
      },
    ])
  ],
  declarations: [SignUpComponent]
})
export class SignUpModule {
}
