import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationContentComponent} from './notification-content.component';
import {MatIconModule} from '@angular/material/icon';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    CtFrameworkModule,
    MatIconModule
  ],
  declarations: [NotificationContentComponent],
  exports: [NotificationContentComponent]
})
export class NotificationContentModule {
}
