import {Component, OnInit} from '@angular/core';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {Subscription} from 'rxjs';
import {JwtService} from "../../../../../../core/library/jwt.service";

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {

  subscription: Subscription;
  viewModel: any = {

    name: null,
    surname: null,
    email: null

  }

  constructor(
    private ctAuthenticationService: CtAuthenticationService,
    public jwt: JwtService
  ) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        this.viewModel.name = this.jwt.getName();
        this.viewModel.surname = this.jwt.getSurname();
        this.viewModel.email = this.jwt.getEmail();

      });

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {

    this.subscription
      .unsubscribe();

  }

}
