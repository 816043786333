<div class="savings-cntr">

    <div class="row">

        <h1 class="primary-color text-center mt-3" [innerHTML]="['SAVINGS.TITLE'|translate]"></h1>

        <!-- <div class="col-5 ps-0 pe-0"> -->

            <div class="saving-content d-flex justify-content-center">

                <p class="me-2" [innerHTML]="['SAVINGS.SUB_TITLE'|translate]"></p>
                
               <p><b>{{ totalEarnings | currency:'EUR':'symbol':'1.0' }}</b></p> 

            </div>
<!-- 
        </div> -->

        <div class="col-12 ps-0 pe-0">

            <app-chart [chartType]="chartType" [chartData]="chartData" [chartOptions]="chartOptions"></app-chart>

        </div>

    </div>

</div>