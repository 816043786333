import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Subscription} from "rxjs";

export enum ORDER_FILTER {

  IS_OPEN = 'IsOpen',
  LATEST = 'Latest',
  FAVORITES = 'Favorites',
  SPECIAL_DISCOUNT = 'SpecialDiscount',
  ONLY_FAVORITES = 'OnlyFavorites'

}

export const ORDER_FILTERS: { key: string, value: ORDER_FILTER }[] = [
  {key: 'FILTER.OPEN', value: ORDER_FILTER.IS_OPEN},
  {key: 'FILTER.LATEST', value: ORDER_FILTER.LATEST},
  {key: 'FILTER.SPECIAL-DISCOUNT', value: ORDER_FILTER.SPECIAL_DISCOUNT}
];

@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.scss']
})
export class OrderFilterComponent implements OnDestroy {

  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  subscription?: Subscription;

  control: FormControl = new FormControl(null);

  options: { key: string, value: ORDER_FILTER }[] = ORDER_FILTERS;

  constructor(private ctAuthenticationService: CtAuthenticationService) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => this.enableFavoritesFilters(this.ctAuthenticationService.userValue));

  }

  ngAfterViewInit() {

  }

  enableFavoritesFilters(logged: boolean = false) {

    const FAVORITES_FILTER: { key: string, value: ORDER_FILTER } = {
      key: 'FILTER.FAVORITES',
      value: ORDER_FILTER.FAVORITES
    };

    this.options = ORDER_FILTERS;

    if (logged && !this.options.find((elm: { key: string, value: ORDER_FILTER }) => elm.value === ORDER_FILTER.FAVORITES)) this.options.push(FAVORITES_FILTER);

  }

  initValue = (value: ORDER_FILTER) => this.control.setValue(value);

  selectValue = () => this.update.emit(this.control.value);

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
