import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CtFileManagementService } from '@ctsolution/ct-file-management';
import { Product } from 'src/app/core/classes/product';
import { FileType } from 'src/app/core/enum/file-type.enum';
import { AppGeneralService } from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-map-info-window',
  templateUrl: './map-info-window.component.html',
  styleUrls: ['./map-info-window.component.scss']
})
export class MapInfoWindowComponent implements OnInit {


  @Input() detailCard!: Product

  constructor(
    private appGeneralService: AppGeneralService,
    private ctFileManagementService: CtFileManagementService
  ) { }
  ngOnInit(): void {
  }

  getUrlImage():string{
    if (this.detailCard && this.detailCard.Files && this.detailCard.Files?.length > 0) {
      return this.ctFileManagementService.getFilesFromListByType(this.detailCard.Files, FileType.LOGO)?.shift()?.PreviewUrl ?? "";
    } 
    
    return "/assets/images/placeholder-images/generic-logo/logo-w-background.png"
    
  }

  goInfoDetail() {

    this.appGeneralService.navigateTo('/research/list/detail', { queryParams: { id: this.detailCard.Id } });

  }

}
