import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {UserPointBalanceComponent} from '../user-point-balance/user-point-balance.component';
import {VpointLabelModule} from "../../../../components/vpoint-label/vpoint-label.module";

@NgModule({
  imports: [
    CommonModule,
    CtFrameworkModule,
    VpointLabelModule
  ],
  declarations: [UserPointBalanceComponent],
  exports: [UserPointBalanceComponent]
})
export class UserPointBalanceModule {
}
