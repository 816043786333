import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { AppGeneralService } from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-no-vpoints-dialog',
  templateUrl: './no-vpoints-dialog.component.html',
  styleUrls: ['./no-vpoints-dialog.component.scss']
})
export class NoVpointsDialogComponent implements OnInit {

  btnRedirectHome: CtButtonParameters = CtButtonParameters
    
  .create(() =>{ this.general.navigateTo('/home')})
  .setCSS("generalButton box-shadow mt-4 mb-4", true)
  .setContent("CTBUTTON.BACKHOME")

  constructor(
    public dialogRef: MatDialogRef<NoVpointsDialogComponent>,
    private general: AppGeneralService
  ) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
