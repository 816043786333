import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import HomeComponent from './home.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {SearchBarModule} from 'src/app/components/search-bar/search-bar.module';
import {ButtonSectionModule} from './button-section/button-section.module';
import {CtCarouselIvyModule} from "../../components/ct-framework/ct-carousel-ivy/ct-carousel-ivy.module";
import {CtCardModule} from "../../components/ct-framework/ct-card/ct-card.module";
import { CompletePersonalDataEntryModule } from './complete-personal-data-entry/complete-personal-data-entry.module';

@NgModule({
  imports: [

    CommonModule,
    CtButtonModule,
    SearchBarModule,
    ButtonSectionModule,
    CtCarouselIvyModule,
    CtCardModule,
    CtFrameworkModule,
    CompletePersonalDataEntryModule

  ],
  declarations: [
    HomeComponent],

  exports: [HomeComponent]
})
export class HomeModule {
}
