import {Component, OnInit} from '@angular/core';
import {CategoriesService} from "./categories.service";
import {ICategory} from "../../../core/interfaces/category";

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  constructor(private categoriesService: CategoriesService) {
  }

  ngOnInit() {

  }

  searchByCategory(category: ICategory | null = null) {

    this.categoriesService.goToSearch(category);

  }

}
