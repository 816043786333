<div class="sign-in-cntr container mt-4">

  <div class="row">

    <h1 class="text-center mb-4" [innerHTML]="['LOGIN.SIGN_UP'|translate]"></h1>

    <app-registration-form></app-registration-form>

  </div>

</div>
