import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoSectionComponent} from './info-section.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from "@angular/router";
import {ShopTimeTableModule} from "./shop-time-table/shop-time-table.module";
import {MatDialogModule} from "@angular/material/dialog";
import {PriceCategoryButtonModule} from "../../../_components/price-category-button/price-category-button.module";
import {
  PriceFilterModule
} from "../../../search-list/_components/search-filters/_components/price-filter/price-filter.module";

@NgModule({
  imports: [
    CommonModule,
    CtFrameworkModule,
    CtButtonModule,
    MatIconModule,
    RouterModule,
    PriceFilterModule,
    MatIconModule,
    ShopTimeTableModule,
    MatDialogModule,
    PriceCategoryButtonModule
  ],
  declarations: [InfoSectionComponent],
  exports: [InfoSectionComponent]
})
export class InfoSectionModule {
}
