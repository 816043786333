import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TransactionStatus} from "../../../../../../../../core/enum/transaction-status.enum";
import {CtMapsService, ILocation} from "@ctsolution/ct-maps";
import {Place, TransactionData} from "../../../../../../../../core/classes/transactionData";
import {TransactionResultDto} from "../../../../../../../../core/classes/transaction-result-dto";
import {TransactionController} from "../../../../../../../../core/controllers/transaction.controller";
import {SnackbarService} from "../../../../../../../../core/library/snackbar.service";
import {MatStepper} from "@angular/material/stepper";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss'],
  providers: [TransactionController, CtMapsService]
})
export class StepTwoComponent implements OnInit, OnDestroy {

  @Input() transaction: TransactionResultDto | null = null;
  @Output() transactionChange = new EventEmitter<TransactionResultDto | null>();

  interval: any;

  constructor(private transactionController: TransactionController, private snackbar: SnackbarService, private maps: CtMapsService, private stepper: MatStepper) {
  }

  ngOnInit(): void {
  }

  initializeTransaction() {

    this.resetPolling()

    this.maps
      .getPosition()
      .then((location: ILocation | null) => {

        const initializeTransactionParameter = new TransactionData(this.transaction?.Amount, new Place(location?.lat, location?.lng));
        if (this.transaction?.CouponCode) initializeTransactionParameter.Code = this.transaction!.CouponCode;

        this.transactionController
          .initialize(initializeTransactionParameter)
          .then(data => this.start(<TransactionResultDto>data.Result));

      });

  }

  getEncodedTransactionCode(): string | null {

    if (!this.transaction) return null;

    return btoa(this.transaction.TransactionCode);

  };

  private start(transaction: TransactionResultDto) {

    this.interval = setInterval(() => {

      this.transactionController
        .infos(transaction.TransactionCode)
        .then((data: CtWebapiGenericResponse<any>) => {

          const result = <TransactionResultDto>data.Result;

          if (this.transaction?.Status != result.Status) this.notifyStatus(result)

          this.transactionChange.emit(result);

        })

    }, 2000);

  }

  private terminate(result: TransactionResultDto) {

    this.resetPolling();
    this.transactionChange.emit(result);

    this.stepper.next();

  }

  private notifyStatus(value: TransactionResultDto) {

    switch (value.Status) {

      case TransactionStatus.Success:
      case TransactionStatus.Cancelled:
        this.terminate(value);
        break;

      case TransactionStatus.Bind:
        this.notify("SNACKBAR.QR_CONNECTED", {productName: value.ProductName});
        break;

    }

  }

  private notify = (message: string, params: object | undefined = undefined) => this.snackbar.generalMessage(message, params);

  resetPolling() {

    if (this.interval)
      clearInterval(this.interval)

  }

  ngOnDestroy() {

    this.resetPolling()

  }

}
