import {Component, OnInit} from '@angular/core';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {AppGeneralService} from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  constructor(
    private appGeneralService: AppGeneralService,
    private ctAuthenticationService: CtAuthenticationService,
  ) {

    if (this.ctAuthenticationService.userValue) {

      this.appGeneralService.redirectToHome();

    }

  }

  ngOnInit() {
  }

}
