<div class="profile-form-cntr h-100 mb-5">

  <div class="container p-footer">

    <h2 class="text-center mt-4"><b [innerHtml]="'FORM.EDIT-PROFILE' | translate"></b></h2>

    <form [formGroup]="form">

      <mat-form-field class="w-100 b-radius" appearance="outline">

        <mat-label> {{ 'FORM.NAME' | translate }} </mat-label>
        <input matInput placeholder="Francesco" type="text" autocomplete="off" formControlName="name" required>
        <mat-error *ngIf="form.get('name')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
        </mat-error>

      </mat-form-field>

      <mat-form-field class="w-100 b-radius" appearance="outline">

        <mat-label> {{ 'FORM.SURNAME' | translate }} </mat-label>
        <input matInput placeholder="Rossi" type="text" autocomplete="off" formControlName="surname" required>
        <mat-error *ngIf="form.get('surname')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
        </mat-error>

      </mat-form-field>

      <div class="birthdate-cntr">

        <mat-form-field appearance="outline" class="w-100 mb-3">

          <mat-label>{{ 'FORM.BIRTHDATE' | translate }}</mat-label>

          <input formControlName="birthday" (click)="picker.open()" readonly [max]="maxDate" required matInput [matDatepicker]="picker">
          <mat-error>* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

          <mat-datepicker #picker></mat-datepicker>

        </mat-form-field>

      </div>

      <mat-form-field class="w-100 b-radius" appearance="outline">

        <mat-label> {{ 'FORM.MAIL' | translate }} </mat-label>
        <input matInput placeholder="username@company.it" type="email" autocomplete="off" formControlName="email"
               readonly>
        <mat-error *ngIf="form.get('email')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
        </mat-error>

      </mat-form-field>

      <mat-form-field class="w-100 b-radius" appearance="outline">

        <mat-label> {{ 'FORM.PHONE' | translate }} </mat-label>
        <input matInput placeholder="344 9929384" type="number" autocomplete="off" formControlName="phone" required>
        <mat-error *ngIf="form.get('phone')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
        </mat-error>

      </mat-form-field>

      <ng-container *ngIf="roleHelper.isBusiness()">

        <mat-form-field class="w-100 b-radius" appearance="outline">

          <mat-label> {{ 'FORM.NOMINATIVE' | translate }} </mat-label>
          <input matInput [placeholder]="'FORM.NOMINATIVE'|translate" type="text" autocomplete="off"
                 formControlName="nominative" required>
          <mat-error *ngIf="form.get('nominative')?.hasError('required')">
            * {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

        </mat-form-field>

        <mat-form-field class="w-100 b-radius mb-2" appearance="outline">

          <mat-label> {{ 'FORM.PEC' | translate }} </mat-label>

          <input matInput placeholder="username@pec.it" type="email" autocomplete="off" formControlName="pec" required>

          <mat-error *ngIf="form.get('pec')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('pec')?.hasError('email')">* {{ 'FORM.VALID_MAIL' | translate }}
          </mat-error>

        </mat-form-field>

        <mat-form-field class="w-100 b-radius" appearance="outline">

          <mat-label> {{ 'FORM.SDI' | translate }} </mat-label>
          <input matInput placeholder="sdi" type="text" autocomplete="off" formControlName="sdi" required>
          <mat-error *ngIf="form.get('sdi')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
          </mat-error>

        </mat-form-field>

        <mat-form-field class="w-100 b-radius" appearance="outline">

          <mat-label> {{ 'FORM.FISCAL_CODE' | translate }} </mat-label>
          <input matInput placeholder="TNRLZN78A92J696K" type="text" autocomplete="off" formControlName="fiscalCode"
                 required>
          <mat-error *ngIf="form.get('fiscalCode')?.hasError('required')">
            * {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

        </mat-form-field>

        <mat-form-field class="w-100 b-radius" appearance="outline">

          <mat-label> {{ 'FORM.VAT' | translate }} </mat-label>
          <input matInput placeholder="Vat number" type="text" autocomplete="off" formControlName="vat" required>
          <mat-error *ngIf="form.get('vat')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
          </mat-error>

        </mat-form-field>

        <mat-form-field class="w-100 mb-4 b-radius place" appearance="outline">

          <mat-label> {{ 'FORM.LOCATION-BUSINESS'| translate}} </mat-label>

          <input matInput matGoogleMapsAutocomplete [formControl]="LocationFormControl" [types]="['geocode']"
                 [country]="['it']" [value]="LocationFormControl.value" (onLocationSelected)="getCoordinates($event)"
                 (onAutocompleteSelected)="onAutocompleteSelected($event)" required>
          <mat-error *ngIf="LocationFormControl.hasError('required')" class="placeError">
            * {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

        </mat-form-field>

      </ng-container>

      <div>

        <app-localization-switch-select #localization [registration]="true"></app-localization-switch-select>

      </div>

      <div class="mt-3 mb-3">

        <mat-checkbox formControlName="newsletter">
          <span target="_blank" [innerHTML]="['FORM.NEWSLETTER' | translate]"></span>
        </mat-checkbox>

      </div>

    </form>

    <ct-button *ngIf="buttonsViewModel.btnSubmit " [parameters]="buttonsViewModel.btnSubmit "></ct-button>

    <div class="mt-4" role="button" (click)="this.openDeleteProfileDialog()">

      <p [innerHTML]="'GENERAL.DELETE-PROFILE-NOTIFICATION' | translate"></p>

     <p class="link-style">{{ 'CTBUTTON.DELETE-PROFILE' | translate }}</p> 

    </div>

  </div>


</div>
