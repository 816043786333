import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/core/classes/product';

@Component({
  selector: 'app-input-text-area',
  templateUrl: './input-text-area.component.html',
  styleUrls: ['./input-text-area.component.scss']
})
export class InputTextAreaComponent implements OnInit {

  @Input() textAreaViewModel: ITextAreaInput | undefined;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Input() productData: Product | undefined;

  constructor() { }

  ngOnInit() {
  }

}

export interface ITextAreaInput {

  control: any;
  label: string;

}

