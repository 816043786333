import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CtMapsService} from '@ctsolution/ct-maps';
import {Product} from 'src/app/core/classes/product';
import {MapsContentComponent, MapsData} from './maps-content/maps-content.component';
import {GOOGLE_MAPS_STYLE} from "./maps.style";
import {Subscription} from "rxjs";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {ILocation, LocationController} from "../../../../../../core/controllers/location.controller";
import {JwtService} from "../../../../../../core/library/jwt.service";

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  providers: [CtMapsService]
})
export class MapsComponent implements OnInit, OnDestroy {

  @Input() products: Array<Product> = new Array<Product>();

  subscription?: Subscription;

  viewModel = {

    coordinates: {

      lat: 44.405650,
      lng: 8.946256

    },
    zoom: 14,
    style: GOOGLE_MAPS_STYLE

  }

  constructor(
    private dialog: MatDialog,
    private ctMapsService: CtMapsService,
    private ctAuthenticationService: CtAuthenticationService,
    private locationController: LocationController,
    private jwt: JwtService) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        this.locationController
          .list()
          .then((locations: ILocation[]) => {

            let location: ILocation | undefined = locations.find((location: ILocation) => location.Oid === this.jwt.getLocationOid());

            if (location) {

              this.viewModel.coordinates.lat = location.Latitude;
              this.viewModel.coordinates.lng = location.Longitude;

            }

          });


      });

  }

  ngOnInit() {

    this.setCurrentLocation();

  }

  private setCurrentLocation() {

    this.ctMapsService
      .getPosition()
      .then((result: any | null) => {

        this.viewModel.coordinates.lat = result?.lat ?? 0;
        this.viewModel.coordinates.lng = result?.lng ?? 0;

      });

  }


  getMarkerData = (product: Product): string => '/assets/images/icons/marker-category/' + product.Category?.Code + '-marker.svg';

  getCurrentLocationMarkerIcon = () => '/assets/images/icons/location.svg'

  detail(product: Product) {

    const data = new MapsData(product, this.products);
    this.dialog.open(MapsContentComponent, {data, panelClass: 'dialog-map-container'});

  }

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}




