<ct-card [parameter]="cardParameter"></ct-card>

<ng-template #cardTemplate>

    <div class="row">

        <div class="col-4">

            <img src="{{parameter.logoPath ? parameter.logoPath : '/assets/images/placeholder-images/generic-logo/logo-w-background.png'}}"
                alt="">

        </div>

        <div class="col-8">

            <p><b>{{parameter.title}}</b></p>

            <div>

                <small [innerHTML]="['GENERAL.USAGE_DATE' | translate]"></small>&nbsp;<small
                    *ngIf="parameter.date"><b>{{parameter.date}}</b></small>

            </div>
            <div>

                <small [innerHTML]="['GENERAL.PLACE' | translate]"></small>&nbsp;<small
                    *ngIf="parameter.location"><b>{{parameter.location}}</b></small>

            </div>
            <!-- <div>

                <small [innerHTML]="['GENERAL.DISCOUNT' | translate]"></small>&nbsp;<small *ngIf="parameter.sale"><b>{{parameter.sale}}</b></small>

            </div> -->
            <div>

                <ng-container *ngIf="parameter.saving">

                    <small
                        [innerHTML]="['GENERAL.SAVINGS' | translate]"></small>&nbsp;<small><b>{{parameter.saving}}</b></small>

                </ng-container>

            </div>

            <div>

                <ng-container *ngIf="parameter.virtualPoint; else novPointsTmpl">

                    <small
                        [innerHTML]="['GENERAL.VIRTUALPOINT' | translate]"></small>&nbsp;<small><b>{{parameter.virtualPoint}}</b></small>

                </ng-container>


            </div>

        </div>


    </div>

</ng-template>

<ng-template #novPointsTmpl>

    <b>{{'GENERAL.NOVIRTUALPOINT' | translate}}</b>

</ng-template>