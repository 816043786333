<div class="maps-section-cntr">

  <div class="map-search-bar-cntr container">

    <app-search-bar></app-search-bar>

  </div>

  <agm-map
    [latitude]="viewModel.coordinates.lat"
    [longitude]="viewModel.coordinates.lng"
    [zoom]="viewModel.zoom"
    [disableDefaultUI]="true"
    [styles]="viewModel.style">

    <agm-marker
      *ngFor="let product of products"
      [latitude]="product.Place?.Latitude ?? 0"
      [longitude]="product.Place?.Longitude  ?? 0"
      [iconUrl]="getMarkerData(product)"
      (markerClick)="detail(product)">

    </agm-marker>

    <agm-marker
      [latitude]="viewModel.coordinates.lat"
      [longitude]="viewModel.coordinates.lng"
      [iconUrl]="getCurrentLocationMarkerIcon()"
      [animation]="'BOUNCE'"
      ></agm-marker>

  </agm-map>

</div>
