<div class="profile-image-cntr">

  <ng-container *ngIf="viewModel">

    <div class="photo-ptofile box-shadow mt-5 mb-2" [style.background-image]="'url('+ viewModel.profileImgUrl +')'">
    </div>

    <div class="edit-button-cntr">

      <ct-button [parameters]="editButton"></ct-button>

    </div>

  </ng-container>

</div>
