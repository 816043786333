import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {filter, Subscription} from 'rxjs';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {RoleHelperService} from "../../../core/library/role-helper.service";
import {
  ScannerQrHelperService
} from "../../../pages/personal-area/business-area/_components/qr-scanner/scanner-qr-helper.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  //#region buttons
  homeButton = CtButtonParameters
    .create(() => this.appGeneralService.navigateTo('/home'))
    .setImage('/assets/images/icons/footer/home-active.svg')
    .setImageCss('menuIconFooter')
    .setCSS('footerMarket mat-icon-button')
    .setGroupName('footerGroupButton')
    .setActiveEffectEnable(true)
    .setActiveOnInit(this.isSelected('home'));

  editProductButton = CtButtonParameters
    .create(() => this.appGeneralService.navigateTo('/personal-area/business/store'))
    .setImage('/assets/images/icons/footer/edit-business.svg')
    .setImageCss('menuIconFooter edit-business')
    .setCSS('footerMarket mat-icon-button')
    .setGroupName('footerGroupButton')
    .setActiveEffectEnable(true)
    .setActiveOnInit(this.isSelected('store'));

  specialDiscountButton = CtButtonParameters
    .create(() => this.redirectCouponDiscount())
    .setImage('/assets/images/icons/footer/special-discount.svg')
    .setImageCss('menuIconFooter edit-business')
    .setCSS('footerMarket mat-icon-button')
    .setGroupName('footerGroupButton')
    .setActiveEffectEnable(true)
    .setActiveOnInit(this.isSelected('discount'));

  centralButton = CtButtonParameters
    .create(() => this.appGeneralService.navigateTo('/personal-area/customer/'))
    .setImage('/assets/images/logo/Vlogo-v.svg')
    .setImageCss('menuIconFooter')
    .setCSS('footerLogo mat-icon-button')
    .setGroupName('footerGroupButton')
    .setActiveEffectEnable(true)
    .setActiveOnInit(this.isSelected('my-victoria-card'));

  discountButton = CtButtonParameters
    .create(() => this.redirectCouponDiscount())
    .setImage('/assets/images/icons/footer/vPoints.svg')
    .setImageCss('menuIconFooter vPoints-footer')
    .setCSS('footerUser mat-icon-button')
    .setGroupName('footerGroupButton')
    .setActiveEffectEnable(true)
    .setActiveOnInit(this.isSelected('profile'))

  qrCentralButton = CtButtonParameters
    .create(() => this.businessScanService.openScanner())
    .setImage('/assets/images/icons/footer/qr-code.svg')
    .setImageCss('menuIconFooter')
    .setCSS('footerLogo qr-icon')
    .setGroupName('footerGroupButton')
    .setActiveEffectEnable(true)
    .setActiveOnInit(this.isSelected('scanner'));

  //#endregion

  private BUSINESS_ACTIONS: CtButtonParameters[] = [this.editProductButton, this.qrCentralButton, this.specialDiscountButton];
  private USER_ACTIONS: CtButtonParameters[] = [this.homeButton, this.centralButton, this.discountButton];

  UIButtons: CtButtonParameters[] = this.USER_ACTIONS;

  subscription?: Subscription;

  constructor(
    private appGeneralService: AppGeneralService,
    private ctAuthenticationService: CtAuthenticationService,
    public roleHelper: RoleHelperService,
    private router: Router,
    private businessScanService: ScannerQrHelperService
  ) {

    this.router
      .events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => this.updateActiveIcon());

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => this.setupFooterIcons());

  }

  setupFooterIcons = () => this.UIButtons = this.roleHelper.isBusiness() && !this.roleHelper.isCustomer() ? this.BUSINESS_ACTIONS : this.USER_ACTIONS;

  ngOnInit() {
  }

  private redirectCouponDiscount() {

    if (this.roleHelper.isCustomer()) {

      this.appGeneralService.navigateTo('/personal-area/customer/coupons')

    } else {

      this.appGeneralService.navigateTo('/personal-area/business/discounts')

    }

  }

  isSelected(key: string): boolean {

    return location.href.includes(key)

  };

  updateActiveIcon() {

    this.homeButton.setActiveOnInit(this.isSelected('home'))
      .setActiveEffectEnable(true);
    this.editProductButton.setActiveOnInit(this.isSelected('store'));
    this.centralButton.setActiveOnInit(this.isSelected('my-victoria-card'));
    this.discountButton.setActiveOnInit(this.isSelected('coupons') || this.isSelected('discount'));
    this.qrCentralButton.setActiveOnInit(this.isSelected('scanner'));
    this.specialDiscountButton.setActiveOnInit(this.isSelected('discount'));

  }

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
