import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { NotificationAreaComponent } from './notification-area.component';
import { NotificationContentModule } from '../notification-content/notification-content.module';
import { CtFrameworkModule } from '@ctsolution/ct-framework';


@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    NotificationContentModule,
    CtFrameworkModule
  ],
  declarations: [NotificationAreaComponent],
  exports: [NotificationAreaComponent]
})
export class NotificationAreaModule { }
