import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {IProfile} from 'src/app/core/classes/profile';
import {ProfileController} from 'src/app/core/controllers/profile.controller';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import PlaceResult = google.maps.places.PlaceResult;
import {CtMapsService, AddressResult, Coordinates} from '@ctsolution/ct-maps';
import {SnackbarService} from 'src/app/core/library/snackbar.service';
import {CtDateAdapterService} from "@ctsolution/ct-framework";
import {RoleHelperService} from "../../../../../../core/library/role-helper.service";
import {
  LocalizationSwitchSelectComponent
} from "../../../../../../layout/full/navbar/localization-switch-select/localization-switch-select.component";
import { MatDialog } from '@angular/material/dialog';
import { DeleteProfileComponent } from '../delete-profile/delete-profile.component';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {

  @ViewChild('localization') localizationSelect?: LocalizationSwitchSelectComponent;

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;

  LocationFormControl: FormControl = new FormControl('');

  buttonsViewModel: any = {

    btnSubmit: CtButtonParameters
      .create(() => this.onSubmit())
      .setCSS("generalButton box-shadow", true)
      .setContent("CTBUTTON.SAVE"),

  }

  maxDate: Date;

  profileData: any;

  constructor(
    private appGeneralService: AppGeneralService,
    private ctAuthenticationService: CtAuthenticationService,
    private ctMapsService: CtMapsService,
    private formBuilder: FormBuilder,
    private profileController: ProfileController,
    private snackbarService: SnackbarService,
    private dateService: CtDateAdapterService,
    public roleHelper: RoleHelperService,
    private dialog: MatDialog
  ) {

    const currentYear = new Date().getFullYear();

    this.maxDate = new Date(currentYear - 16, 11, 31);

    this.form = this.formBuilder.group({

      name: new FormControl(null, [Validators.required]),
      surname: new FormControl(null, [Validators.required]),
      birthday: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required]),
      newsletter: new FormControl(false),
      nominative: new FormControl(null),
      pec: new FormControl(null, [Validators.email]),
      sdi: new FormControl(null),
      fiscalCode: new FormControl(null),
      vat: new FormControl(null),
      location: new FormControl(null),
      coordinates: new FormControl(null)

    });

    if (this.roleHelper.isBusiness()) {

      this.setupBusinessValidations();

    }

  }

  ngOnInit() {

    this.setupProfile();


  }

  setupBusinessValidations() {

    this.form.get('nominative')?.addValidators([Validators.required]);
    this.form.get('nominative')?.updateValueAndValidity();

    this.form.get('pec')?.addValidators([Validators.required]);
    this.form.get('pec')?.updateValueAndValidity();

    this.form.get('sdi')?.addValidators([Validators.required]);
    this.form.get('sdi')?.updateValueAndValidity();

    this.form.get('fiscalCode')?.addValidators([Validators.required]);
    this.form.get('fiscalCode')?.updateValueAndValidity();

    this.form.get('vat')?.addValidators([Validators.required]);
    this.form.get('vat')?.updateValueAndValidity();

    this.form.get('location')?.addValidators([Validators.required]);
    this.form.get('location')?.updateValueAndValidity();

    this.LocationFormControl?.addValidators([Validators.required]);
    this.LocationFormControl?.updateValueAndValidity();

  }

  setupProfile() {

    // GET PROFILE
    this.profileController.get().then((p: any) => {

      this.profileData = p.Result;

      if (!this.profileData) {

        return

      }

      if (this.profileData.DateOfBirth) {

        const birthDate: Date = new Date(this.profileData.DateOfBirth);
        this.form.get('birthday')?.setValue(birthDate);

      }

      this.form.get('name')?.setValue(this.profileData.Name);
      this.form.get('surname')?.setValue(this.profileData.Surname);

      this.form.get('email')?.setValue(this.profileData.Email);
      this.form.get('phone')?.setValue(this.profileData.Phone);
      this.form.get('newsletter')?.setValue(this.profileData.Newsletter);

      if (this.roleHelper.isBusiness()) {

        this.form.get('nominative')?.setValue(this.profileData.Nominative);
        this.form.get('pec')?.setValue(this.profileData.PEC);
        this.form.get('sdi')?.setValue(this.profileData.SDI);
        this.form.get('fiscalCode')?.setValue(this.profileData.FiscalCode);
        this.form.get('vat')?.setValue(this.profileData.VAT);

        this.form.get('location')?.setValue(this.profileData.FiscalPlace);
        this.form.get('coordinates')?.setValue({
          Latitude: this.profileData.FiscalPlace?.latitude ?? null,
          Longitude: this.profileData.FiscalPlace?.longitude ?? null
        });
        this.LocationFormControl.setValue(this.profileData.FiscalPlace?.Name ?? null)

      }

    });

  }

  onAutocompleteSelected(result: PlaceResult) {

    // this.placeData = this.googleMapsService.getAddress(result);

    if (result) {

      let address: AddressResult | undefined =
        this.ctMapsService.getAddress(result);

      this.form.get('location')?.setValue(address);

    }

  }

  getCoordinates(location: Coordinates) {

    this.form.get('coordinates')?.setValue({Latitude: location.latitude, Longitude: location.longitude});

  }

  onSubmit() {

    this.form.markAllAsTouched();

    if (this.form?.valid && this.LocationFormControl.valid) {

      const birthdate = this.dateService.getTimeZoneOffsetFormat(this.form.get('birthday')?.value);

      let profileData: IProfile = {

        Id: this.profileData.Id,
        Name: this.form.get('name')?.value,
        Surname: this.form.get('surname')?.value,
        DateOfBirth: birthdate,
        Email: this.form.get('email')?.value,
        Phone: this.form.get('phone')?.value,
        Newsletter: this.form.get('newsletter')?.value
      }

      if (this.roleHelper.isBusiness()) {

        profileData.Nominative = this.form.get('nominative')?.value;
        profileData.FiscalCode = this.form.get('fiscalCode')?.value;
        profileData.PEC = this.form.get('pec')?.value;
        profileData.SDI = this.form.get('sdi')?.value;
        profileData.VAT = this.form.get('vat')?.value;

        const placeValues = this.form.get('location')?.value;
        const coordinates = this.form.get('coordinates')?.value;

        profileData.FiscalPlace = { // TODO: dovremmo fare una funzione che casta questi dati
          Name: placeValues.Address,
          Latitude: coordinates.Latitude,
          Longitude: coordinates.Latitude,
          PostalCode: placeValues.Cap,
          ...placeValues
        };

      }

      profileData.LocationOid = this.localizationSelect?.control.value.Oid;

      this.profileController
        .put(profileData)
        .subscribe(() =>

          this.ctAuthenticationService
            .getClaims()?.then(() => this.appGeneralService.navigateTo('/personal-area/profile/')));

      this.snackbarService.generalMessage('SNACKBAR.EDIT_PROFILE_SUCCESS');

    }

  }


  openDeleteProfileDialog = () => this.dialog.open(DeleteProfileComponent, {maxHeight: '80vh', maxWidth: '500px'});

}
