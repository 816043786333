import {NgModule} from "@angular/core";

import {RouterModule} from "@angular/router";

import {SignInModule} from "./sign-in/sign-in.module";
import {SignUpModule} from "./sign-up/sign-up.module";
import {RecoveryPasswordModule} from "./recovery-password/recovery-password.module";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'sign-in',
        loadChildren: () => SignInModule
      },
      {
        path: 'sign-up',
        loadChildren: () => SignUpModule
      },
      {
        path: 'recovery-password',
        loadChildren: () => RecoveryPasswordModule
      }
    ])
  ],
  declarations: [],
})
export class AuthenticationModule {
}
