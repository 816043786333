<div class="text-center">

  <div class="search-bar-cntr container">

    <div class="input-container">

      <mat-form-field appearance="outline" class="search-input b-radius w-100">

        <mat-icon matPrefix class="primary-color">search</mat-icon>
        <input matInput type="text" autocomplete="off"
               [formControl]="control"
               [placeholder]="'SEARCH.BY_SHOP_NAME'|translate"
               (keyup)="search()">

      </mat-form-field>

    </div>

  </div>

</div>
