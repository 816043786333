import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {SearchFiltersComponent} from "../../search-filters/search-filters/search-filters.component";

@Component({
  selector: 'app-search-active-filters',
  templateUrl: './search-active-filters.component.html',
  styleUrls: ['./search-active-filters.component.scss']
})
export class SearchActiveFiltersComponent implements OnInit {

  btnShowAllFilters: CtButtonParameters = CtButtonParameters
    .create(() => this.openFiltersPanel())
    .setImage('/assets/images/icons/filter.svg')
    .setCSS('mat-raised-button mat-icon-button')
    .setImageCss('generalFilterIcon');

  constructor(private _bottomSheet: MatBottomSheet) {
  }

  ngOnInit(): void {
  }

  scroll(element: HTMLElement) {
    element.scrollIntoView(({behavior: 'smooth'}));
}

  openFiltersPanel() {

    this._bottomSheet
      .open(SearchFiltersComponent, {panelClass: 'search-filters-panel', disableClose: true})

  }

}
