import { Component, OnInit } from '@angular/core';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { AppGeneralService } from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-registration-welcome',
  templateUrl: './registration-welcome.component.html',
  styleUrls: ['./registration-welcome.component.scss']
})
export class RegistrationWelcomeComponent implements OnInit {

  viewModel: any = {

    btnMore: CtButtonParameters
      .create(() => { this.goSignIn() })
      .setCSS("generalButton box-shadow", true)
      .setContent("CTBUTTON.BACK-LOGIN"),

  }


  constructor(
    private appGeneralService: AppGeneralService
  ) { }

  ngOnInit() {
  }

  goSignIn() {

    this.appGeneralService.navigateTo('/authentication/sign-in');

  }

}
