<div class="file-upload-cntr">

    <div class="d-flex upload_cntr box-shadow b-radius" (click)="uploader.click()"
        [style]="{'background-image': 'url('+ picture + ')'}">

        <span class="mt-auto mb-auto w-75 brochureText" *ngIf="viewModel.isPdfFile"
            [innerHTML]="viewModel.fileName ? viewModel.fileName : ['FORM.FILE_UPLOAD' | translate]"
            [ngClass]="viewModel.fileName ? 'noPlaceholder' : ''"></span>

        <input type="file" id="upload" (change)="onUpload($event)" class="d-none" #uploader [accept]="typeToAccept">

        <mat-icon *ngIf="!picture" class="upload-icon b-radius">file_upload</mat-icon>

    </div>
    <small *ngIf="viewModel.isWrongExtension">estensione errata</small>

</div>