<div class="availability-hours-choise-cntr">

    <div class="d-flex mb-4">

        <div class="m-auto">

            <app-slide-toggle [configuration]="slideToggleConfiguration" (onChange)="hoursType($event)"></app-slide-toggle>

        </div>

    </div>

    <form [formGroup]="form">

        <ng-container formArrayName="ratesTimeFormArray">

            <ng-container *ngFor="let rate of ratesTime.controls; index as i">

                <div class="w-100 mb-2" [formGroupName]="i">

                    <ng-container *ngIf="i > 0">

                        <div class="w-100 mt-3">

                            <div (click)="removeRateTime(i)">
                                <p [innerHTML]="['FORM.REMOVE_PERIOD' | translate]" class="text-right primary-color text-decoration-underline"></p>
                            </div>

                        </div>

                    </ng-container>

                    <app-time-select [form]="rate"></app-time-select>

                </div>

            </ng-container>

        </ng-container>

        <ng-container *ngIf="isAllDay">

            <div class="m-auto mt-4 mb-1 w-75">

                <ct-button [parameters]="addPeriodButton"></ct-button>
              
            </div>

        </ng-container>

    </form>

</div>