<mat-list-item role="listitem" class="discount-list-item-container mb-2">

  <mat-card [class]="'w-100 discount-card status-' + item.Status + ( getStatus() === 'STATUS.EXPIRED' ? ' disabled': '')">

    <mat-card-header>

      <div [class]="'discount-status ps-2 pe-1 status-' + item.Status + ( getStatus() === 'STATUS.EXPIRED' ? ' disabled': '')">

        {{getStatus() | translate }} 

      </div>
      <mat-card-subtitle
        class="primary-color"
        [class.special-discount-color]="item.Type === TYPES.Special"> {{ getDiscountType() | translate }}</mat-card-subtitle>
      <mat-card-title>{{ item.Title }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <p><strong>{{ 'FORM.DESCRIPTION_DISCOUNT' | translate }}</strong>: {{ item.Description }}</p>
      <p><strong>{{ 'BUSINESS.DISCOUNT_MODE' | translate }}</strong>: {{ getTypeOfUse() | translate }}</p>

      <ng-container *ngIf="item.CustomerNote; else validationRangeTemplate">

        <p><strong class="refused-note">{{ 'BUSINESS.REFUSAL_NOTE' | translate }}</strong>: {{ item.CustomerNote }}</p>

      </ng-container>

      <ng-template #validationRangeTemplate>

        <ng-container *ngIf="item.Start && item.End">

          <p>{{ "BUSINESS.RANGE_VALIDITY" | translate: {start: item.Start | date, end: item.End | date} }}</p>

        </ng-container>

      </ng-template>

    </mat-card-content>

    <div class="d-flex justify-content-start">

      <div class="special-discount-color">

        {{'FORM.SALES_TITLE'|translate}}&nbsp;<strong>{{ item.Discount }}%</strong>

      </div>

    

    </div>

  </mat-card>

</mat-list-item>
