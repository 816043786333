import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActionsComponent} from './actions.component';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatListModule} from '@angular/material/list';
import {TransactionCardModule} from "../../../../_components/transaction-card/transaction-card.module";

@NgModule({
  imports: [
    CommonModule,
    TransactionCardModule,
    CtFrameworkModule,
    MatListModule,
  ],
  declarations: [ActionsComponent],
  exports: [ActionsComponent]
})
export class ActionsModule {
}
