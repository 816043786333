<ng-container *ngIf="configuration.coupon?.CouponTemplate">

  <div class="my-coupon-container"
       [class]="configuration.class"
       [class.special-discount]="configuration.coupon?.CouponTemplate?.isSpecialCoupon()"
       [class.victoria-card]="configuration.coupon?.CouponTemplate?.isMyVictoriaCard()"
       #card>

    <div class="introduction">

      <ng-container *ngIf="configuration.coupon?.CouponTemplate?.isMyVictoriaCard(); else SPECIFIC_TEMPLATE">

        <ng-container *ngTemplateOutlet="VICTORIACARDTEMPLATE"></ng-container>

      </ng-container>

    </div>

    <div class="coupon-type">

      <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>

    </div>

  </div>

  <ng-template #labelTemplate>

    <ng-container *ngIf="configuration.coupon?.CouponTemplate?.isMyVictoriaCard(); else discountTemplate">

      VICTORIA CARD

    </ng-container>

    <ng-template #discountTemplate>

      <ng-container *ngIf="configuration.coupon?.CouponTemplate?.isVPointCoupon(); else specialDiscountTemplate">

        VPoint <img class="vpoint-icon" src="/assets/images/icons/vpoints-white.svg"/>

      </ng-container>

      <ng-template #specialDiscountTemplate>

        {{ 'DISCOUNT.SPECIAL_DISCOUNT' | translate }}

      </ng-template>

    </ng-template>

  </ng-template>

  <ng-template #VICTORIACARDTEMPLATE>

    <div class="d-flex h-100">

      <div class="row" style="margin: 0px !important; width: 100%">

        <div class="col-7 d-flex h-100">

          <div class="mt-auto mb-3 text-center">

            <img class="m-auto victoria-card mb-4"
                 src="/assets/images/placeholder-images/generic-logo/logo-contrast.png">

            <ct-button [parameters]="generateQRButton"></ct-button>

          </div>

        </div>
        <div class="col-5 d-flex qr-code-container h-100"></div>

      </div>

    </div>

  </ng-template>

  <ng-template #SPECIFIC_TEMPLATE>

    <div class="row mb-4">

        <div class="col-4">

          <img class="logo-coupon" [src]="configuration.coupon?.getLogo()">

        </div>

      <div class="col">

        <h2 class="title-coupon"> {{ configuration.coupon?.CouponTemplate?.getCouponDiscountName() }}</h2>
        <p class="specific-coupon-content" style="min-height: 40px"> {{ configuration.coupon?.CouponTemplate?.Description}}</p>

      </div>

    </div>

    <div class="row">

      <div class="col-7">

        <ct-button [parameters]="generateQRButton"></ct-button>

      </div>

      <div class="col-5">

        <ng-container *ngIf="!configuration.coupon?.CouponTemplate?.isMyVictoriaCard()">

          <ct-button [parameters]="shopDetailButton"></ct-button>

        </ng-container>

      </div>

    </div>

  </ng-template>

</ng-container>
