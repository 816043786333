import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {Observable} from "rxjs";
import {Coupon} from "../classes/coupon";

@Injectable()

export class MarketPlaceController {

  private controller: string = '/MarketPlace/';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  list(parameter: MarketPlaceListParameter): Promise<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`${this.controller}List`, {hideSpinner: true});

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => this.ctWebApiService
      .post(request, parameter).subscribe((data: CtWebapiGenericResponse<any>) => resolve(data)));

  }

  purchase(coupon: Coupon): Observable<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`${this.controller}Purchase`, {hideSpinner: true});

    return this.ctWebApiService.post(request, {Oid: coupon.Oid});

  }

}

export class MarketPlaceListParameter {

  ProductOid: number | null = null;

  constructor() {
  }

}
