import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {
  CouponQuote,
  TransactionController,
  TransactionRequestParameter
} from "../../../../../../../../core/controllers/transaction.controller";
import {TransactionResultDto} from "../../../../../../../../core/classes/transaction-result-dto";
import {Coupon} from "../../../../../../../../core/classes/coupon";

@Component({
  selector: 'app-discounts-manager',
  templateUrl: './discounts-manager.component.html',
  styleUrls: ['./discounts-manager.component.scss'],
  providers: [TransactionController]
})
export class DiscountsManagerComponent implements OnInit {

  @Input() transaction: TransactionResultDto | null = null;
  @Output() transactionChange: EventEmitter<TransactionResultDto | null> = new EventEmitter<TransactionResultDto | null>();

  get totalDiscountAmount() {

    return this.transaction?.Coupons.map(item => (item.Quote?.Quote) ?? 0).reduce((prev, next) => prev + next) ?? 0;

  }

  sliderViewModel = {

    min: 0,
    max: 0,
    step: 1,
    getCouponSliderMaxValue: (coupon: Coupon): number => {

      let max: number = this.sliderViewModel.max;

      max -= (this.totalDiscountAmount - (coupon.Quote?.Quote ?? 0));

      if (max < 0) return 0;

      return max;

    }

  }

  accept: CtButtonParameters = CtButtonParameters
    .create(() => this.onSuccess())
    .setCSS("generalButton mb-2")
    .setContent('BUTTON.CONFIRMATION')

  formatLabel = (value: number | null) => `${value ?? 0}€`

  private getCouponsQuotes() {

    return (this.transaction?.Coupons ?? []).filter((coupon: Coupon) => !!coupon.Quote).map((coupon: Coupon) => coupon.Quote!);

  }


  constructor(private transactionController: TransactionController) {
  }

  ngOnInit(): void {

    this.setup();

  }

  setup() {

    if (!this.transaction) return;

    this.transaction
      .Coupons
      .forEach((coupon: Coupon) => coupon.Quote = new CouponQuote(coupon.Oid, 0));

    this.sliderViewModel.max = this.transaction.Amount;

    if (this.transaction.Amount <= 10) this.sliderViewModel.step = 0.25;

  }

  private onSuccess() {

    const parameter: TransactionRequestParameter | null = this.getTransactionRequestParameter();

    if (!parameter) return;

    this.transactionController
      .success(parameter)
      .then(data => this.transactionChange.emit(data.Result));

  }


  onCancel() {

    const parameter: TransactionRequestParameter | null = this.getTransactionRequestParameter();

    if (!parameter) return;

    this.transactionController
      .cancelled(parameter)
      .then(() => this.transactionChange.emit(null));

  }

  private getTransactionRequestParameter(): TransactionRequestParameter | null {

    if (!this.transaction) return null;

    return new TransactionRequestParameter(this.transaction.TransactionCode, this.getCouponsQuotes());

  }

}
