import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoriesComponent} from './categories.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from '@ctsolution/ct-framework';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from "@angular/router";
import {CategoriesListModule} from "./categories-list/categories-list.module";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    MatButtonModule,
    CtFrameworkModule,
    CtSkeletonModule,
    RouterModule.forChild([{path: '', component: CategoriesComponent}]),
    CategoriesListModule
  ],
  declarations: [CategoriesComponent]
})
export class CategoriesModule {
}
