import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {TransactionResultDto} from 'src/app/core/classes/transaction-result-dto';
import {TransactionStatus} from "../../../../../../../../core/enum/transaction-status.enum";
import {AppGeneralService} from "../../../../../../../../core/library/app-general.service";
import {MatDialogRef} from "@angular/material/dialog";
import {QrScannerComponent} from "../../../qr-scanner/qr-scanner.component";

@Component({
  selector: 'app-scanner-result',
  templateUrl: './scanner-result.component.html',
  styleUrls: ['./scanner-result.component.scss']
})
export class ScannerResultComponent implements OnInit {

  @Input() transaction: TransactionResultDto | null = null;
  @Output() transactionChange: EventEmitter<TransactionResultDto | null> = new EventEmitter<TransactionResultDto | null>();

  successfullyTransaction = (): boolean => this.transaction?.Status === TransactionStatus.Success;

  resultImageSrc = (): string => `/assets/images/thank-you-page/` + (this.successfullyTransaction() ? 'payment_success' : 'payment_failed') + `.svg`

  rescan: CtButtonParameters = CtButtonParameters
    .create(() => this.scan())
    .setCSS("generalButton mb-2")
    .setContent('CTBUTTON.SCAN')

  report: CtButtonParameters = CtButtonParameters
    .create(() => this.reports())
    .setCSS("generalButton  mb-2")
    .setContent('CHART.YOUR-EARNINGS');

  constructor(private general: AppGeneralService, private dialog: MatDialogRef<QrScannerComponent>) {
  }

  ngOnInit(): void {
  }

  scan = () => this.transactionChange.emit(null);

  reports = () => {

    this.dialog
      .afterClosed()
      .subscribe(() => this.general.navigateTo('/personal-area/business/reports'));

    this.dialog.close();

  };

}
