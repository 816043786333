import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OpeningDaysComponent} from './opening-days.component';
import {MatChipsModule} from '@angular/material/chips';
import {CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    CtFrameworkModule
  ],
  declarations: [OpeningDaysComponent],
  exports: [OpeningDaysComponent]
})
export class OpeningDaysModule {
}
