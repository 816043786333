import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VPointCardComponent} from './VPoint-card.component';
import {MatIconModule} from '@angular/material/icon';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {VPointCouponRequestComponent} from './VPoint-coupon-request/VPoint-coupon-request.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    CtFrameworkModule,
    CtFrameworkModule,
    CtButtonModule,
    MatDialogModule
  ],
  declarations: [VPointCardComponent, VPointCouponRequestComponent],
  exports: [VPointCardComponent]
})
export class VPointCardModule {
}
