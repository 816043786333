import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtExpansionPanelComponent } from './ct-expansion-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    CtFrameworkModule,
    MatIconModule
  ],
  declarations: [CtExpansionPanelComponent],
  exports:[CtExpansionPanelComponent]
})
export class CtExpansionPanelModule { }
