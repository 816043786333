<div class="login-form-cntr">

    <form [formGroup]="form">

        <mat-form-field class="w-100 b-radius">
            <input matInput placeholder="mail" type="email" autocomplete="off" formControlName="Email" required>
            <mat-error *ngIf="form.get('Email')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>
            <mat-error *ngIf="form.get('Email')?.hasError('email')">* {{ 'FORM.VALID_MAIL' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100 b-radius">

            <input matInput placeholder="password" [type]="passwordType" autocomplete="off" formControlName="Password" required>
            <button mat-icon-button matSuffix (click)="changePasswordType()" type="button">
                <mat-icon *ngIf="passwordType">{{iconVisibility}}</mat-icon>             
              </button>

            <mat-error>* {{ 'GENERAL.REQUIRED_FIELD' | translate }}</mat-error>

        </mat-form-field>
        <div class="align-left">
        <mat-checkbox formControlName="RememberMe" [hidden]="">{{ 'FORM.REMEMBER_ME' | translate }}</mat-checkbox>
        </div>
    </form>

</div>
