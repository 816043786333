import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginFormComponent} from './login-form/login-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatButtonModule} from '@angular/material/button';
import {LoginComponent} from './login.component';
import {CtOAuthModule} from "@ctsolution/ct-authentication";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    RouterModule,
    MatCheckboxModule,
    CtButtonModule,
    CtFrameworkModule,
    CtOAuthModule
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent
  ],
  exports: [LoginComponent],
  providers: []
})
export class LoginModule {
}
