import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-savings-chart',
  templateUrl: './savings-chart.component.html',
  styleUrls: ['./savings-chart.component.scss']
})
export class SavingsChartComponent implements OnInit {

  savings: boolean = false;

  polarChartData: any = [{ 
    data: [16, 11, 7, 10, 3, 15],
     label: 'Risparmi' }];

  polarChartLabels: any = [];

  polarChartOptions: any = {
    borderColor: [
      '#0584C7',
      '#0AB1F2',
      '#1ED6A5',
      '#FFB81A',   
      '#c1c1c1',
      '#EF3A54'     
    ],

    hoverBackgroundColor: [

      '#0584C7',
      '#0AB1F2',
      '#1ED6A5',
      '#FFB81A',
      '#c1c1c1',
      '#EF3A54'       
    ],

    backgroundColor: [
      '#0584C7',
      '#0AB1F2',
      '#1ED6A5',
      '#FFB81A',
      '#c1c1c1',
      '#EF3A54'        
    ],

    responsive: true,

    }

  constructor() { }

  ngOnInit() {
  }

}
