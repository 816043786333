import {Component, OnDestroy, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {TranslateService} from '@ngx-translate/core';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Subscription} from "rxjs";
import {JwtService} from "../../../../../core/library/jwt.service";

@Component({
  selector: 'app-share-discount-code',
  templateUrl: './share-discount-code.component.html',
  styleUrls: ['./share-discount-code.component.scss'],
})
export class ShareDiscountCodeComponent implements OnInit, OnDestroy {

  btnPrice: CtButtonParameters = CtButtonParameters.create(() => this.shareUrl())
    .setCSS('generalButton apply-btn box-shadow ', true)
    .setImage('/assets/images/shared-friends/share.png')
    .setImageCss('ios-share-img')

  btnShareCode: CtButtonParameters = CtButtonParameters.create(() => this.shareUrl())
    .setImage('assets/images/icons/ticket-coupon.svg')
    .setCSS('btn-share')
    .setLabel('SHARE.COUPON_CONTENT')
    .setImageCss('img-share');

  viewModel: any = {

    brokerCode: null

  };

  subscription ?: Subscription;

  constructor(private translateService: TranslateService, private ctAuthentication: CtAuthenticationService, private jwt: JwtService
  ) {

    this.subscription = this.ctAuthentication.user
      .subscribe(() => {

        if (this.ctAuthentication.userValueContainsClaimsRegistry()) {

          this.viewModel.brokerCode = this.jwt.getBrokerCode();

        }

      })

  }

  ngOnInit() {
  }

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

  shareUrl() {

    if (this.viewModel.brokerCode) {

      const url: string = `${window.location.origin}/authentication/sign-up?c=${this.viewModel.brokerCode}`;

      navigator.share({
        title: this.translateService.instant('SHARE.FRIENDS'),
        text: this.translateService.instant('SHARE.FRIENDS_CONTENT'),
        url: url,
      });

    }

  }

}
