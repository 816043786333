import {Injectable} from '@angular/core';
import {VPointCouponRequestComponent} from "./VPoint-coupon-request.component";
import {MatDialog} from "@angular/material/dialog";
import {Coupon} from "../../../../../../core/classes/coupon";

@Injectable({
  providedIn: 'root'
})
export class CouponRequestService {

  constructor(private dialog: MatDialog) {
  }

  request = (data: Coupon) => this.dialog.open(VPointCouponRequestComponent, {
    data,
    disableClose: true
  });

}
