import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchListComponent} from './search-list/search-list.component';
import {RouterModule} from "@angular/router";
import {SearchDetailComponent} from "./search-detail/search-detail/search-detail.component";
import {SearchCardModule} from "./search-card/search-card.module";
import {SearchDetailModule} from "./search-detail/search-detail.module";
import {CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {SearchActiveFiltersModule} from "./search-list/_components/search-active-filters/search-active-filters.module";
import {MapsModule} from "./search-list/_components/maps/maps.module";
import {SearchFiltersModule} from "./search-list/_components/search-filters/search-filters.module";

@NgModule({
  declarations: [
    SearchListComponent
  ],
  imports: [
    SearchDetailModule,
    SearchCardModule,
    SearchFiltersModule,
    CommonModule,
    CtFrameworkModule,
    MapsModule,
    RouterModule.forChild([
      {
        path: '',
        children: [
          {path: 'detail', component: SearchDetailComponent},
          {path: '', component: SearchListComponent}
        ]
      }
    ]),
    SearchCardModule,
    CtSkeletonModule,
    SearchActiveFiltersModule
  ]
})
export class SearchListModule {
}
