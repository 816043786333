import {NavbarModule} from './full/navbar/navbar.module';
import {NgModule} from '@angular/core';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {FooterModule} from './full/footer/footer.module';
import {FullComponent} from './full/full.component';
import {RouterModule} from "@angular/router";
import {MatSidenavModule} from "@angular/material/sidenav";
import {CommonModule} from "@angular/common";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MenuNavigationButtonsModule} from "./_components/menu-navigation-buttons/menu-navigation-buttons.module";
import {ProfileButtonModule} from "./_components/profile-button/profile-button.module";

@NgModule({
  imports: [
    NavbarModule,
    CtButtonModule,
    ProfileButtonModule,
    FooterModule,
    CtFrameworkModule,
    MenuNavigationButtonsModule,
    RouterModule,
    MatSidenavModule,
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    FullComponent
  ],
  declarations: [
    FullComponent
  ]
})
export class LayoutModule {
}
