import {
  CouponTemplateProcessTypeVO,
  CouponTemplateStatus,
  CouponTemplateType,
  CouponTemplateTypeOfUseVO
} from "../enum/coupons-enums";
import {CouponQuote} from "../controllers/transaction.controller";
import {CTBase} from "@ctsolution/ct-framework";

export class Coupon extends CTBase<Coupon> {

  public Oid: number = 0;
  public ProductOid: number | null = null;
  public ProductName: string | null = null;
  public Title: string | null = null;
  public Description: string | null = null;
  public ProcessType: CouponTemplateProcessTypeVO | null = null;
  public Status: CouponTemplateStatus | null = null;
  public Type: CouponTemplateType | null = null;
  public TypeOfUse: CouponTemplateTypeOfUseVO | null = null;
  public Start: Date | null = null;
  public End: Date | null = null;
  public Stock: number | null = null;
  public Purchased: number | null = null;
  public Cost: number = 0;
  public Discount: number = 0;

  Quote?: CouponQuote;

  constructor();
  constructor(model: Coupon);
  constructor(model?: Coupon) {

    super();
    if (model) this.getClass(model);

  }

  checkIfPurchasableWithVPoints = (vpoints: number): boolean => this.Cost <= vpoints;

  getCouponDiscountName(): string {

    const title: string = this.Title ?? '';

    if (!this.Discount) return title;

    if (title.includes(this.Discount.toString())) return title;

    return this.Title + ` ${this.Discount}%`

  }

  isSpecialCoupon = (): boolean => this.Type === CouponTemplateType.Special;

  isVPointCoupon = (): boolean => this.Type === CouponTemplateType.Purchasable;

  isMyVictoriaCard = (): boolean => this.Type === CouponTemplateType.MyVictoriaCard;

}

