import {Component, OnInit} from '@angular/core';
import {Product} from "../../../../../core/classes/product";
import {CtFileManagementService, IFile} from "@ctsolution/ct-file-management";
import {ActivatedRoute} from "@angular/router";
import {ProductController} from "../../../../../core/controllers/product.controller";
import {FileType} from "../../../../../core/enum/file-type.enum";
import {ILocalization} from "../../../../../core/classes/localization";
import {
  CtCarouselIvyParameter
} from "../../../../../components/ct-framework/ct-carousel-ivy/class/CtCarouselIvyParameter";
import {ShopHelperService} from "../../../../../core/library/shop-helper.service";
import {RoleHelperService} from "../../../../../core/library/role-helper.service";
import {ContentAreaParameter} from "../_components/content-area/content-area.component";

@Component({
  selector: 'app-search-detail',
  templateUrl: './search-detail.component.html',
  styleUrls: ['./search-detail.component.scss']
})
export class SearchDetailComponent implements OnInit {

  productViewModel: Product | undefined;
  contentAreaViewModel: ContentAreaParameter | undefined;
  carouselParameter: CtCarouselIvyParameter = CtCarouselIvyParameter.Create([]).setHeight(250).setArrows(false).setDots(true).setAutoplay(true);
  logo: IFile[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private productController: ProductController,
    private ctFileManagementService: CtFileManagementService,
    private shopHelperService: ShopHelperService,
    public roleHelperService: RoleHelperService
  ) {

    this.activatedRoute
      .queryParams
      .subscribe(params => {

        if (params['id']) {

          this.productController
            .get(+params['id'], true)
            .then((product: Product) => this.setupProduct(product));

        }

      });
  }

  ngOnInit() {
  }

  setupProduct(productData: Product) {

    this.productViewModel = productData;

    if (this.productViewModel.Files && this.productViewModel.Files.length >= 0) {

      let pictures: IFile[] = [];

      let cover = this.ctFileManagementService.getFilesFromListByType(this.productViewModel.Files, FileType.COVER)?.shift();

      if (cover) {

        pictures.push(cover);

      }

      let others = this.ctFileManagementService.getFilesFromListByType(this.productViewModel.Files, FileType.OTHER);

      this.logo = this.ctFileManagementService.getFilesFromListByType(this.productViewModel.Files, FileType.LOGO);

      if (others)
        pictures = [...pictures, ...others];

      if (pictures.length === 0 && this.productViewModel.Category) {

        pictures = this.shopHelperService.getDefaultPicturesByCategory(this.productViewModel.Category);

      }

      this.carouselParameter
        .setPictures(pictures.map((f: IFile) => f.PreviewUrl));

    }

    if (this.productViewModel.Localizations && this.productViewModel.Localizations.length > 0) {

      this.contentAreaViewModel = new ContentAreaParameter(null, this.productViewModel.Localizations.filter((e: ILocalization) => e.LabelCode === 'description'));

    }

  }

}
