import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StepOneComponent} from './step-one/step-one.component';
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {FormInputModule} from "../../../../../../../components/ct-framework/form-input/form-input.module";

@NgModule({
  declarations: [
    StepOneComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule,
    FormInputModule
  ],
  exports: [StepOneComponent]
})
export class Step1Module {
}
