import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Product } from 'src/app/core/classes/product';
import { Days, IDay } from 'src/app/core/utilities/days';
import { WeekDays } from 'src/app/core/utilities/week-days.enum';

@Component({
  selector: 'app-opening-days',
  templateUrl: './opening-days.component.html',
  styleUrls: ['./opening-days.component.scss']
})
export class OpeningDaysComponent implements OnInit, OnChanges {

  @Input() productData: Product | undefined;

  WEEKDAYS = WeekDays;

  days: Array<IDay> = Days;

  selectedDays: Array<number> = [];

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['productData']) {

      if (this.productData && this.productData.OpeningRatesTime && this.productData.OpeningRatesTime.length > 0) {

        // prendo la stringa di numeri che corrispondono ai giorni selezionati, la trasformo in un
        // array di numeri e poi per ogni giorno della settimana verifico che l'id dello stesso
        // sia presente nel mio array di numeri. Se è presente rendo selected il giorno
        // NB. c'è OpeningRatesTime[0] perché al momento gli openingDays sono uguali per tutti gli orari
        let daysArray = this.productData.OpeningRatesTime[0].OpeningDays.split(',');

        this.days.forEach(d => {
          d.selected = daysArray.map((element: any) => element).includes(d.id.toString());
          if (d.selected) {
            this.selectedDays.push(d.id);
          }
        })

      }

    }

  }

  selectDay(day: IDay) {

    day.selected = !day.selected;

    if (day.selected) {

      this.selectedDays.push(day.id)

    }

    if (!day.selected) {

      this.selectedDays.forEach((item, index) => {
        if (item === day.id) this.selectedDays.splice(index, 1);
      });

    }

  }



}
