import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageProductButtonComponent } from './manage-product-button.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    CtButtonModule,
    MatButtonModule,
    CtFrameworkModule
  ],
  declarations: [ManageProductButtonComponent],
  exports: [ManageProductButtonComponent]
})
export class ManageProductButtonModule { }
