import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubcategoriesListComponent} from './subcategories-list.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    CtFrameworkModule
  ],
  declarations: [SubcategoriesListComponent],
  exports: [SubcategoriesListComponent]
})
export class SubcategoriesListModule {
}
