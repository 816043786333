import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CouponSectionModule} from "./coupon-section/coupon-section.module";
import {FavoritesProductsModule} from "./favorites-products/favorites-products.module";
import {ShareCouponWithFriendsModule} from "./share-coupon-with-friends/share-coupon-with-friends.module";
import {ActivitiesSectionModule} from "./activities-section/activities-section.module";
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import {QrGeneratorModule} from "./_components/qr-generator/qr-generator.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    QrGeneratorModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: 'coupons',
        loadChildren: () => CouponSectionModule
      },
      {
        path: 'favorites',
        loadChildren: () => FavoritesProductsModule
      },
      {
        path: 'share',
        loadChildren: () => ShareCouponWithFriendsModule
      },
      {
        path: '',
        loadChildren: () => ActivitiesSectionModule
      }
    ])
  ]
})
export class CustomerAreaModule {
}
