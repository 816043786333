<div class="general-data-cntr">

  <form *ngIf="form" [formGroup]="form">

    <h3 [innerHTML]="['FORM.SHOP_NAME'|translate]"></h3>

    <app-form-input [textInputViewModel]="{ control: form.get('name'), label: 'FORM.SHOP_NAME' | translate}">
    </app-form-input>

    <h3 [innerHTML]="['FORM.PHONE_NUMBER'|translate]"></h3>

    <app-form-input [textInputViewModel]="{ control: form.get('phone'), label: 'FORM.PHONE_NUMBER' | translate}">
    </app-form-input>

    <h3 [innerHTML]="['FORM.DESCRIPTION'|translate]"></h3>
    <small class="primary-color" [innerHTML]="['FORM.DESCRIPTION-SUBTITLE'|translate]"></small>

    <mat-tab-group mat-align-tabs="center">

      <ng-container formArrayName="description">

        <ng-container *ngFor="let descriptionForm of descriptions.controls">

          <mat-tab [label]="'LANGUAGECODE.'+ descriptionForm.value.LanguageCode | translate"
                   [formGroup]="castAbstractControlToFormControl(descriptionForm)">

            <app-input-text-area
              [textAreaViewModel]="{ control: descriptionForm.get('Value'), label: 'FORM.DESCRIPTION' | translate}">
            </app-input-text-area>

          </mat-tab>

        </ng-container>

      </ng-container>

    </mat-tab-group>

  </form>


</div>
