import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import {Product} from 'src/app/core/classes/product';
import {RatingPosition} from './rate-time.service';
import {TimeSelectComponent} from './time-select/time-select.component';
import {TimeService} from './time-select/time.service';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {
  SlideToggleConfiguration
} from "../../../../../../../components/ct-framework/slide-toggle/slide-toggle.configuration";

@Component({
  selector: 'app-opening-rate-time',
  templateUrl: './opening-rate-time.component.html',
  styleUrls: ['./opening-rate-time.component.scss']
})
export class OpeningRateTimeComponent implements OnInit, OnChanges {

  @Input() productData: Product | undefined;

  addPeriodButton: CtButtonParameters = CtButtonParameters
    .create(() => {
      this.addRateTime(true)
    })
    .setCSS("generalButton box-shadow", true)
    .setContent("FORM.ADD_PERIOD");

  form: UntypedFormGroup;

  slideToggleConfiguration = SlideToggleConfiguration.create()
    .setLabel("FORM.TIME_BANDS")
    .setPrefixLabel("FORM.ALL_DAY");

  isAllDay: boolean = false;
  // ratesTimeFormArray: UntypedFormArray = new UntypedFormArray([]);
  ratingTimeAndPositionData!: RatingPosition;

  @ViewChild(TimeSelectComponent, {static: false})
  timeSelectComponent!: TimeSelectComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private timeService: TimeService
  ) {

    this.form = this.formBuilder.group({
      ratesTimeFormArray: new UntypedFormArray([]),
    });

  }

  ngOnInit() {

    this.addRateTime();

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['productData']) {

      if (this.productData) {

        this.slideToggleConfiguration
          .setControlValue(this.productData.OpeningType == 'all' ? false : true);

        this.isAllDay = this.productData.OpeningType == 'all' ? false : true;

        this.setupRateTimes(this.productData);

      }

    }

  }

  // hoursType = (changed: boolean) => this.isAllDay = changed;

  hoursType(changed: boolean) {

    this.isAllDay = changed;

  }

  get ratesTime(): FormArray {

    return this.form.get('ratesTimeFormArray') as FormArray;

  }

  addRateTime(isAfterOnInit: boolean = false) {

    if (!this.ratesTime.valid) {

      this.ratesTime.markAllAsTouched();

    } else {

      const formGroup: UntypedFormGroup = this.formBuilder.group({
        Start: new UntypedFormControl('09:00'),
        End: new UntypedFormControl('19:00'),
        Position: new UntypedFormControl(this.ratesTime.length > 0 ? this.ratesTime.length : 0),
      });

      if (isAfterOnInit && this.ratesTime.length > 0) {

        const lastEndTimeValue = this.ratesTime.value[this.ratesTime.length - 1];

        formGroup.get('Start')?.setValue(lastEndTimeValue.End);
        // tramite la funzione del time.service aggiungo 1 ora all'orario di apertura
        formGroup.get('End')?.setValue(this.timeService.addTime(lastEndTimeValue.End, 1));

      }

      this.ratesTime.push(formGroup);

    }

  }

  removeRateTime(index: number) {

    this.ratesTime.removeAt(index);

    // ciclo l'array e scalo il numero della posizione agli elementi successivi a quello rimosso
    for (let i = index; i < this.ratesTime.length; i++) {

      this.ratesTime.value[i].position = this.ratesTime.value[i].position - 1;

    }

  }

  setupRateTimes(product: Product) {

    if (product.OpeningRatesTime && product.OpeningRatesTime.length > 0) {

      const rates = product.OpeningRatesTime;

      for (let i = 0; i < rates.length - 1; i++) {

        this.addRateTime()

      }

      this.ratesTime.value.forEach((element: any, index: number) => {


        let toFind = rates.find((item: any) => item.Position === element.Position);

        if (toFind) {

          const form = this.ratesTime.at(index);

          form.get('Start')?.setValue(toFind.Start.slice(0, -3));
          form.get('End')?.setValue(toFind.End.slice(0, -3));

        }

      });

    }

  }

}
