import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Product} from 'src/app/core/classes/product';
import {ISubCategory} from "../../../../../../core/interfaces/category";

@Component({
  selector: 'app-subcategories-list',
  templateUrl: './subcategories-list.component.html',
  styleUrls: ['./subcategories-list.component.scss']
})
export class SubcategoriesListComponent implements OnInit {

  @Input() productData: Product | undefined;
  @Input() subCategoriesList: Array<ISubCategory> = [];

  subCategoryControl = new FormControl();

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  setupSubCategory(list: ISubCategory[] | any, key: string = 'Code') {

    const selectedCodes: Array<string> = list.map((element: any) => element[key]);

    const matchedCodes: ISubCategory[] = this.subCategoriesList.filter((element: any) => selectedCodes.includes(element[key]));

    this.subCategoryControl.setValue(matchedCodes);

  }

  onSubCatDeselected(subCategory: string) {
    const subCats = this.subCategoryControl.value as string[];
    this.removeFirst(subCats, subCategory);
    // this.subCategoryControl.setValue(subCats); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

}
