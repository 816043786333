<div class="maps-content-cntr">

  <mat-dialog-content>

    <div mat-dialog-actions>

      <div class="close-map-button" mat-dialog-close>

        <mat-icon style="font-weight: 800;">cancel</mat-icon>

      </div>

    </div>
    <ng-container *ngIf="this.viewModel.coordinates.latitude && this.viewModel.coordinates.longitude">
      <agm-map [latitude]="this.viewModel.coordinates.latitude" [longitude]="this.viewModel.coordinates.longitude"
               [disableDefaultUI]="true" [zoom]="14" [styles]="style">

        <agm-marker *ngFor="let item of data.products"
                    [latitude]="item.Place?.Latitude ?? 0"
                    [longitude]="item.Place?.Longitude ?? 0"
                    [iconUrl]="getMarkerData(item)"
                    (markerClick)="changeInfo(item)">


          <agm-info-window #infoWindowDetail [isOpen]="true">

            <app-map-info-window [detailCard]="data.product" mat-dialog-close></app-map-info-window>

          </agm-info-window>

        </agm-marker>

      </agm-map>
    </ng-container>


  </mat-dialog-content>

</div>
