import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {CategoryController} from 'src/app/core/controllers/category.controller';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {ICategory} from "../../../core/interfaces/category";
import {CategoriesService} from "../../research/categories/categories.service";

@Component({
  selector: 'app-button-section',
  templateUrl: './button-section.component.html',
  styleUrls: ['./button-section.component.scss']
})
export class ButtonSectionComponent implements OnInit {

  viewModel = {

    loading: false

  }

  mapButton = CtButtonParameters
    .create(() => this.showGeneralMap())
    .setCSS("map-icon-box mat-icon-button", true)
    .setImage('/assets/images/icons/map_filter.svg')
    .setImageCss('smallIcon')
    .setContent("CTBUTTON.MAP");

  categoryListButtons: Array<CtButtonParameters> = [];

  constructor(
    private categoryController: CategoryController,
    private appGeneralService: AppGeneralService,
    private categoriesService: CategoriesService
  ) {
  }

  ngOnInit() {

    this.getCategories();

  }

  getCategories() {

    this.viewModel.loading = true;

    this.categoryController
      .list()
      .then((categories: ICategory[]) => {

        categories
          .slice(0, 5)
          .forEach((element: any) => {

            this.categoryListButtons
              .push(this.categoriesService.getButton('CATEGORY.' + element.Code, element.Code, () => this.categoriesService.goToSearch(element)));

          });

        this.categoryListButtons
          .push(this.categoriesService.getButton("GENERAL.ALL_CATEGORIES", "group-points", () => this.showCategories()));

        this.viewModel.loading = false;

      });

  }


  showCategories = () => this.appGeneralService.navigateTo('/research/categories');

  showGeneralMap = () => this.appGeneralService.navigateTo('/research/list');

}


