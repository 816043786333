import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SetNewPasswordComponent} from './set-new-password.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {FormInputModule} from "../../../components/ct-framework/form-input/form-input.module";

@NgModule({
  imports: [
    CtFrameworkModule,
    CommonModule,
    CtButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule
  ],
  declarations: [SetNewPasswordComponent],
  exports: [SetNewPasswordComponent]
})
export class SetNewPasswordModule {
}
