import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsComponent} from './notifications.component';
import {NotificationAreaModule} from './components/notification-area/notification-area.module';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    NotificationAreaModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: '',
        component: NotificationsComponent
      }
    ])
  ],
  declarations: [NotificationsComponent],
  exports: [NotificationsComponent]
})
export class NotificationsModule {
}
