import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-coupon-with-friends',
  templateUrl: './share-coupon-with-friends.component.html',
  styleUrls: ['./share-coupon-with-friends.component.scss']
})
export class ShareCouponWithFriendsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
