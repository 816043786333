<mat-form-field [appearance]="registration ? 'outline' : 'fill'" [class.localization-switch-container]="!registration"
                [class.w-100]="registration">
  <mat-label *ngIf="registration"> {{ 'GENERAL.LOCALITY' | translate }} </mat-label>
  <mat-select [formControl]="control" (selectionChange)="select()">
    <mat-option *ngFor="let locality of locations" [value]="locality">
      {{locality.City | translate}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="control?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate}}</mat-error>
</mat-form-field>
