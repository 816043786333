import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoBusinessCreateComponent} from './logo-business-create.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {FileUploadModule} from '../../file-upload/file-upload.module';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    FileUploadModule,
    CtFrameworkModule
  ],
  declarations: [LogoBusinessCreateComponent],
  exports: [LogoBusinessCreateComponent]
})
export class LogoBusinessCreateModule {
}
