import {Injectable} from "@angular/core";
import {IProfile} from "../classes/profile";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {UserCoupon} from "../classes/user-coupon";

@Injectable()
export class ProfileController {

  controller: string = '/Profile/';

  params: any = {}

  constructor(
    private ctWebapiService: CtWebapiService
  ) {
  }

  put(profile: IProfile) {

    return this.ctWebapiService
      .put(new DataRequest(`${this.controller}Put`), profile);

  }

  get(): Promise<any> {

    return new Promise<any>((resolve) => this.ctWebapiService
      .get(new DataRequest(`${this.controller}Get`)).subscribe(d => resolve(d as any)));

  }

  putAvatar(avatarCode: string) {

    return this.ctWebapiService
      .put(new DataRequest(`${this.controller}PutAvatar`), {AvatarCode: avatarCode});

  }

  coupon(): Promise<Array<UserCoupon>> {

    return new Promise<Array<UserCoupon>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}Coupon`);

      this.ctWebapiService
        .get(request)
        .subscribe((d: CtWebapiGenericResponse<any>) => resolve(<Array<UserCoupon>>d.Result));

    });

  }

  changeLocation(locationOid: number): Promise<CtWebapiGenericResponse<any>> {

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}ChangeLocation`, {locationOid});

      this.ctWebapiService
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<any>) => resolve(response));

    });

  }

  changeLanguage(languageCode: string): Promise<CtWebapiGenericResponse<any>> {

    return new Promise<CtWebapiGenericResponse<any>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}ChangeLanguage`, {languageCode});

      this.ctWebapiService
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<any>) => resolve(response));

    });

  }

}
