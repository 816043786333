<div class="activities-section-cntr p-footer">

  <h1 class="primary-color personal-area-title text-center mt-4"
      [innerHTML]="['GENERAL.YOUR_VICTORIA_CARD' | translate]"></h1>

  <div>

    <app-my-coupons-list></app-my-coupons-list>

  </div>

  <mat-card class="position-relative pt-0 container">

    <mat-list role="list">

      <mat-list-item role="listitem">

        <h1 class="p-0 text-center" [innerHTML]="['BALANCE.TITLE'|translate] + ':'"></h1>

        <app-user-point-balance></app-user-point-balance>

      </mat-list-item>

    </mat-list>

    <ct-button [parameters]="vpoints"></ct-button>

    <hr>

    <mat-list role="list" class="container pt-0">

      <mat-list-item role="listitem" class="p-0">

        <app-savings></app-savings>

      </mat-list-item>

    </mat-list>

    <hr>

    <ct-button [parameters]="toggleActionsViewButton"></ct-button>

    <mat-list role="list" class="container" [ngClass]="status ? 'show' : 'hide'">

      <mat-list-item role="listitem" style="border-bottom: none;">

        <app-actions></app-actions>

      </mat-list-item>

    </mat-list>

  </mat-card>

</div>
