import { Component, OnInit } from '@angular/core';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { ProfileController } from 'src/app/core/controllers/profile.controller';
import { AppGeneralService } from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-personal-avatar',
  templateUrl: './personal-avatar.component.html',
  styleUrls: ['./personal-avatar.component.scss']
})
export class PersonalAvatarComponent implements OnInit {

  avatarViewModel: any = {
    avatars: this.getAvatarList()
  }

  constructor(
    private appGeneralService: AppGeneralService,
    private profileController: ProfileController,
    private ctAuthenticationService: CtAuthenticationService
  ) { }

  ngOnInit() {
  }

  getAvatarList(): Array<CtButtonParameters> {

    let avatarList: Array<CtButtonParameters> = [];

    let imageName: string = '';

    for (let index = 1; index < 22; index++) {

      imageName = '/assets/images/avatars/avatar_' + index + '.svg';

      avatarList.push(CtButtonParameters
        .create(() => { this.setAvatar(index.toString()) })
        .setImage(imageName))

    }

    return avatarList;

  }

  setAvatar(avatarCode: string) {

    this.profileController
      .putAvatar(avatarCode)
      .subscribe(() =>

        this.ctAuthenticationService
          .getClaims()?.then(() =>

            this.appGeneralService.navigateTo('/personal-area/profile/')));

  }

}
