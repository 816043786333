<div class="search-list p-footer" id="top">

  <div class="search-list-map-cntr">

    <app-maps [products]="list"></app-maps>

    <app-search-active-filters></app-search-active-filters>

  </div>

  <div class="container products-list-cntr">

    <ng-container
      *ctSkeleton="viewModel.loading;
           repeat: 6;
           className: 'card-detail-skeleton';">

      <ng-container *ngIf="list.length > 0; else emptyListTplt">

        <app-search-card [product]="product" *ngFor="let product of list"></app-search-card>

      </ng-container>

      <ng-template #emptyListTplt>

        <p class="text-center mt-5"> {{ 'NO_AVAILABLE_ELEMENTS' | translate }} </p>

      </ng-template>

    </ng-container>

  </div>

</div>
