import {Component, OnDestroy, OnInit} from '@angular/core';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Subscription} from "rxjs";
import { JwtService } from 'src/app/core/library/jwt.service';

@Component({
  selector: 'app-user-point-balance',
  templateUrl: './user-point-balance.component.html',
  styleUrls: ['./user-point-balance.component.scss']
})
export class UserPointBalanceComponent implements OnInit, OnDestroy {

  userBalance: string  = '';
  subscription?: Subscription;

  constructor(
    private ctAuthenticationService: CtAuthenticationService,
    private jwt: JwtService
  ) {

    this.subscription = this.ctAuthenticationService
    .user
    .subscribe(() => {

      this.userBalance = this.jwt.getBalance();

    });

  }

  ngOnInit(){}


  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
