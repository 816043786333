import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuideComponent } from './guide.component';
import { CtCarouselIvyModule } from 'src/app/components/ct-framework/ct-carousel-ivy/ct-carousel-ivy.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    CtCarouselIvyModule,
    MatIconModule
  ],
  declarations: [GuideComponent],
  exports: [GuideComponent]
})
export class GuideModule { }
