import {Component, Input, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {DrawerService} from '../../drawer.service';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {Subscription} from 'rxjs';
import {JwtService} from "../../../core/library/jwt.service";

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})

export class ProfileButtonComponent implements OnInit {

  @Input() buttonMenu!: CtButtonParameters;

  personalImageButton: CtButtonParameters = CtButtonParameters
    .create(() => {
      this.goToProfile()
    })
    .setImageCss("w-100")
    .setCSS("w-100 fakeImage box-shadow mat-icon-button", true);

  subscription: Subscription;

  viewModel: any = {

    name: null,
    surname: null,
    email: null

  }

  constructor(
    private drawerService: DrawerService,
    private appGeneralService: AppGeneralService,
    private ctAuthenticationService: CtAuthenticationService,
    private jwt: JwtService
  ) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        this.personalImageButton
          .setImage(this.jwt.getUserAvatarSrc());

        this.viewModel.name = this.jwt.getName();
        this.viewModel.surname = this.jwt.getSurname();
        this.viewModel.email = this.jwt.getEmail();

      });

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

  }

  goToProfile() {

    this.drawerService.toggle();
    this.appGeneralService.navigateTo('/personal-area/profile');

  }

  ngOnDestroy(): void {

    this.subscription
      .unsubscribe();

  }

}
