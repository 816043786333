<div class="special-discount-create-cntr">

<h1 class="text-center" [innerHTML]="['BUSINESS.CREATE_YOUR_DISCOUNT'|translate]"></h1>

<form [formGroup]="form">

  <mat-form-field class="w-100 b-radius mb-4" appearance="outline">

    <mat-label> {{ 'FORM.TITLE' | translate }} </mat-label>
    <input matInput type="text" autocomplete="off" formControlName="Title" required
           [placeholder]="'DISCOUNTS.TITLE_PLACEHOLDER'| translate">
    <mat-hint> {{ 'DISCOUNTS.TITLE_HINT' | translate }}</mat-hint>
    <mat-error *ngIf="form.get('Title')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
    </mat-error>
    <mat-error *ngIf="form.get('Title')?.hasError('maxlength')">* {{ 'FORM.INVALID_FIELD' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-100 b-radius mb-4" appearance="outline">

    <mat-label> {{ 'FORM.DESCRIPTION_DISCOUNT' | translate }} </mat-label>

    <textarea matInput formControlName="Description" required></textarea>

    <mat-hint> {{ 'DESCRIPTION_DISCOUNT.HINT' | translate }}</mat-hint>

    <mat-error *ngIf="form.get('Description')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
    </mat-error>
    <mat-error *ngIf="form.get('Description')?.hasError('maxlength')">* {{ 'FORM.INVALID_FIELD' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-100 b-radius mb-4" appearance="outline">

    <mat-label> {{ 'BUSINESS.DISCOUNT_PERCENTAGE' | translate }} </mat-label>
    <input matInput type="number" autocomplete="off" formControlName="Discount" required>
    <mat-hint> {{ 'DISCOUNTS.PERCENTAGE_HINT' | translate }}</mat-hint>
    <mat-error *ngIf="form.get('Discount')?.hasError('required')">* {{ 'GENERAL.REQUIRED_FIELD' | translate }}
    </mat-error>
    <mat-error *ngIf="form.get('Discount')?.hasError('min') || form.get('Discount')?.hasError('max')">
      * {{ 'FORM.INVALID_FIELD' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label> {{ 'BUSINESS.DISCOUNT_VALIDITY' | translate}}</mat-label>

    <mat-date-range-input
      [min]="viewModel.currentDate"
      [rangePicker]="picker" (click)="picker.open()">
      <input matStartDate formControlName="Start" #startDate>
      <input matEndDate formControlName="End" readonly>
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="form.get('Start')?.hasError('required') || form.get('End')?.hasError('required')">
      * {{ 'GENERAL.REQUIRED_FIELD' | translate }} </mat-error>

  </mat-form-field>

  <div class="mb-2">

    <label> {{ "BUSINESS.DISCOUNT_MODE" | translate }} </label>
    <mat-radio-group
      class="discount-radio-group"
      formControlName="TypeOfUse">

      <mat-radio-button class="discount-radio-button" [value]="TYPE_OF_USE.SingleUse">
        {{"BUSINESS.DISCOUNT_SINGLE_MODE" | translate }}
      </mat-radio-button>

      <mat-radio-button class="discount-radio-button" [value]="TYPE_OF_USE.MultipleUse">
        {{"BUSINESS.DISCOUNT_MULTIPLE_MODE" | translate }}
      </mat-radio-button>

    </mat-radio-group>

  </div>

  <mat-dialog-actions class="justify-content-between">

    <ct-button [parameters]="cancelButton"></ct-button>

    <ct-button [parameters]="createButton"></ct-button>

  </mat-dialog-actions>

</form>

</div>
