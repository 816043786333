import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {ProfileFormComponent} from './profile-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from '@angular/router';
import {AgmCoreModule} from '@agm/core';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CtMapsModule} from '@ctsolution/ct-maps';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
  LocalizationSwitchSelectModule
} from "../../../../../../layout/full/navbar/localization-switch-select/localization-switch-select.module";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    RouterModule,
    AgmCoreModule,
    MatGoogleMapsAutocompleteModule,
    CtMapsModule,
    CtFrameworkModule,
    MatDatepickerModule,
    LocalizationSwitchSelectModule
  ],
  declarations: [ProfileFormComponent],
  exports: [ProfileFormComponent]
})
export class ProfileFormModule {
}
