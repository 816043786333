import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {FilterProduct, SearchService} from "../../../search-filters/search-filters/search.service";
import {DISTANCE_FILTERS} from "../../../search-filters/_components/distance-filter/distance-filter.component";
import {ORDER_FILTER, ORDER_FILTERS} from "../../../search-filters/_components/order-filter/order-filter.component";

@Component({
  selector: 'app-active-filters-list',
  templateUrl: './active-filters-list.component.html',
  styleUrls: ['./active-filters-list.component.scss']
})
export class ActiveFiltersListComponent implements OnInit {

  private qp: any = {};
  params = (): string[] => Object.keys(this.qp);

  constructor(private route: ActivatedRoute, public searchService: SearchService) {

    this.route
      .queryParams
      .subscribe(qp => {

        this.qp = qp ?? {}

      });

  }

  remove(key: string) {

    this.searchService.filters.setValue(key, null);
    this.searchService.search();

  }

  getLabel(key: string): string {

    const value: any = this.qp[key];

    switch (key) {

      case 'CategoryCodes':
        return `CATEGORY.${value}`;
      case 'PriceCategory':
        return this.getCurrency(+value);
      case 'DistanceMeter':
        return this.getDistanceLabel(+value);
      case 'Name':
        return value;
      default:
        return this.getOrderLabel(key); // questo è un po cablato, ma per ora teniamo cosi

    }

  }

  private getCurrency(index: number = 1) {

    const currency: string = '€';
    let toReturn: string = '';

    for (let i = 0; i <= index; i++) {

      toReturn += currency;

    }

    return toReturn;

  }

  private getDistanceLabel = (value: number): string => DISTANCE_FILTERS.find((elm: { key: string, value: number }) => elm.value === value)?.key ?? '';

  private getOrderLabel = (value: string): string => ORDER_FILTERS.find((elm: { key: string, value: ORDER_FILTER }) => elm.value === value)?.key ?? '';

  ngOnInit(): void {
  }

}
