<div class="share-discount-code-cntr">

  <div class="container">

    <div class="d-flex share-discount-content m-auto">

      <ct-button [parameters]="btnShareCode"></ct-button>
      <ct-button [parameters]="btnPrice"></ct-button>

    </div>

  </div>

</div>
