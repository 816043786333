import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatBottomSheet, MatBottomSheetConfig} from '@angular/material/bottom-sheet';
import {CtAuthenticationService} from '@ctsolution/ct-authentication';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {Subscription} from 'rxjs';
import {IProductPlace, Product} from 'src/app/core/classes/product';
import {MarketPlaceController, MarketPlaceListParameter} from 'src/app/core/controllers/market-place.controller';
import {JwtService} from 'src/app/core/library/jwt.service';
import {ActivatedRoute} from "@angular/router";
import {BackButtonService} from "../../../../layout/_components/back-button/back-button.service";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {Coupon} from "../../../../core/classes/coupon";
import {IFile} from "@ctsolution/ct-file-management";
import {FileType} from "../../../../core/enum/file-type.enum";
import {ICategory} from "../../../../core/interfaces/category";
import {VpointFiltersComponent} from "./vpoint-filters/vpoint-filters.component";
import { AppGeneralService } from 'src/app/core/library/app-general.service';
import { MatDialog } from '@angular/material/dialog';
import { NoVpointsDialogComponent } from './no-vpoints-dialog/no-vpoints-dialog.component';

@Component({
  selector: 'app-coupon-section',
  templateUrl: './coupon-section.component.html',
  styleUrls: ['./coupon-section.component.scss']
})
export class CouponSectionComponent implements OnInit, OnDestroy {

  private productCoupons: Array<ProductCoupon> = new Array<ProductCoupon>();
  $productCoupons: Array<ProductCoupon> = new Array<ProductCoupon>();


  balance: string = '';
  btnCategoriesFilter: CtButtonParameters = CtButtonParameters
    .create(() => this.openCategoriesFilterPanel())
    .setMaterialIcon('keyboard_arrow_down')
    .setCSS('filterArrowIcon mat-raised-button mat-icon-button')
    .setContent("CTBUTTON.CATEGORIES")

    btnRedirectHome: CtButtonParameters = CtButtonParameters

    .create(() =>{ this.general.navigateTo('/home')})
    .setCSS("generalButton box-shadow", true)
    .setContent("CTBUTTON.BACKHOME")

  subscription?: Subscription;

  viewModel = {

    ProductId: null,
    ProductName: null,
    loading: false

  }

  constructor(
    private marketPlaceController: MarketPlaceController,
    private ctAuthenticationService: CtAuthenticationService,
    private jwt: JwtService,
    private _bottomSheet: MatBottomSheet,
    private backService: BackButtonService,
    private route: ActivatedRoute,
    private general: AppGeneralService,
    private dialog: MatDialog
  ) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        if (this.ctAuthenticationService.userValueContainsClaimsRegistry()) {

          this.getBalance();

          this.route
            .queryParams
            .subscribe(qp => {

              this.viewModel.ProductId = qp['ProductId'] ?? null;
              this.viewModel.ProductName = qp['ProductName'] ?? 'Shop';

              this.getList();

            });

        }

      });

  }

  removeShopFilter() {

    this.viewModel.ProductId = null;
    this.viewModel.ProductName = null;

    this.getList();

  }

  getList() {

    this.productCoupons = [];


    this.viewModel.loading = true;
    const searchParameter: MarketPlaceListParameter = new MarketPlaceListParameter();

    if (this.viewModel.ProductId) {

      searchParameter.ProductOid = +this.viewModel.ProductId;

    }

    this.marketPlaceController
      .list(searchParameter)
      .then(((response: CtWebapiGenericResponse<any>) => {

        const products: Array<Product> = (response.Result ?? []) as Array<Product>;

        products
          .filter((product: Product) => !!product.Coupons)
          .forEach((product: Product) => product.Coupons!.forEach((coupon: Coupon) => this.productCoupons.push(this.getReflectedProductCoupon(product, coupon))))

        this.productCoupons
          .sort((a: ProductCoupon, b: ProductCoupon) => (a.Coupon?.checkIfPurchasableWithVPoints(+this.balance) === b.Coupon?.checkIfPurchasableWithVPoints(+this.balance)) ? 0 : a.Coupon?.checkIfPurchasableWithVPoints(+this.balance) ? -1 : 1);

        this.$productCoupons = this.productCoupons;

        this.viewModel.loading = false;

      }))

  }

  private getReflectedProductCoupon = (product: Product, coupon: Coupon) => new ProductCoupon(product.Id, product.Name, product.Place, product.IsOpen, product.Files, product.Category, new Coupon(coupon));

  getBalance = () => this.balance = this.jwt.getBalance();

  openNoVpointDialog(p: ProductCoupon){

      this.dialog.open(NoVpointsDialogComponent, {panelClass: 'no-vpoint-dialog-container'})

  }

  ngOnInit() {

    this.backService.resetLatestUrl();

    //TODO METTERE LA FUNZIONE PER APRIRE LA DIALOG DI PUNTI INSUFFICIENTI

  }

  openCategoriesFilterPanel() {

    this._bottomSheet
      .open(VpointFiltersComponent, {panelClass: 'v-points-categories-filters-panel', disableClose: false})
      .afterDismissed()
      .subscribe((category: ICategory | undefined) => {

        if (category === null) {

          this.$productCoupons = this.productCoupons;

        } else if (!!category) {

          this.$productCoupons = this.productCoupons.filter((element: ProductCoupon) => element.Category?.Code === category.Code)

        }

      });

  }

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}

// non sono molto d'accordo con questa classe, la sto riscrivendo per averla con il solo coupon, bah, anyways
export class ProductCoupon {

  constructor(
    public ProductOid: number = 0,
    public ProductName: string | null = null,
    public Place: IProductPlace | null = null,
    public IsOpen: boolean = false,
    public Files: IFile[] = [],
    public Category: ICategory | null = null,
    public Coupon: Coupon | null = null
  ) {
  }

  getLogo = () => this.Files.find((file: IFile) => file.Type === FileType.LOGO)?.PreviewUrl ?? '/assets/images/placeholder-images/generic-logo/logo.png';

}
