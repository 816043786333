import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonalAvatarComponent} from './personal-avatar.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: '',
        component: PersonalAvatarComponent
      }
    ])
  ],
  declarations: [PersonalAvatarComponent]
})
export class PersonalAvatarModule {
}
