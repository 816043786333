import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MapsComponent} from './maps.component';
import {SearchBarModule} from 'src/app/components/search-bar/search-bar.module';
import {AgmCoreModule} from '@agm/core';
import {MapsContentComponent} from './maps-content/maps-content.component';
import {CtButtonModule} from '@ctsolution/ct-framework';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {LocationSearchBarModule} from 'src/app/components/location-search-bar/location-search-bar.module';
import {MapInfoWindowModule} from './map-info-window/map-info-window.module';
import {SearchCardModule} from "../../../search-card/search-card.module";

@NgModule({
  imports: [
    CommonModule,
    SearchBarModule,
    AgmCoreModule,
    SearchCardModule,
    CtButtonModule,
    MatIconModule,
    MatDialogModule,
    LocationSearchBarModule,
    MapInfoWindowModule
  ],
  declarations: [MapsComponent, MapsContentComponent],
  exports: [MapsComponent, MapsContentComponent]
})
export class MapsModule {
}
