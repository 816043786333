<div class="profile-menu-cntr container">

  <ng-container *ngFor="let button of buttons">

    <div class="mb-4 container">

      <div class="mt-auto mb-auto box-shadow b-radius-1">

        <ct-button [parameters]="button"></ct-button>

      </div>

    </div>

  </ng-container>

</div>
