<div class="user-point-balance-cntr mb-4">

  <div class="text-center">

    <p class="points-size"><b class="primary-color">
      <app-vpoint-label [amount]="+userBalance"></app-vpoint-label>
    </b></p>

  </div>

</div>
