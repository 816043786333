import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivitiesSectionComponent} from './activities-section.component';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {SavingsModule} from './activities/savings/savings.module';
import {ActionsModule} from './activities/actions/actions.module';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MyCouponsListModule} from "./my-coupons-list/my-coupons-list.module";
import {RouterModule} from "@angular/router";
import {UserPointBalanceModule} from "../../_components/user-point-balance/user-point-balance.module";
import {TransactionController} from "../../../../core/controllers/transaction.controller";
import {CtCarouselIvyModule} from "../../../../components/ct-framework/ct-carousel-ivy/ct-carousel-ivy.module";

@NgModule({
  imports: [
    CommonModule,
    CtCarouselIvyModule,
    CtButtonModule,
    MatCardModule,
    MatListModule,
    SavingsModule,
    ActionsModule,
    CtFrameworkModule,
    UserPointBalanceModule,
    MyCouponsListModule,
    RouterModule.forChild([
      {
        path: '',
        component: ActivitiesSectionComponent
      }
    ])
  ],
  declarations: [ActivitiesSectionComponent],
  providers: [TransactionController]
})
export class ActivitiesSectionModule {
}
