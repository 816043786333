import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TransactionResultDto} from "../../../../../../../../core/classes/transaction-result-dto";
import {TransactionStatus} from "../../../../../../../../core/enum/transaction-status.enum";

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  @Input() transaction: TransactionResultDto | null = null;
  @Output() transactionChange = new EventEmitter<TransactionResultDto | null>();

  constructor() {
  }

  ngOnInit(): void {
  }

  successfullyTransaction = (): boolean => this.transaction?.Status === TransactionStatus.Success;

  resultImageSrc = (): string => `/assets/images/thank-you-page/` + (this.successfullyTransaction() ? 'payment_success' : 'payment_failed') + `.svg`

}
