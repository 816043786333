<div class="my-coupons-list-container container mb-4">

  <carousel
    [dots]="viewModel.carouselSettings.dots"
    [cellWidth]="viewModel.carouselSettings.cellWidth"
    [height]="viewModel.carouselSettings.height"
    [arrows]="viewModel.carouselSettings.arrows"
    [autoplay]="viewModel.carouselSettings.autoplay">

    <ng-container
      *ctSkeleton="viewModel.loading;
           repeat: 3;
           className: 'carousel-cell';">

      <app-my-coupon
        class="carousel-cell"
        [class.active]="isVisibleInViewport('coupon_' + i)"
        [id]="'coupon_' + i"
        *ngFor="let couponConfiguration of viewModel.coupons; let i = index;"
        [configuration]="couponConfiguration"
        (updateList)="getCoupons()">
      </app-my-coupon>

    </ng-container>

  </carousel>

</div>
