import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {SpecialDiscountCreateComponent} from "../special-discount-create/special-discount-create.component";
import {MatDialog} from "@angular/material/dialog";
import {
  BusinessDiscount,
  BusinessDiscountController
} from "../../../../../core/controllers/business-discount.controller";
import {CouponTemplateStatus, CouponTemplateType} from "../../../../../core/enum/coupons-enums";

const MAX_SPECIAL_DISCOUNT_LENGTH: number = 5;

@Component({
  selector: 'app-special-discount-list',
  templateUrl: './special-discount-list.component.html',
  styleUrls: ['./special-discount-list.component.scss']
})
export class SpecialDiscountListComponent implements OnInit {

  create: CtButtonParameters = CtButtonParameters
    .create(() => this.createDiscount())
    .setCSS("generalButton create-discount", true)
    .setContent("BUSINESS.CREATE_SPECIAL_DISCOUNT");

  viewModel: { approved: Array<BusinessDiscount>, approval: Array<BusinessDiscount>, selectedIndex: number, specialDiscountCreationDisabled: boolean } = {

    approved: [],
    approval: [],
    selectedIndex: 0,
    specialDiscountCreationDisabled: false

  }

  constructor(private discountController: BusinessDiscountController, private dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.getDiscounts();

  }

  getDiscounts(switchToApproval: boolean = false) {

    this.discountController
      .get()
      .then((result: Array<BusinessDiscount>) => {

        this.viewModel.approved = result.filter((discount: BusinessDiscount) => [CouponTemplateStatus.Active, CouponTemplateStatus.Cancelled, CouponTemplateStatus.Rejected].includes(discount.Status!));
        this.sortApprovedDiscounts();

        this.viewModel.approval = result.filter((discount: BusinessDiscount) => discount.Status === CouponTemplateStatus.Draft);

        this.viewModel.specialDiscountCreationDisabled = this.viewModel.approved.filter((value: BusinessDiscount) => value.Status === CouponTemplateStatus.Active && value.Type === CouponTemplateType.Special).length >= MAX_SPECIAL_DISCOUNT_LENGTH;

        if (switchToApproval) {

          this.viewModel.selectedIndex = 1;

        }

      });

  }

  sortApprovedDiscounts() {

    this.viewModel.approved = this.viewModel.approved.reduce((acc: BusinessDiscount[], element: BusinessDiscount) => {
      if (element.Type === CouponTemplateType.Standard) return [element, ...acc];
      return [...acc, element];
    }, []);


  }

  getStandardDiscounts = (): BusinessDiscount[] => this.viewModel.approved.filter((discount: BusinessDiscount) => discount.Type === CouponTemplateType.Standard);

  getMinDiscountValue = (): number => this.getStandardDiscounts().sort((discountA: BusinessDiscount, discountB: BusinessDiscount) => (discountA.Discount ?? 0) - (discountB.Discount ?? 0))[0]?.Discount ?? 1;

  createDiscount() {

    const dialogData = {
      disableClose: true,
      panelClass: 'dialog-special-discount-container',
      data: {
        minDiscountValue: this.getMinDiscountValue(),
        
      }
    }

    this.dialog
      .open(SpecialDiscountCreateComponent, dialogData)
      .afterClosed()
      .subscribe((refresh?: boolean) => {

        if (refresh) {

          this.getDiscounts(true);

        }

      })

  }

}
