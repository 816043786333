import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationExtras, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppGeneralService {

  constructor(
    private location: Location,
    private router: Router,
  ) {
  }

  back = () => this.location.back();

  navigateTo = (url: string, extras?: NavigationExtras) => this.router.navigate([url], extras);

  redirectToHome = () => this.navigateTo('/home');

}
