import {Injectable} from '@angular/core';
import {Category} from "../enum/category";
import {IFile} from "@ctsolution/ct-file-management";
import {ICategory} from "../interfaces/category";

const PLACEHOLDER_ASSETS_PATH: string = '/assets/images/placeholder-images/';

const DEFAULT_BACKGROUND_PLACEHOLDERS: string[] = [
  'background-placeholder-1.webp',
  'background-placeholder-2.webp'
];

export const DEFAULT_LOGO: string = `${PLACEHOLDER_ASSETS_PATH}generic-logo/logo-w-background.png`;

@Injectable({
  providedIn: 'root'
})
export class ShopHelperService {

  constructor() {
  }

  private getPlaceHolderImagesPathByCategoryCode = (code: Category): string => PLACEHOLDER_ASSETS_PATH + code + '/';

  getDefaultPicturesByCategory(category: ICategory): IFile[] {

    if (!category.Code) {

      return []

    }

    return DEFAULT_BACKGROUND_PLACEHOLDERS.map((element: string) => <IFile>{PreviewUrl: this.getPlaceHolderImagesPathByCategoryCode(<Category>category.Code) + element});

  }

}
