import {Component, OnInit} from '@angular/core';
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-vpoint-filters',
  templateUrl: './vpoint-filters.component.html',
  styleUrls: ['./vpoint-filters.component.scss']
})
export class VpointFiltersComponent implements OnInit {

  constructor(private _matbottom: MatBottomSheetRef<VpointFiltersComponent>) {
  }

  ngOnInit(): void {
  }

  filterByCategory(event: any) {

    this._matbottom
      .dismiss(event)

  }

}
