import { TemplateRef } from "@angular/core";
import { ICtFunction } from "@ctsolution/ct-framework";


export class CtCardParameters{

    action!: ICtFunction;

    //OPTIONAL

    title?: string;
    previewCoverUrl?: string;
    previewLogoImageUrl?: string;

    infoTemplate: TemplateRef<any> | null;

    constructor(action:ICtFunction){
        this.action = action;
        this.infoTemplate = null;
    }

    static Create(action:ICtFunction):CtCardParameters{
        return new CtCardParameters(action);
    }

    setInfoTemplate(value: TemplateRef<any>):CtCardParameters
    {
        this.infoTemplate = value
        return this;
    }
    setTitle(value: string):CtCardParameters
    {
        this.title = value
        return this;
    }
}
