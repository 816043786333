import {Component, OnDestroy, OnInit} from '@angular/core';
import {TransactionResultDto} from "../../../../../../core/classes/transaction-result-dto";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import { MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {SnackbarService} from "../../../../../../core/library/snackbar.service";
import {RoleHelperService} from "../../../../../../core/library/role-helper.service";
import {TransactionStatus} from "../../../../../../core/enum/transaction-status.enum";

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit, OnDestroy {

  transaction: TransactionResultDto | null = null;

  STATUS = TransactionStatus;

  private subscription?: Subscription;

  viewModel = {

    scannerEnabled: false

  }

  constructor(private ctAuthenticationService: CtAuthenticationService, private dialog: MatDialogRef<QrScannerComponent>, private snackbar: SnackbarService, private roleHelper: RoleHelperService) {
  }

  ngOnInit(): void {

    this.checkBusinessUserEnabled();

  }

  checkBusinessUserEnabled() {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        if (this.ctAuthenticationService.userValueContainsClaimsRegistry()) {

          this.viewModel.scannerEnabled = this.ctAuthenticationService.getJwtValueByContainedKey('EnableScanning') === 'True';

          if (!this.viewModel.scannerEnabled) {

            this.dialog
              .afterClosed()
              .subscribe(() => {

                this.snackbar.generalMessage("SNACKBAR.SHOP_DISABLE")
                if (this.roleHelper.isEmployee()) this.ctAuthenticationService.signOut();

              });

            this.dialog.close();


          }

        }

      });

  }

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
