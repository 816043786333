import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchDetailComponent} from './search-detail/search-detail.component';
import {ManageProductButtonModule} from "../_components/manage-product-button/manage-product-button.module";
import {CtCarouselIvyModule} from "../../../../components/ct-framework/ct-carousel-ivy/ct-carousel-ivy.module";
import {InfoSectionModule} from "./_components/info-section/info-section.module";
import {TextAreaModule} from "./_components/content-area/content-area.module";
import {MatChipsModule} from "@angular/material/chips";
import {CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    SearchDetailComponent
  ],
  imports: [
    CommonModule,
    ManageProductButtonModule,
    CtCarouselIvyModule,
    InfoSectionModule,
    TextAreaModule,
    MatChipsModule,
    CtFrameworkModule,
    CtSkeletonModule
  ]
})
export class SearchDetailModule {
}
