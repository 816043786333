import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BusinessProductController} from "../../../../../core/controllers/business-product.controller";
import {CouponTemplateTypeOfUseVO} from "../../../../../core/enum/coupons-enums";
import {
  BusinessDiscount,
  BusinessDiscountController
} from "../../../../../core/controllers/business-discount.controller";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {Product} from "../../../../../core/classes/product";

const DISCOUNT_DEFAULT_VALIDATORS = [Validators.required, Validators.max(90)];

@Component({
  selector: 'app-special-discount-create',
  templateUrl: './special-discount-create.component.html',
  styleUrls: ['./special-discount-create.component.scss'],
  providers: [BusinessProductController]
})
export class SpecialDiscountCreateComponent implements OnInit {

  viewModel = {

    currentDate: new Date()

  }

  form: FormGroup;

  cancelButton: CtButtonParameters = CtButtonParameters
    .create(() => this.cancel())
    .setCSS("cancel-button", true)
    .setContent("BUTTON.CANCEL");

  createButton: CtButtonParameters = CtButtonParameters
    .create(() => this.create())
    .setCSS("generalButton ps-3 pe-3 create-discount", true)
    .setContent("BUSINESS.CREATE_SPECIAL_DISCOUNT");

  TYPE_OF_USE = CouponTemplateTypeOfUseVO;

  constructor(
    private fb: FormBuilder,
    private businessDiscount: BusinessDiscountController,
    private businessProduct: BusinessProductController,
    private dialog: MatDialogRef<SpecialDiscountCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      minDiscountValue: number
    }) {

    this.form = this.fb.group({
      Title: new FormControl(null, [Validators.required, Validators.maxLength(120)]),
      Description: new FormControl(null, [Validators.required, Validators.maxLength(512)]),
      Discount: new FormControl(null, DISCOUNT_DEFAULT_VALIDATORS),
      TypeOfUse: new FormControl(CouponTemplateTypeOfUseVO.SingleUse, Validators.required),
      Start: new FormControl(this.viewModel.currentDate, Validators.required),
      End: new FormControl(null, Validators.required),
    })

  }

  ngOnInit(): void {

    this.setupDiscountValidators();

  }

  setupDiscountValidators() {

    const validators: any[] = DISCOUNT_DEFAULT_VALIDATORS;
    validators.push(Validators.min(this.data.minDiscountValue ?? 1));

    this.form.get('Discount')?.setValidators(validators);
    this.form.get('Discount')?.updateValueAndValidity();

  }

  cancel() {

    this.dialog.close();

  }

  create() {

    if (this.form.valid) {

      this.businessProduct
        .list()
        .then((data: CtWebapiGenericResponse<any>) => {

          const product: Product = <Product>data.Result[0];

          if (product) {

            const formValue: any = this.form.value;
            const parameter: BusinessDiscount = new BusinessDiscount(product.Id, formValue)


            this.businessDiscount
              .create(parameter)
              .then(() => this.dialog.close(true))

          }

        });

    }

  }

}
