import {ILocalization} from "./localization";
import {IFile} from "@ctsolution/ct-file-management";
import {DiscountTypeEnum} from "../enum/discount-type.enum";

import {PriceCategoryEnum} from "../enum/price-category.enum";
import {Coupon} from "./coupon";
import {ICategory, ISubCategory} from "../interfaces/category";

export class Product {

  Id: number = 0;
  Name?: string;
  PhoneNumber?: string;
  OpeningType?: string;
  OpeningRatesTime?: Array<RateTimeDTO>;
  IsOpen?: boolean;
  IsFavorite!: boolean;
  Coupons?: Array<Coupon>;
  PriceCategory: PriceCategoryEnum = PriceCategoryEnum.ReallyCheap;

  constructor(
    public Category: ICategory | null,
    public SubCategories: Array<ISubCategory> | null,
    public Place: IProductPlace | null,
    public Status: IProductStatus | null,
    public Discount: IProductDiscount | null,
    public Files: IFile[] = [],
    public Localizations: ILocalization[] | null
  ) {
  }

}


export interface RateTimeDTO {

  Start: string;
  End: string;
  Position?: number;
  OpeningDays: string;

}

export interface IProductStatus {

  Code: string;
  Description: string;

}

export interface IProductDiscount {

  Id?: number;
  AmountType: string; // 'percentual' / 'fixed'
  Amount: number;
  Description?: string;
  Type: DiscountTypeEnum; // 'all' / 'specific'
  Status?: string;

}

export interface IProductPlace {
  Name: string;
  PostalCode?: string;
  City?: string;
  Country?: string;
  Province: string;
  Region?: string;
  Id?: number;
  Latitude?: number;
  Longitude?: number;
}
