<div class="row justify-content-between chips-padding categories-list-cntr">

  <h3><b [innerHTML]="['FORM.CATEGORY_FILTER_TITLE' | translate]"></b></h3>

  <ng-container
    *ctSkeleton="viewModel.loading;
           repeat: 14;
           className: 'categories-button-skeleton';">

    <div class="text-center" [ngClass]="filtersPanel ? 'col-4 p-2' : 'col-6'" *ngFor="let button of categoryListButtons">

      <ct-button [parameters]="button"></ct-button>

    </div>

  </ng-container>

</div>
