import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Subscription} from "rxjs";
import {BackButtonService} from "../../layout/_components/back-button/back-button.service";
import {RoleHelperService} from "../../core/library/role-helper.service";
import {ScannerQrHelperService} from "../personal-area/business-area/_components/qr-scanner/scanner-qr-helper.service";
import {CtCarouselIvyParameter} from "../../components/ct-framework/ct-carousel-ivy/class/CtCarouselIvyParameter";
import {JwtService} from "../../core/library/jwt.service";
import {CompletionStatusEnum} from "../../core/enum/completion-status";
import {MatDialog} from '@angular/material/dialog';
import {
  CompletePersonalDataEntryComponent
} from './complete-personal-data-entry/complete-personal-data-entry.component';
import {UserHelperService} from "../../core/library/user-helper.service";

const CAROUSEL_CONTENT: string[] = [
  "/assets/images/carousel/vCard_videoHome.webp",
  "/assets/images/carousel/vCard_video.webp",
  "/assets/images/carousel/vCard_delicious.webp"];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export default class HomeComponent implements OnInit, OnDestroy {

  carouselParameter?: CtCarouselIvyParameter;
  subscription?: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private backButtonService: BackButtonService,
    private ctAuthenticationService: CtAuthenticationService,
    private roleHelper: RoleHelperService,
    private businessScannerService: ScannerQrHelperService,
    private userHelperService: UserHelperService
  ) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        if (this.ctAuthenticationService.userValueContainsClaimsRegistry()) {

          if (this.roleHelper.isEmployee()) this.businessScannerService.openScanner();

          this.updateCarouselContent();

          this.userHelperService
            .checkIfUserProfileIsEnabled();

        }

      })

  }

  ngOnInit(): void {

    this.backButtonService.resetLatestUrl();

  }

  ngAfterViewInit() {

    this.updateCarouselContent();
    this.cdr.detectChanges();

  }

  private updateCarouselContent() {

    this.carouselParameter = CtCarouselIvyParameter.Create(this.getRandomizedValues()).setHeight(300).setArrows(false).setDots(true).setAutoplay(true);

  }

  private getRandomizedValues = () => CAROUSEL_CONTENT
    .map(value => ({value, sort: Math.random()}))
    .sort((a, b) => a.sort - b.sort)
    .map(({value}) => value)

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
