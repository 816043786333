import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StepThreeComponent} from './step-three/step-three.component';
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import {VpointLabelModule} from "../../../../../../../components/vpoint-label/vpoint-label.module";
import {MatCardModule} from "@angular/material/card";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [
    StepThreeComponent
  ],
  imports: [
    CommonModule,
    CtFrameworkModule,
    VpointLabelModule,
    MatCardModule,
    MatProgressBarModule
  ],
  exports: [StepThreeComponent]
})
export class Step3Module {
}
