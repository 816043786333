import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScannerResultComponent} from './scanner-result/scanner-result.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    ScannerResultComponent
  ],
  exports: [
    ScannerResultComponent
  ],
  imports: [
    CtButtonModule,
    CommonModule,
    CtFrameworkModule,
    CtSkeletonModule
  ]
})
export class ScannerResultModule {
}
