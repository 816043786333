<div class="container mt-4 mb-5">

  <div class="m-auto col-4">

    <div class="logo-image-cntr">

      <!-- <ct-button [parameters]="logoBusinessButton"></ct-button> -->

      <app-file-upload
        [picture]="viewModel.backgroundUrl"
        [typeToAccept]="viewModel.fileTypeToAccept"
        (change)="manageFile($event)"
        (deletePicture)="delete()">
      </app-file-upload>

    </div>

    <h3 [innerHTML]="['FORM.SHOP_LOGO'|translate]"></h3>

  </div>

</div>
