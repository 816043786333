import { Component, Input, OnInit } from '@angular/core';
import { NotificationResult } from 'src/app/core/classes/notification';


@Component({
  selector: 'app-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss']
})
export class NotificationContentComponent implements OnInit {

  @Input() content!:NotificationResult 

  date: string = '';


  constructor() { }

  ngOnInit() {

    this.date = new Date().toLocaleString()

    if(this.content.NotificationDate){

      this.date = new Date(this.content.NotificationDate).toLocaleDateString()

    }
  
  }

}

