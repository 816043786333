import {Injectable} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {AppGeneralService} from "../../../core/library/app-general.service";
import {ICategory} from "../../../core/interfaces/category";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private general: AppGeneralService) {
  }

  getButton = (content: string, code: string, action: () => any) => CtButtonParameters
    .create(() => action())
    .setCSS(`categoryIconBox box-shadow mat-icon-button ${code}`, true)
    .setImage(`/assets/images/icons/category/${code}.svg`)
    .setImageCss('bigIcon')
    .setContent(content);

  goToSearch(categoryCode: ICategory | null = null) {

    const queryParams: any = {};

    if (categoryCode?.Code) queryParams.CategoryCodes = [categoryCode.Code];

    this.general.navigateTo('/research/list', {queryParams});

  }

  removeActiveClass = (button: CtButtonParameters) => button
    .setCSS(button.cssStyle.replace(/active/g, ''), true)
    .setImage((button.image ?? '').replace(/_white/g, '').replace(/white\//g, ''));

  activate = (button: CtButtonParameters, code: string) => button
    .setCSS(button.cssStyle + ' active', true)
    .setImage(`/assets/images/icons/category/white/${code}_white.svg`);

}
