import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileUploadComponent} from './file-upload.component';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {CtFrameworkModule} from '@ctsolution/ct-framework';
import {DeleteImageConfirmComponent} from './delete-image-confirm/delete-image-confirm.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  imports: [

    CommonModule,
    FormsModule,
    MatIconModule,
    CtFrameworkModule,
    MatDialogModule,
    MatButtonModule

  ],
  declarations: [FileUploadComponent, DeleteImageConfirmComponent],
  exports: [FileUploadComponent]
})
export class FileUploadModule {
}
