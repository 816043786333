import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtCardComponent } from './ct-card.component';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
   CtFrameworkModule
  ],
  declarations: [CtCardComponent],
  exports: [CtCardComponent]
})
export class CtCardModule { }
