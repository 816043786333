<ng-container *ngIf="product">

  <div class="card-detail-cntr mb-3" role="button" (click)="goInfoDetail()">

    <div class="row">

      <div class="col-12">

        <div class="mt-2 mb-2 position-relative">

          <ng-container *ngIf="product.Id">

            <img *ngIf="viewModel.coupon" src="/assets/images/icons/vPoints-box-rotate.svg" class="vpoints-icon">

            <app-manage-product-button
              *ngIf="roleHelperService.isCustomer()"
              [product]="product"></app-manage-product-button>

            <div *ngIf="viewModel.specialSale" class="special-sales"> {{ 'DISCOUNT.SPECIAL_DISCOUNT' | translate }}
            </div>

          </ng-container>

        </div>

        <ng-container *ngIf="this.viewModel.coverImg">
          <div [ngStyle]="{'background': 'url(' + this.viewModel.coverImg.PreviewUrl +') no-repeat center'}"
               class="cover-image"></div>
        </ng-container>

        <ng-container *ngIf="viewModel.maxDiscount > 0">

          <h2 class="mt-1 mb-0 d-flex justify-content-end primary-color">
            {{'GENERAL.DISCOUNT_UNTIL'|translate:{discount: viewModel.maxDiscount} }}</h2>

        </ng-container>

      </div>

    </div>

    <div class="row mt-2 p-2">

      <div class="col-3">

        <ng-container *ngIf="this.viewModel.logoImg">
          <div [ngStyle]="{'background': 'url(' + this.viewModel.logoImg.PreviewUrl +') no-repeat center'}"
               class="logo-image box-shadow"></div>
        </ng-container>

      </div>

      <div class="min-padding col-9">

        <ng-container *ngIf="product.Name">

          <div class="mb-1 d-flex justify-content-between">

            <h1 class="title-stile me-3">{{product.Name}}</h1>

            <app-price-category-button [price]="product"></app-price-category-button>

          </div>

        </ng-container>

        <div class="row">

          <div class="col-12">

            <div class="d-flex">

              <mat-icon class="color-green">schedule</mat-icon>

              <p class="ps-1 search-card-content"> {{ product.IsOpen ? ['GENERAL.OPEN' | translate] :
                ['GENERAL.CLOSE' | translate] }} </p>

            </div>


            <ng-container *ngIf="product.Place">

              <div class="d-flex">

                <mat-icon class="primary-color pe-4">place</mat-icon>
                <p class="search-card-content">{{product.Place.Name}}</p>

              </div>

            </ng-container>

          </div>

        </div>

      </div>

    </div>

  </div>

</ng-container>
