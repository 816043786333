import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackButtonService} from "../../../../layout/_components/back-button/back-button.service";
import {ProductController} from "../../../../core/controllers/product.controller";
import {Product} from "../../../../core/classes/product";
import {SearchService} from "./_components/search-filters/search-filters/search.service";
import {ActivatedRoute} from "@angular/router";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent implements OnInit, OnDestroy {

  list: Array<Product> = new Array<Product>();
  subscription?: Subscription;

  viewModel = {

    loading: false

  }

  constructor(
    private backButtonService: BackButtonService,
    private productController: ProductController,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private ctAuthenticationService: CtAuthenticationService) {

    this.subscription = this.ctAuthenticationService
      .user
      .subscribe(() => {

        const user = this.ctAuthenticationService.userValueContainsClaimsRegistry();

        if ( user || !user) {

          this.activatedRoute
            .queryParams
            .subscribe((qp: any) => {

              this.searchService.reset();

              Object
                .keys(qp)
                .forEach((key: string) => this.searchService.filters.setValue(key, qp[key]))

              this.search(true);

            });

        }

      });

  }

  ngOnInit(): void {

    this.backButtonService.resetLatestUrl();

  }

  search(bypassCache: boolean = false) {

    this.viewModel.loading = true;

    this.productController
      .list(this.searchService.filters, bypassCache)
      .then((productList: Product[]) => {

        this.list = productList;
        this.viewModel.loading = false;

      });

  }

  ngAfterViewInit() {

    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
