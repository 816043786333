<div class="card-recap-cntr container p-footer">

  <ng-container *ngIf="reportData.length > 0; else noDataTmpl">

    <div class="row">

      <div class="text-left" *ngFor="let report of reportData">

        <div class="col-12 mb-3">

          <mat-card class="report-card-border">

            <strong class="me-1">Data:</strong>

            <span><small>{{report.CreateAt | date: 'dd-MM-YYYY'}}</small></span>

            <br/>

            <strong class="me-1" [innerHTML]="'CHART.EARNINGS' | translate"></strong>
            <span><strong>{{report.Amount | currency:'EUR':'symbol':'1.0' }} </strong></span>

          </mat-card>

        </div>

      </div>

    </div>

  </ng-container>

  <ng-template #noDataTmpl>

    <div class="text-center mt-4">

      <strong [innerHTML]="'CHART.NODATA' | translate"></strong>

    </div>

  </ng-template>

</div>
