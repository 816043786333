import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CtButtonParameters, FormHelperService, passwordStrengthValidator} from '@ctsolution/ct-framework';
import {ChangePasswordParameter, CtProfileService} from '@ctsolution/ct-authentication';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {SnackbarService} from 'src/app/core/library/snackbar.service';
import {notEqualToValidator } from "@ctsolution/ct-framework";

@Component({
  selector: 'app-change-psw',
  templateUrl: './change-psw.component.html',
  styleUrls: ['./change-psw.component.scss']
})
export class ChangePswComponent implements OnInit {

  form!: FormGroup;

  btnMore: CtButtonParameters = CtButtonParameters
    .create(() => {
    })
    .setCSS("generalButton box-shadow", true)
    .setContent("CTBUTTON.SAVE")

  viewModel: any = {

    passwords: {

      type1: 'password',
      type2: 'password',
      type3: 'password'

    }

  };

  constructor(
    private appGeneralService: AppGeneralService,
    private ctProfileService: CtProfileService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    public formHelper: FormHelperService
  ) {
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      yourPsw: new FormControl('', Validators.required),
      newPsw: new FormControl('', [Validators.required, Validators.minLength(6), passwordStrengthValidator, notEqualToValidator("yourPsw")]),
      confirmPsw: new FormControl('', [Validators.required])
    });

  }

  get passwordNotEqual() {

    const formValue = this.form.value;

    return this.form.touched && !(formValue.newPsw === formValue.confirmPsw);

  }

  submit() {

    if (this.form.valid && !this.passwordNotEqual) {

      this.ctProfileService
        .changePassword(new ChangePasswordParameter(this.form.value.yourPsw, this.form.value.newPsw))
        .subscribe(() => {

          this.snackbarService.generalMessage('CHANGE_PASSWORD.PASSWORD_CHANGED');
          this.appGeneralService.back();

        });

    }

  }

}
