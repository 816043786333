export class CtFaqParameter {
    //Mandatory
    private _title!: string;
    public get title(): string {
        return this._title;
    }
    private set title(value: string) {
        this._title = value;
    }
    private _content!: string;
    public get content(): string {
        return this._content;
    }
    private set content(value: string) {
        this._content = value;
    }

    private _description?: string | undefined;
    public get description(): string | undefined {
        return this._description;
    }
    private set description(value: string | undefined) {
        this._description = value;
    }

    constructor(title:string, content:string){

        this.title = title;
        this.content = content;
       
    }

    public static Create(title: string, content: string):CtFaqParameter{
        return new CtFaqParameter(title, content);
    }

    public setDescription(value:string): CtFaqParameter{
        this.description = value;
        return this;
    }

}
