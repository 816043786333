<div class="sign-out-dialog-cntr">

  <h2 [innerHtml]="'LOGOUT.TITLE' | translate"></h2>

  <div class="row mt-4">

    <div class="col-6">

      <ct-button [parameters]="deleteButton"></ct-button>

    </div>

    <div class="col-6">

      <ct-button [parameters]="annulla"></ct-button>

    </div>
  
  </div>

</div>