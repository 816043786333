import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {

  latestUrl: string | null = null;

  constructor(
    private router: Router
  ) {

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.latestUrl = this.router.url;
      });



  }

  resetLatestUrl() {

    this.latestUrl = null;

  }

}
