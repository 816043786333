import {Component} from '@angular/core';
import {CtAuthenticationService, CtOAuthService} from '@ctsolution/ct-authentication';
import {TranslateService} from '@ngx-translate/core';
import {CtWebviewService} from "@ctsolution/ct-webview";
import {JwtService} from "./core/library/jwt.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  subscription?: Subscription;

  constructor(
    public ctAuthenticationService: CtAuthenticationService,
    private ctWebviewService: CtWebviewService,
    private jwt: JwtService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private _oauth: CtOAuthService
  ) {

    this.ctWebviewService.subscribeToInAppOpeningIntents();

    this.setLang();

    this.ctAuthenticationService
      .user
      .subscribe(() => {

        if (this.ctAuthenticationService.userValueContainsClaimsRegistry()) {

          this.setLang();

        }

        // this.sendFcmConfiguration();

      });

  }

  setLang() {

    const lang: string = this.jwt.getDefaultLang();

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(lang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);

  }

  /*sendFcmConfiguration() {
      setTimeout(() => {
        this.ctWebviewService
          .FCMSetup()
          .then((data: IFCM | null) => {
            if (data) { this.profileController.SetMobileInformation(data) }
          });
      }, 500); //TODO: da rimuovere questo timeout, bisogna capire su webview ios perche crea errore dopo la redirect di nexi
   }*/

  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
