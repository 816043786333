import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss']
})
export class TimeSelectComponent implements OnInit {

  @Input() form: any;

  constructor() { }

  ngOnInit() {}

}


  // vecchia gestione degli orari con le select

  // viewModel: any = {

  //   values: [],
  //   defaultOpeningTime: '09:00:00.000',
  //   defaultClosingTime: '19:00:00.000'

  // }

  // ratingTimeData: RatingTime = RatingTime.create(this.viewModel.defaultOpeningTime, this.viewModel.defaultClosingTime);

  // @Input() openingTimeOnNewControl: string | undefined;
  // @Output() selectedTime: EventEmitter<RatingTime> = new EventEmitter<RatingTime>();

  // constructor() {

    // this.initViewValues();

  // }

  // ngOnInit() {

  //   if (this.openingTimeOnNewControl) {

  //     this.viewModel.defaultOpeningTime = this.openingTimeOnNewControl;

  //   }

  //   this.selectedTime.emit(this.ratingTimeData);

  // }

  /**
   * For each hour in a day, create a new SelectValues object and add it to the viewModel.values array.
   */
  // initViewValues() {

  //   for (var h = 0; h < 24; h++) {

  //     this.viewModel.values.push(new TimeSelectValues(h));

  //   }

  // }

  /**
   * The function takes in a time and a code, and then sets the time to the ratingTimeData object, and
   * then emits the ratingTimeData object.
   * @param {string} time - string - the time selected by the user
   * @param {string} code - string - this is the type of time that is being set.
   */
//   setTime(time: string, code: string) {

//     switch (code) {

//       case TIME_TYPE.OPENING_TIME: this.ratingTimeData.setStartTime(time);
//         break;

//       case TIME_TYPE.CLOSING_TIME: this.ratingTimeData.setEndTime(time);
//         break;

//     }

//     this.selectedTime.emit(this.ratingTimeData);

//   }

// }
