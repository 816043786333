<div class="active-filters-list-cntr">

  <mat-chip-list>
    <mat-chip disableRipple  *ngFor="let param of params()" (removed)="remove(param)">
      {{getLabel(param) | translate}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-list>

</div>
