import {NgModule} from '@angular/core';
import {CustomerAreaModule} from "./customer-area/customer-area.module";
import {BusinessAreaModule} from "./business-area/business-area.module";
import {RouterModule} from "@angular/router";
import {ProfileModule} from "./profile/profile.module";
import {NotificationsModule} from "./_components/notifications/notifications.module";

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild([
      {
        path: '',
        children: [
          {
            path: 'business',
            loadChildren: () => BusinessAreaModule
          },
          {
            path: 'customer',
            loadChildren: () => CustomerAreaModule
          },
          {
            path: 'profile',
            loadChildren: () => ProfileModule
          },
          {
            path: 'notifications',
            loadChildren: () => NotificationsModule
          }
        ]
      }
    ])
  ]
})
export class PersonalAreaModule {}
