<div class="delete-image-cntr">
  <h1 mat-dialog-title> {{ 'COPY.WARNING_TITLE' | translate}}</h1>

  <div mat-dialog-content>
    <p class="text-center" [innerHtml]="'COPY.DELETE_IMAGE' | translate">
    </p>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button color="warn" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
      {{'BUTTON.CANCEL'|translate}}
    </button>

    <button mat-raised-button color="primary" [mat-dialog-close]="true">
      {{'BUTTON.CONTINUE' | translate}}
    </button>
  </div>
</div>
