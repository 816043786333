import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignInComponent} from './sign-in.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {RouterModule} from '@angular/router';
import {LoginModule} from './login/login.module';

@NgModule({
  imports: [
    CommonModule,
    LoginModule,
    CtButtonModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: '',
        component: SignInComponent
      }
    ])
  ],
  declarations: [SignInComponent],
  exports: [SignInComponent]
})
export class SignInModule {
}
