<div
  class="vpoint-card-cntr container mb-4"
  [class.not-purchasable]="!productCoupon?.Coupon?.checkIfPurchasableWithVPoints(+this.balance)"
  [class.specialDiscount]="productCoupon?.Coupon?.isSpecialCoupon()"
  [class.VPointDiscount]="productCoupon?.Coupon?.isVPointCoupon()"
  [class.myVictoriaCard]="productCoupon?.Coupon?.isMyVictoriaCard()">

  <div
    class="b-radius-1 pt-4 pb-4 ps-2 pe-2 box-shadow position-relative vpoint-card-border">

    <img *ngIf="productCoupon?.Coupon?.isVPointCoupon()" class="vPoint-box" [src]="getVPointIcon()">

    <div class="row">

      <div class="col-3 me-3">

        <div
          class="logo-image box-shadow"
          [style.background]="'url(' + productCoupon?.getLogo() +') no-repeat center'"></div>
      </div>

      <div class="col-8">

        <p class="coupon-shop-title">{{ productCoupon?.ProductName ?? '' }}</p>

        <ng-container *ngIf="(productCoupon?.Coupon?.Cost ?? 0) > 0">

          <p class="card-points primary-color">{{productCoupon?.Coupon?.Cost}} VPoint</p>

        </ng-container>

        <small><strong class="description-text">{{productCoupon?.Coupon?.Description ?? ''}}</strong></small>

      </div>

    </div>

    <div class="container">

      <hr class="mt-2 mb-4">

    </div>

    <div class="row">

      <div class="col-6">

        <div class="d-flex align-content-center mb-2">

          <mat-icon class="color-green me-2">schedule</mat-icon>

          <p> {{ productCoupon?.IsOpen ? ['GENERAL.OPEN' | translate] : ['GENERAL.CLOSE' | translate] }}</p>

        </div>

      </div>

      <div class="col-6">

        <div class="d-flex align-content-center mb-2">

          <mat-icon class="primary-color me-1">place</mat-icon>

          <p class="coupon-info">{{productCoupon?.Place?.Name}}</p>

        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-7 offset-5">

        <ct-button [parameters]="redeem"></ct-button>

      </div>

    </div>

  </div>

</div>
