<div class="savings-chart-cntr pt-5">

    <ng-container *ngIf="!savings;  else emptyTplt">
        <canvas baseChart [datasets]="polarChartData" [labels]="polarChartLabels" [options]="polarChartOptions" [legend]="false" [type]="'polarArea'"></canvas>

    </ng-container>

    <ng-template #emptyTplt>
        <p class="text-center" [innerHTML]="['GENERAL.NO_AVAILABLE_GRAPHS' | translate]"></p>
    </ng-template>

</div>