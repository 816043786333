<div class="step-three-cntr">

  <mat-progress-bar
    *ngIf="!transaction; else resultTemplate" mode="buffer"></mat-progress-bar>

  <ng-template #resultTemplate>

    <div class="mb-5 text-center">

      <img height="100px" [src]='resultImageSrc()' class='roll-in-blurred-right'/>

    </div>

    <h2 class="acqua-green-color text-center"
        [innerHTML]="(successfullyTransaction() ? 'QR.ACCEPTED' : 'QR.REFUSED') | translate"></h2>

    <ng-container *ngIf="successfullyTransaction()">

      <mat-card class="position-relative">

        <p><strong [innerHTML]="['QR.RESULT'|translate]"></strong></p>

        <h1 class="primary-color result-points text-center">

          <app-vpoint-label [amount]="transaction?.VirtualPoint ?? 0"></app-vpoint-label>

        </h1>

      </mat-card>

    </ng-container>

  </ng-template>

</div>
