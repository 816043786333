export class TransactionCardParameter {
    date?:string;
    location?:string;
    sale?:string;
    saving?:string;

    title!:string;
    logoPath?:string;

    virtualPoint?:number;
    
    constructor(title:string){
        this.title = title;
    }

    static Create(title:string): TransactionCardParameter{
        return new TransactionCardParameter(title);
    }

    setDate(value:string):TransactionCardParameter{
        this.date = value;
        return this;
    }

    setLocation(value:string):TransactionCardParameter{
        this.location = value;
        return this;
    }

    setSale(value:string):TransactionCardParameter{
        this.sale = value;
        return this;
    }

    setSaving(value:string):TransactionCardParameter{
        this.saving = value;
        return this;
    }

    setIconPath(value:string):TransactionCardParameter{
        this.logoPath = value;
        return this;
    }

    setVirtualPoint(value:number):TransactionCardParameter{
        this.virtualPoint = value;
        return this;
    }
}
