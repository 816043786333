import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiscountComponent} from "./discount.component";
import {SpecialDiscountListComponent} from "./special-discount-list/special-discount-list.component";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatTabsModule} from "@angular/material/tabs";
import {MatListModule} from "@angular/material/list";
import {SpecialDiscountCreateModule} from "./special-discount-create/special-discount-create.module";
import {MatDialogModule} from "@angular/material/dialog";
import {DiscountListItemComponent} from './special-discount-list/discount-list-item/discount-list-item.component';
import {MatCardModule} from "@angular/material/card";
import {MatDividerModule} from "@angular/material/divider";
import {MatChipsModule} from "@angular/material/chips";
import {RouterModule} from "@angular/router";
import {BusinessDiscountController} from "../../../../core/controllers/business-discount.controller";

@NgModule({
  declarations: [
    DiscountComponent,
    SpecialDiscountListComponent,
    DiscountListItemComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    MatTabsModule,
    MatListModule,
    SpecialDiscountCreateModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatChipsModule,
    CtFrameworkModule,
    RouterModule.forChild([
      {
        path: '',
        component: DiscountComponent
      }
    ])
  ],
  providers: [
    BusinessDiscountController
  ]
})
export class DiscountModule {
}
