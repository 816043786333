import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ChangePswComponent} from "./change-psw.component";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [ChangePswComponent],
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    RouterModule.forChild([
      {
        path: '',
        component: ChangePswComponent
      }
    ])
  ]
})
export class ChangePswModule {
}
