<div class="support-cntr mt-3 container">

  <h1 class="primary-color text-center" [innerHtml]="'CTBUTTON.SUPPORT' | translate"></h1>

  <h2 class="text-center mb-5" [innerHtml]="'GENERAL.SUPPORT_SUBTITLE' | translate"></h2>

  <ng-container *ngFor="let button of buttons">

    <div class="mb-4 container">

      <div class="mt-auto mb-auto box-shadow b-radius-1">

        <ct-button [parameters]="button"></ct-button>

      </div>

    </div>

  </ng-container>

</div>