import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

const LOCATION_CONTROLLER: string = 'Location';

@Injectable({
  providedIn: 'root'
})
export class LocationController {

  locationList: ILocation[] = [];

  constructor(private webapi: CtWebapiService) {
  }

  list() {

    return new Promise<ILocation[]>((resolve) => {

      if (this.locationList.length > 0) {

        resolve(this.locationList);
        return;

      }

      const request: DataRequest = new DataRequest(`/${LOCATION_CONTROLLER}/List`, {hideSpinner: true})

      this.webapi
        .get(request)
        .subscribe((response: CtWebapiGenericResponse<any>) => {

          this.locationList = <ILocation[]>(response.Result ?? []);
          resolve(this.locationList);

        });

    })

  }

}

export interface ILocation {

  Oid: number;
  City: string;
  Latitude: number;
  Longitude: number;

}
