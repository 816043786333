import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuNavigationButtonsComponent} from './menu-navigation-buttons.component';

import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatBadgeModule} from '@angular/material/badge';
import {SignOutDialogComponent} from './sign-out-dialog/sign-out-dialog.component';
import {SlideToggleModule} from "../../../components/ct-framework/slide-toggle/slide-toggle.module";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    MatBadgeModule,
    SlideToggleModule,
    CtFrameworkModule
  ],
  declarations: [MenuNavigationButtonsComponent, SignOutDialogComponent],
  exports: [MenuNavigationButtonsComponent, SignOutDialogComponent]
})
export class MenuNavigationButtonsModule {
}
