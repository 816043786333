import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SlideToggleComponent} from './slide-toggle.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {CtFrameworkModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CtFrameworkModule
  ],
  declarations: [SlideToggleComponent],
  exports: [SlideToggleComponent]
})
export class SlideToggleModule {
}
