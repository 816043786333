<div class="categories-cntr p-footer">

  <div class="container ps-5 pe-5">

    <h1 class="text-center pt-3" [innerHTML]="['GENERAL.ALL_CATEGORIES' | translate]"></h1>

    <app-categories-list (update)="searchByCategory($event)"></app-categories-list>

  </div>

</div>
