import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {DeleteImageConfirmComponent} from "./delete-image-confirm/delete-image-confirm.component";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() typeToAccept: string | undefined;
  @Input() picture: string | undefined;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletePicture: EventEmitter<any> = new EventEmitter<any>();

  viewModel: any = {

    isPdfFile: false,
    isWrongExtension: false,
    fileName: null
  }

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {

    this.viewModel.isPdfFile = this.typeToAccept?.includes('pdf');

  }

  onUpload(event: any) {

    event.stopPropagation();

    let file = <File>event.target.files[0];

    let extension = file.name.split('.').pop();

    if (!this.typeToAccept?.includes('pdf')) {

      if (extension == 'jpg' || extension == 'jpeg' || extension == 'png') {

        this.viewModel.isWrongExtension = false;

        this.change.emit(file);

      } else {

        this.viewModel.isWrongExtension = true;

      }

    } else {

      if (extension == 'pdf') {

        this.viewModel.fileName = file.name;
        this.viewModel.isWrongExtension = false;

        this.change.emit(file);

      } else {

        this.viewModel.isWrongExtension = true;

      }

    }

  }

  delete(evt: any) {

    evt.stopPropagation();

    this.dialog.open(DeleteImageConfirmComponent)
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) {

          this.deletePicture.emit();

        }

      })

  }

}
