import { Component, Input, OnInit } from '@angular/core';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { Product } from 'src/app/core/classes/product';
import { ProductController } from 'src/app/core/controllers/product.controller';

@Component({
  selector: 'app-manage-product-button',
  templateUrl: './manage-product-button.component.html',
  styleUrls: ['./manage-product-button.component.scss']
})
export class ManageProductButtonComponent implements OnInit {

  @Input() product!: Product;

  isFavorite!: boolean;

  favoriteButton: CtButtonParameters = CtButtonParameters
    .create((event) => { this.setFavorite(), event.stopPropagation() })
    .setMaterialIcon('favorite_border');

  constructor(
    private productController: ProductController
  ) {
    this.isFavorite = false;
   }

  ngOnInit() {
    this.isFavorite = this.product.IsFavorite;
  }

  setFavorite() {
    this.productController.updateFavorite(this.product.Id).then();
    this.isFavorite = !this.isFavorite
  }

}
