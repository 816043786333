<div class="login-cntr pb-3">

  <app-login-form></app-login-form>

  <div class="mt-4  col-11 m-auto">

    <ct-button [parameters]="LOGIN_BUTTON"></ct-button>

  </div>

  <div class="mt-3 mb-5 text-center">

    <mat-hint class="link-style" [routerLink]="['/authentication/recovery-password' ]" align="end" role="button">

      {{ 'FORM.PASSWORD_FORGOTTEN' | translate }}

    </mat-hint>

  </div>

  <div class="d-flex justify-content-center">

    <hr>

    <p class="p-2" [innerHTML]="['FORM.SIGN_IN_WITH' | translate]"></p>

    <hr>

  </div>

  <div class="container">

    <div class="mb-4">

      <ct-google-login-button [configuration]="GOOGLE_BUTTON"></ct-google-login-button>

    </div>

    <div class="mb-4">

      <ct-button [parameters]="APPLE_BUTTON"></ct-button>

    </div>

    <div class="mb-4">

      <ct-button [parameters]="FACEBOOK_BUTTON"></ct-button>

    </div>

  </div>

</div>
