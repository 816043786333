import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-radio-button',
  templateUrl: './time-radio-button.component.html',
  styleUrls: ['./time-radio-button.component.scss']
})
export class TimeRadioButtonComponent implements OnInit {

  constructor() { }

  model:any = {
    values: []
  }

  ngOnInit() {
    for(var h = 0; h < 24; h++)
    {
      this.model.values.push(`${h.toString().padStart(2,'0')}:00`)
      this.model.values.push(`${h.toString().padStart(2,'0')}:30`)
    }
  }

}

