<div class="no-vpoints-dialog-cntr">

  <div class="d-flex justify-content-between align-content-center mb-2">

    <h2 [innerHtml]="'NO_VPOINTS_TITLE' | translate"></h2>

    <mat-icon class="primary-color" (click)="close()">close</mat-icon>

  </div>
  
  <p [innerHtml]="'NO_VPOINTS_CONTENT' | translate"></p>

  <ct-button [parameters]="btnRedirectHome"></ct-button>

</div>
