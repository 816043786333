import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGeneralService } from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Input() path: any;


  constructor(
    private appGeneralService: AppGeneralService,
    private router: Router,
  ) { }

   ngOnInit(): void {
  }

  backBtn() {




    if (this.path) {

      if (this.path.includes('?')) {

        this.router.navigateByUrl(this.path);

      }

    } else {


      this.appGeneralService.back();

    }
  }

}
