import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {FormControl, Validators} from "@angular/forms";

export const PRICE_INPUT_PATTERN = '^\\d{0,8}(\\.\\d{1,2})?$';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {

  @Output() setupAmount: EventEmitter<number> = new EventEmitter<number>();

  pattern: string = PRICE_INPUT_PATTERN;
  control: FormControl = new FormControl(null, Validators.required);

  generate: CtButtonParameters = CtButtonParameters
    .create(() => this.generateQrCode())
    .setCSS("generalButton generate-qr box-shadow", true)
    .setContent("CTBUTTON.QR-GENERA");

  constructor() {
  }

  ngOnInit(): void {
  }

  generateQrCode() {

    if (this.control.valid) {

      const value = Number.parseFloat(this.control.value);
      this.setupAmount.emit(value);

    }

  }

}
