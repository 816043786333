<div *ngIf="subCategoriesList.length > 0" class="business-tags-cntr container" style="max-height: max-content;">

    <mat-form-field>

        <mat-label [innerHTML]="['SUBCATEGORY.TITLE' | translate]"></mat-label>

        <mat-select class="mt-2 mb-4" [formControl]="subCategoryControl" multiple>

            <mat-select-trigger>

                <mat-chip-list>

                    <mat-chip disableRipple *ngFor="let subCategory of subCategoryControl.value" [removable]="true" (removed)="onSubCatDeselected(subCategory)">
                        {{ 'SUBCATEGORY.' + subCategory['Code'] | translate }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>

                </mat-chip-list>

            </mat-select-trigger>

            <mat-option *ngFor="let subCategory of subCategoriesList" [value]="subCategory">{{ 'SUBCATEGORY.' + subCategory.Code | translate }}</mat-option>

        </mat-select>

    </mat-form-field>

</div>
