import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecoveryPasswordComponent} from './recovery-password.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from "@angular/router";
import {SetNewPasswordModule} from "./set-new-password/set-new-password.module";
import {SetNewPasswordComponent} from "./set-new-password/set-new-password.component";
import {FormInputModule} from "../../components/ct-framework/form-input/form-input.module";

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    FormInputModule,
    MatInputModule,
    CtButtonModule,
    CtFrameworkModule,
    SetNewPasswordModule,
    RouterModule.forChild([
      {path: '', component: RecoveryPasswordComponent},
      {path: 'set-new-password', component: SetNewPasswordComponent}
    ])
  ],
  declarations: [RecoveryPasswordComponent],
  exports: [RecoveryPasswordComponent]
})
export class RecoveryPasswordModule {
}
