import {AgmInfoWindow} from '@agm/core';
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {toJSDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import {Product} from 'src/app/core/classes/product';
import {AppGeneralService} from 'src/app/core/library/app-general.service';

@Component({
  selector: 'app-maps-content',
  templateUrl: './maps-content.component.html',
  styleUrls: ['./maps-content.component.scss']
})
export class MapsContentComponent implements OnInit, AfterViewInit {

  @ViewChild(AgmInfoWindow, {static: true})
  infoWindow!: AgmInfoWindow;


  style: google.maps.MapTypeStyle[] = [
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ] as google.maps.MapTypeStyle[];

  viewModel!: ViewModelMapsContent;


  constructor(
    private appGeneralService: AppGeneralService,
    @Inject(MAT_DIALOG_DATA) public data: MapsData,
    public dialogRef: MatDialogRef<MapsContentComponent>
  ) {
    this.viewModel = new ViewModelMapsContent();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      this.changeInfo(this.data.product);

    }, 700);
  }

  ngOnInit() {
  }

  changeCoordinates() {
    if (this.data.product) {
      this.viewModel.coordinates.latitude = this.data.product.Place?.Latitude ?? 0;
      this.viewModel.coordinates.longitude = this.data.product.Place?.Longitude ?? 0;
    }
  }

  getMarkerData(product: Product): any {

    let markerUrl = '/assets/images/icons/marker-category/' + product.Category?.Code + '-marker.svg';

    return markerUrl;

  }

  changeInfo(product: Product) {

    this.data.product = product;
    this.changeCoordinates();

  }

  goInfoDetail() {

    this.appGeneralService.navigateTo('/research/list/detail');
    this.dialogRef.close();

  }

  close_window() {

    if (this.viewModel.previousInfoWindow != null) {

      this.viewModel.previousInfoWindow.close();
      this.viewModel.previousInfoWindow = null;

    }

  }

  openInfoWindow(infoWindow: any) {

    if (this.viewModel.previousInfoWindow == null)

      this.viewModel.previousInfoWindow = infoWindow;

    else {

      this.viewModel.infoWindowOpened = infoWindow
      this.close_window();

    }

    this.viewModel.previousInfoWindow = infoWindow

  }

}


export class MapsData {
  product!: Product;
  products!: Array<Product>;

  constructor(productData: Product, productsData: Array<Product>) {
    this.product = productData;
    this.products = productsData
  }
}

export class ViewModelMapsContent {
  coordinates!: Coordinates;
  infoWindowOpened?: any;
  previousInfoWindow?: any;

  constructor() {
    this.coordinates = new Coordinates();
  }

}

export class Coordinates {
  latitude!: number;
  longitude!: number;

  constructor() {
    this.latitude = 8.978242303176401;
    this.longitude = 44.35622174177096;
  }
}
