import {Component, OnInit, ViewChild} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {ORDER_FILTER, OrderFilterComponent} from "../_components/order-filter/order-filter.component";
import {PriceCategoryEnum} from "../../../../../../../core/enum/price-category.enum";
import {FilterProduct, SearchService} from "./search.service";
import {ProductController} from "../../../../../../../core/controllers/product.controller";
import {Product} from "../../../../../../../core/classes/product";
import {AbstractControl, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DistanceFilterComponent} from "../_components/distance-filter/distance-filter.component";
import {PriceFilterComponent} from "../_components/price-filter/price-filter.component";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {ICategory} from "../../../../../../../core/interfaces/category";
import {
  CategoriesListComponent
} from "../../../../../categories/categories-list/categories-list/categories-list.component";

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  providers: [ProductController]
})
export class SearchFiltersComponent implements OnInit {

  @ViewChild("order") order!: OrderFilterComponent;
  @ViewChild("distance") distance!: DistanceFilterComponent;
  @ViewChild("price") price!: PriceFilterComponent;
  @ViewChild("category") category!: CategoriesListComponent;

  form: FormGroup;

  viewModel: any = {

    results: [],
    loading: true,
    filters: null

  }

  closeFiltersBtn: CtButtonParameters = CtButtonParameters
    .create(() => this._bottomSheetRef.dismiss())
    .setMaterialIcon('close');

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<SearchFiltersComponent>,
    private searchService: SearchService,
    private productController: ProductController,
    private fb: FormBuilder) {

    this.form = this.fb.group({
      Price: new FormControl(null),
      Category: new FormControl(null)
    });

    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => this.search())

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {

    this.form.addControl("Order", this.order.control);
    this.order.control.setParent(this.form);

    this.form.addControl("Distance", this.distance.control);
    this.distance.control.setParent(this.form);

    this.setupInitialValues();

  }

  setupInitialValues() {

    const orderKeyValues: string[] = Object.values(ORDER_FILTER);

    for (const key of orderKeyValues) {

      if (this.searchService.filters.hasValue(key)) {

        this.form.get('Order')?.setValue(key);
        break;

      }

    }

    if (this.searchService.filters.DistanceMeter) {

      this.form.get('Distance')?.setValue(+this.searchService.filters.DistanceMeter);

    }

    if (!!this.searchService.filters.PriceCategory) {

      this.price.select(+this.searchService.filters.PriceCategory);

    }

    if ((this.searchService.filters.CategoryCodes ?? []).length > 0) {

      const value: string | undefined = this.searchService.filters.CategoryCodes![0];

      if (value) {

        this.form.get('Category')?.setValue(value);
        setTimeout(() => this.category.setValue(value), 500);


      }

    }

  }

  setPrice = (evt: PriceCategoryEnum | null) => this.form.get('Price')?.setValue(evt);

  removePriceFilter = () => this.price.select(null);

  setCategory = (evt: ICategory | null) => this.form.get('Category')?.setValue(evt?.Code ?? null);

  removeCategoryFilter = () => this.category.select(null);

  search() {

    this.viewModel.filters = new FilterProduct();

    if (this.form.value.Order) {

      this.viewModel.filters.setValue(this.form.value.Order, true);

    }

    if (this.form.value.Distance) {

      this.viewModel.filters.setValue('DistanceMeter', this.form.value.Distance);

    }

    if (this.form.value.Price >= 0) {

      this.viewModel.filters.setValue('PriceCategory', this.form.value.Price);

    }

    if (this.form.value.Category) {

      this.viewModel.filters.setValue('CategoryCodes', this.form.value.Category);

    }

    this.viewModel.loading = true;

    this.productController
      .list(this.viewModel.filters)
      .then((list: Product[]) => {

        this.viewModel.results = list;
        this.viewModel.loading = false;

      })

  }

  closeWithResults = () => {

    this._bottomSheetRef
      .afterDismissed()
      .subscribe(() => {

        this.searchService.filters = this.viewModel.filters;
        this.searchService.search();

      });

    this._bottomSheetRef.dismiss();

  }

  removeGenericFilter(control: AbstractControl | null) {

    if (!control) return;

    control.setValue(null);
    control.updateValueAndValidity();

  }

}
