import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { CtExpansionPanelParameter } from './class/CtExpansionPanelParameter';

@Component({
  selector: 'ct-expansion-panel',
  templateUrl: './ct-expansion-panel.component.html',
  styleUrls: ['./ct-expansion-panel.component.scss']
})
export class CtExpansionPanelComponent implements OnInit {
  @Input() parameter!:CtExpansionPanelParameter;

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  icon: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
