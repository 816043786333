import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {BusinessProductController} from 'src/app/core/controllers/business-product.controller';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {FileService} from 'src/app/core/library/file.service';
import {FileUploadComponent} from './sections/file-upload/file-upload.component';
import {GeneralDataComponent} from './sections/general-data/general-data.component';
import {OpeningDatasComponent} from './sections/opening-datas/opening-datas.component';
import {SnackbarService} from 'src/app/core/library/snackbar.service';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {SubcategoriesListComponent} from './sections/subcategories-list/subcategories-list.component';
import {PriceCategoryEnum} from "../../../../core/enum/price-category.enum";
import {FileType} from "../../../../core/enum/file-type.enum";
import {ISubCategory} from "../../../../core/interfaces/category";
import {
  PriceFilterComponent
} from 'src/app/pages/research/search-list/search-list/_components/search-filters/_components/price-filter/price-filter.component';
import {CategoryController} from "../../../../core/controllers/category.controller";
import {SubCategoryController} from "../../../../core/controllers/subcategory.controller";

@Component({
  selector: 'app-business-create',
  templateUrl: './business-create.component.html',
  styleUrls: ['./business-create.component.scss']
})
export class BusinessCreateComponent implements OnInit {

  // #region viewChild
  @ViewChild(GeneralDataComponent, {static: false})
  generalDataComponent!: GeneralDataComponent;

  @ViewChild(OpeningDatasComponent, {static: false})
  openingDatasComponent!: OpeningDatasComponent;

  @ViewChild(FileUploadComponent, {static: false})
  fileUploadComponent!: FileUploadComponent;

  @ViewChild(SubcategoriesListComponent, {static: false})
  subcategoriesListComponent!: SubcategoriesListComponent;

  @ViewChild('PriceFilter') PriceFilterComponent?: PriceFilterComponent;

  // #endregion

  form: FormGroup;

  subCategoriesList: Array<ISubCategory> = [];

  viewModel: any = {

    btnSubmit: CtButtonParameters
      .create(() => this.onSubmit())
      .setCSS("generalButton box-shadow", true)
      .setContent("CTBUTTON.SAVE"),

    fileTypeToAccept: ".pdf",
    productData: null

  }

  constructor(
    public fileService: FileService,
    private businessProductController: BusinessProductController,
    private formBuilder: FormBuilder,
    public appGeneralService: AppGeneralService,
    private snackBarService: SnackbarService,
    private subCategoryController: SubCategoryController
  ) {

    this.form = this.formBuilder.group({});
  }

  ngOnInit() {

    this.getBusinessProduct();

  }

  ngAfterViewInit() {

    this.setParentForm();

  }

  setParentForm() {

    this.form.addControl("generalDataComponent", this.generalDataComponent!.form);
    this.generalDataComponent.form.setParent(this.form);

    this.form.addControl("openingDatasComponent", this.openingDatasComponent!.openingRateTimeComponent.form);
    this.openingDatasComponent.openingRateTimeComponent.form.setParent(this.form);

  }

  getBusinessProduct() {

    this.businessProductController
      .list()
      .then((data: any) => {

        if (data.Result && data.Result.length > 0) {

          this.viewModel.productData = data.Result[0];

          this.PriceFilterComponent?.select(this.viewModel.productData.PriceCategory);

          this.subCategoryController
            .list(this.viewModel.productData.Category.Id)
            .then((result: ISubCategory[]) => {

              this.subCategoriesList = result;

              setTimeout(() => this.subcategoriesListComponent.setupSubCategory(this.viewModel.productData.SubCategories))

            });

        }

      });

  }

  uploadBrochure(event: any) {

    if (this.viewModel.productData?.Id) {

      this.fileService
        .uploadFile(event, FileType.BROCHURE, this.viewModel.productData)
        .subscribe(() => this.getBusinessProduct())

    }

  }

  onSubmit() {

    if (this.viewModel.productData.Id && this.form.valid) {

      let productObj: any = {

        Id: this.viewModel.productData.Id,
        Place: this.viewModel.productData.Place,
        Category: this.viewModel.productData.Category,

        Name: this.generalDataComponent.form.value.name,
        PhoneNumber: this.generalDataComponent.form.value.phone,
        SubCategories: this.subcategoriesListComponent?.subCategoryControl?.value ?? [],
        OpeningType: this.openingDatasComponent.openingRateTimeComponent.isAllDay ? 'partial' : 'all',
        OpeningRatesTime: this.openingDatasComponent.openingRateTimeComponent.form.value.ratesTimeFormArray,
        Discount: {},
        Localizations: this.generalDataComponent.descriptions.value.filter((element: any) => !!element.Value),
        Files: [],
        PriceCategory: this.PriceFilterComponent?.getSelectedValue() ?? PriceCategoryEnum.ReallyCheap

      }

      // aggiungo i giorni di apertura ad ogni orario selezionato
      this.addOpeningDaysToRates(this.openingDatasComponent.openingRateTimeComponent.form.value, this.openingDatasComponent.openingDaysComponent.selectedDays);

      // aggiungo :00 alle stringhe degli orari come richiesto dal backend
      this.addZerosToTimes(this.openingDatasComponent.openingRateTimeComponent.form.value);

      this.businessProductController
        .put(productObj)
        .then(() => this.productCreated());

    } else {

      this.form.markAllAsTouched();
      this.snackBarService.generalMessage('PRODUCT.errorField');

    }

  }

  productCreated() {

    this.snackBarService.generalMessage('SNACKBAR.PRODUCT_CREATED');
    this.appGeneralService.navigateTo('/research/list');

  }

  addOpeningDaysToRates(rates: any, days: any) {

    rates.ratesTimeFormArray.forEach((element: any) => element.OpeningDays = days.toString())

  }

  addZerosToTimes(rates: any) {

    rates.ratesTimeFormArray.forEach((element: any) => {

      if (element.Start.length == 5) {
        element.Start += ':00';
        element.End += ':00';
      }
    })

  }

}
