<div [class]="configuration.class" class="expandable-text-cntr container mt-2 mb-2 container">

  <h1 *ngIf="configuration.title">{{ configuration.title | translate }}</h1>

  <p class="product-description" [class.line-clamp]="!viewModel.expanded"> {{ viewModel.content }} </p>

  <div style="font-size:15px" class="mb-3 primary-color vCard-link" role="button" (click)="showMore()" *ngIf="viewModel.expandable"
         [innerHTML]="[(!viewModel.expanded ? 'GENERAL.READ_MORE': 'GENERAL.READ_LESS')| translate]"></div>

</div>
