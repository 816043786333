<div class="business-report-cntr container">

  <h1 class="text-center mt-4 mb-0" [innerHTML]="['BUSINESS.REPORT_TITLE'|translate]"></h1>

  <div class="d-flex justify-content-center">

    <h2>+ {{ viewModel.totalEarnings | currency:'EUR':'symbol':'1.0' }}</h2>

  </div>

  <div class="mb-5 report-card">

    <app-chart [chartType]="chartType" width="500" [chartData]="chartData" [chartOptions]="chartOptions">
    </app-chart>

  </div>

  <div class="mt-4 mb-4 container">

    <mat-card>

      <div class="row">

        <div class="col-6 text-center">

          <img class="earnings-icon" src="/assets/images/personal-area/earnings.svg">
          <h3 class="mb-0" [innerHTML]="'CHART.YOUR-EARNINGS' | translate"></h3>
          <div class="primary-color">{{ viewModel.totalEarnings | currency:'EUR':'symbol':'1.0' }}</div>

        </div>
        <div class="col-6 text-center">
          <img class="clients-icon" src="/assets/images/personal-area/clients-reached.svg">
          <h3 class="mb-0" [innerHTML]="'CHART.CLIENTS-REACHED' | translate"></h3>
          <div class="primary-color"> {{ viewModel.totalCustomers }}</div>

        </div>

      </div>

    </mat-card>

  </div>

  <hr class="w-50  ms-auto me-auto">

  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="tabChanged($event)">

    <mat-tab *ngFor="let tab of matTabLabels" label="{{'REPORT-BUSINESS.' + tab.labelKey | translate}}">

      <div class="mt-2 container" *ngIf="tab.enableSelect && viewModel.monthsFilter.length > 0">

        <mat-form-field appearance="outline">

          <mat-select  [value]="viewModel.monthFilterSelected" (valueChange)="changeMonthFilterSelect($event)">

            <mat-option *ngFor="let data of viewModel.monthsFilter" [value]="createSelectValue(data)">

              {{('MONTH.' + data.month | translate) + ' ' + data.year}}

            </mat-option>

          </mat-select>

        </mat-form-field>

      </div>

      <div class="mt-2">

        <app-card-recap [reportData]="viewModel.statisticTransactions"></app-card-recap>

      </div>

    </mat-tab>

  </mat-tab-group>

</div>
