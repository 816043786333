<div class="step-one-cntr">

  <div class="victoria-card-image-container text-center ">

    <img class="heartbeat" src="/assets/images/personal-area/qr-code-card.png">

  </div>

  <div class="mt-4 mb-2">

    <div class="d-flex justify-content-center">

      <div class="ticket-container">

        <img class="ticket-qr" src="/assets/images/icons/ticket-coupon.svg">

      </div>

      <p class="text-center"><strong [innerHTML]="['SUBSCRIBE.GENERATE_QRCODE'|translate]"></strong></p>

    </div>

    <div class="coupon-form-cntr mb-5">

      <div class="d-flex qr-discount-input position-relative input-qr-generator">

        <app-form-input
          [textInputViewModel]="{
                control,
                type:'number',
                label: 'QR.IMPORT' | translate,
                pattern: pattern}"></app-form-input>

        <ct-button matStepperNext [parameters]="generate"></ct-button>

      </div>

      <div class="text-center">

        <small> {{ 'QR.INPUT_SUBTITLE' | translate}}</small>

      </div>

    </div>

  </div>

</div>
