import {Injectable} from '@angular/core';
import {CtAuthenticationGuard, CtAuthenticationService, ROLE} from "@ctsolution/ct-authentication";
import {AppGeneralService} from "./app-general.service";
import {SnackbarService} from "./snackbar.service";
import {BackButtonService} from "../../layout/_components/back-button/back-button.service";

const CUSTOMER_IMPERSONATION: string = 'VC_CUSTOMER_IMPERSONATION';

@Injectable({
  providedIn: 'root'
})
export class RoleHelperService {

  constructor(
    private ctAuthenticationGuard: CtAuthenticationGuard,
    private ctAuthenticationService: CtAuthenticationService,
    private backButtonService: BackButtonService,
    private appGeneralService: AppGeneralService,
    private snackbarService: SnackbarService) {
  }

  isEmployee = () => this.ctAuthenticationGuard.userHasRole(['EMPLOYEE']);

  isBusiness = (): boolean => this.ctAuthenticationGuard.isBusiness();

  /**
   * If the user is impersonating a customer, then return true
   * @returns A boolean value.
   */
  isCustomer(): boolean {

    const customerImpersonationValue: string | null = sessionStorage.getItem(CUSTOMER_IMPERSONATION);

    if (customerImpersonationValue) {

      const value: string | string[] = JSON.parse(customerImpersonationValue);

      if (Array.isArray(value)) {

        return value.includes(ROLE.CUSTOMER);

      }

    }

    return this.ctAuthenticationGuard.isCustomer();

  }

  /**
   * If the user is an admin, then add the customer role to the user's roles
   * @param {string | string[] | null} registryRoles - string | string[] | null
   * @returns The registryRoles array is being returned.
   */
  private setCustomerImpersonation(registryRoles: string | string[] | null): string[] | undefined {

    if (!registryRoles) return;

    if (!Array.isArray(registryRoles)) registryRoles = [registryRoles];
    registryRoles.push(ROLE.CUSTOMER);

    sessionStorage.setItem(CUSTOMER_IMPERSONATION, JSON.stringify(registryRoles));

    return registryRoles;

  }

  /**
   * If the user is a customer, then remove the customer impersonation from the session storage and set the user role to
   * the original role. If the user is not a customer, then set the user role to the customer impersonation
   */
  toggleUserMode() {

    const currentUser: any = this.ctAuthenticationService.userValue;

    const roleKey: string | undefined = Object.keys(currentUser).find((element: string) => element.includes('role'));

    if (roleKey) {

      if (this.isCustomer()) {

        sessionStorage.removeItem(CUSTOMER_IMPERSONATION);
        currentUser[roleKey] = this.ctAuthenticationService.getJwtValueByContainedKey(roleKey);

      } else {

        currentUser[roleKey] = this.setCustomerImpersonation(this.ctAuthenticationService.getJwtValueByContainedKey(roleKey));

      }

      this.ctAuthenticationService.setUserValue(currentUser);

      this.redirectByUserType();

    }

  }

  private redirectByUserType() {

    this.appGeneralService
      .redirectToHome()
      .then(() => {

        if (this.isCustomer()) {

          this.snackbarService.generalMessage("SNACKBAR.USER_TYPE_CLIENT");

        } else {

          this.snackbarService.generalMessage("SNACKBAR.USER_TYPE_BUSINESS");

        }

        this.backButtonService.resetLatestUrl()

      });

  }

}
