<div class="shop-time-table-cntr">

  <div class="row" *ngFor="let openingDay of viewModel.openingDays">

    <div class="col-4">

      <p><b> {{ 'WEEKDAYS.' + openingDay | translate }} </b></p>

    </div>
    <div class="col-8">

      <p *ngIf="viewModel.openingTime" [innerHtml]="viewModel.openingTime"></p>

    </div>

  </div>

</div>

<div>

  <ct-button [parameters]="closeDialog"></ct-button>

</div>
