<div class="container">

  <div class="special-discount-cntr p-footer">

    <div class="mb-4">

      <h1 class="text-center mt-4" [innerHTML]="['DISCOUNTS.TITLE'|translate]"></h1>

      <p>{{ 'BUSINESS.DISCOUNT_CREATE_DESCRIPTION' | translate}}</p>

    </div>

    <app-special-discount-list #list></app-special-discount-list>

  </div>

</div>
