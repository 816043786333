import {Component, OnInit, ViewChild} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {CtButtonTypeValueObject} from '@ctsolution/ct-framework';
import {LoginFormComponent} from './login-form/login-form.component';
import {
  CtAuthenticationService,
  CtOAuthService, GoogleLoginButtonConfiguration,
  SignInParameter
} from '@ctsolution/ct-authentication';
import {AppGeneralService} from 'src/app/core/library/app-general.service';
import {RegistrationGuest} from "../../../core/classes/registration-guest";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild(LoginFormComponent, {static: false}) loginForm!: LoginFormComponent;

  GOOGLE_BUTTON: GoogleLoginButtonConfiguration<RegistrationGuest> = GoogleLoginButtonConfiguration.create<RegistrationGuest>()
    .setUserCreateClass(RegistrationGuest.createWithGoogleProvider)
    .setSuccessCallback(() => this.appGeneralService.redirectToHome());

  APPLE_BUTTON: CtButtonParameters = CtButtonParameters
    .create(() => {

      this.APPLE_BUTTON
        .setDisabled(true);

      this._oauth
        .appleAuthentication<RegistrationGuest>(RegistrationGuest.createWithAppleProvider)
        .then(() => this.appGeneralService.redirectToHome())

      setTimeout(() => this.APPLE_BUTTON.setDisabled(false), 1000); // per evitare click simultanei

    })
    // .setMaterialTypeConfiguration(CtButtonTypeValueObject.matIconButton) serve davvero?
    .setCSS("social-login-button APPLE", true)
    .setMaterialIcon('apple')
    .setContent("CTBUTTON.APPLE");

  FACEBOOK_BUTTON: CtButtonParameters = CtButtonParameters
    .create(() => {

      this._oauth
        .facebookAuthentication<RegistrationGuest>(RegistrationGuest.createWithFacebookProvider)
        .then(() => this.appGeneralService.redirectToHome())

    })
    .setCSS("social-login-button FACEBOOK", true)
    .setMaterialIcon('facebook')
    .setContent("CTBUTTON.FACEBOOK");

  LOGIN_BUTTON: CtButtonParameters = CtButtonParameters
    .create(() => this.onSubmit())
    .setMaterialTypeConfiguration(CtButtonTypeValueObject.matIconButton)
    .setCSS("generalButton box-shadow")
    .setContent("LOGIN.SIGN_IN");

  constructor(
    private appGeneralService: AppGeneralService,
    private ctAuthenticationService: CtAuthenticationService,
    private _oauth: CtOAuthService
  ) {

    if (this.ctAuthenticationService.userValue) {

      this.appGeneralService.redirectToHome();

    }

  }

  ngOnInit() {
  }

  onSubmit(): void {

    if (this.loginForm.form.valid) {

      this.ctAuthenticationService
        .signIn(<SignInParameter>this.loginForm.form.value)
        .subscribe(() => this.appGeneralService.redirectToHome());

    }

  }

}
