import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CtCardParameters } from './class/CtCardParameters';

@Component({
  selector: 'ct-card',
  templateUrl: './ct-card.component.html',
  styleUrls: ['./ct-card.component.scss']
})
export class CtCardComponent implements OnInit {

  @Input() parameter!:CtCardParameters;

  constructor() { }

  ngOnInit() {
  }

}
