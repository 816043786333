<div class="navbar-cntr">

  <mat-toolbar>

    <mat-toolbar-row>

      <div class="container d-flex">

        <div class="col-2">

          <ng-container *ngIf="backButtonService.latestUrl; else localizationTemplate">

            <app-back-button></app-back-button>

          </ng-container>

          <ng-template #localizationTemplate>

            <ng-container *ngIf="ctAuthenticationService.userValue">

              <app-localization-switch-select></app-localization-switch-select>

            </ng-container>

          </ng-template>

        </div>

        <div class="col-8 text-center">
          <ct-button [parameters]="logoButton"></ct-button>
        </div>

        <div class="col-2 text-center">

          <ct-button [parameters]="toggleButton"></ct-button>

        </div>

      </div>

    </mat-toolbar-row>

  </mat-toolbar>

</div>
