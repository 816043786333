import {Injectable} from "@angular/core";
import {Product} from "../classes/product";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {FileData} from "../library/file.service";

@Injectable()
export class BusinessProductController {

  controller: string = '/BusinessProduct/';

  params: any = {}

  constructor(
    private ctWebapiService: CtWebapiService
  ) {
  }

  get(id: number): Promise<any> {
    return new Promise<any>((resolve) => this.ctWebapiService
      .get(new DataRequest(`${this.controller}Get`, {productId: id})).subscribe(d => resolve(d as any)));
  }

  list(): Promise<any> {
    return new Promise<any>((resolve) => this.ctWebapiService
      .post(new DataRequest(`${this.controller}List`), this.params).subscribe(d => {
        resolve(d as any)
      }));
  }

  put(product: Product): Promise<any> {

    return new Promise<any>((resolve) => this.ctWebapiService
      .put(new DataRequest(`${this.controller}Put`), product).subscribe(d => {
        resolve(d as any)
      }));
  }

  deleteFile(Id: number) {

    const request: DataRequest = new DataRequest(`${this.controller}DeleteFile`, {Id});


    return this.ctWebapiService
      .delete(request);

  }

  setFile(formData: FormData) {

    if (formData.get('IdUpdateFile')) {

      return this.updateFile(formData);

    } else {

      return this.ctWebapiService
        .post(new DataRequest(`${this.controller}SetFile`), formData);

    }

  }

  private updateFile(formData: FormData) {
    return this.ctWebapiService
      .put(new DataRequest(`${this.controller}UpdateFile`), formData);
  }

}
