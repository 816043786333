<ng-container
  *ctSkeleton="!transaction;
           repeat: 6;
           className: 'discounts-manager-skeleton';">

  <div class="qr-available-discounts-cntr container">

    <ng-container *ngIf="transaction!.Amount > 0; else giftTemplate">

      <div class="mb-5">

        <div class="text-center">

          <h1 class="primary-color">

            {{ 'GENERAL.TOTAL_AMOUNT' | translate }}: {{transaction!.Amount | currency:'EUR':'symbol' }}<br>

          </h1>

          <p> {{ 'DISCOUNT.APPLY_DISCOUNTS' | translate }}</p>

        </div>

        <p class="text-right link"> {{"GENERAL.HOW_IT_WORKS" | translate}}?</p>

      </div>

      <ng-container *ngFor="let discount of transaction!.Coupons ?? []">

        <div class="mb-4">

          <label><strong>{{discount.getCouponDiscountName()}}</strong></label>

          <mat-slider
            class="w-100"
            *ngIf="discount.Quote"
            thumbLabel
            [class.special-discount]="discount.isSpecialCoupon()"
            [displayWith]="formatLabel"
            [value]="discount.Quote.Quote"
            [min]="sliderViewModel.min"
            [max]="sliderViewModel.getCouponSliderMaxValue(discount)"
            [step]="sliderViewModel.step"
            (input)="discount.Quote.Quote = $event.value ?? 0">
          </mat-slider>

          <div class="d-flex justify-content-between">
            <label>Min: {{sliderViewModel.min | currency:'EUR':'symbol' }}</label>
            <label>Max: {{sliderViewModel.getCouponSliderMaxValue(discount) | currency:'EUR':'symbol' }}</label>
          </div>

        </div>

      </ng-container>

    </ng-container>

    <ng-template #giftTemplate>

      <div class="mb-5">

        <div class="text-center">

          <h1 class="primary-color">

            {{ 'DISCOUNTS.GIFT_COUPON_TITLE' | translate }}<br>

          </h1>

          <p> {{ 'DISCOUNTS.GIFT_COUPON_DESCRIPTION' | translate }}</p>

        </div>

      </div>

    </ng-template>

    <div class="text-center">

      <p *ngIf="transaction!.Amount > 0" [innerHtml]="'SCAN.IMPORT_DESCRIPTION' | translate"></p>

      <ct-button [parameters]="accept"></ct-button>

      <p> {{ 'GENERAL.OR' | translate }}</p>

      <p class="link" (click)="onCancel()"> {{ 'BUTTON.CANCEL' | translate }}</p>

    </div>

  </div>

</ng-container>
