<div class="opening-days-cntr  mat-chip-cntr">

    <div class="row">

        <mat-chip-list #chipList>

            <ng-container *ngFor="let d of days">

                <div class="col-6">

                    <mat-chip disableRipple  [selectable]="true" class="b-radius box-shadow" [class.selected]="d.selected" (click)="selectDay(d)">
                        <span [innerHTML]="['WEEKDAYS.' + WEEKDAYS[d.id] | translate]"></span>
                    </mat-chip>

                </div>

        </ng-container>
    
        </mat-chip-list>

    </div>

</div>