import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackButtonComponent} from './back-button.component';
import {MatIconModule} from '@angular/material/icon';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    CtFrameworkModule
  ],
  declarations: [BackButtonComponent],
  exports: [BackButtonComponent]
})
export class BackButtonModule {
}
