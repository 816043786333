import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegistrationWelcomeComponent} from './registration-welcome.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule
  ],
  declarations: [RegistrationWelcomeComponent]
})
export class RegistrationWelcomeModule {
}
