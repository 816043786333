    <div class="sign-in-cntr h-100 container mt-4">

          <div class="img-cntr">

            <img src="assets/images/background-svg/sfondo.svg">

          </div>

         <p class="txt-cntr title-login mt-4" [innerHTML]="['LOGIN.DESCRIPTION' | translate]"></p>

            <h1 class="text-center mt-4 mb-4" [innerHTML]="['LOGIN.SIGN_IN'|translate]"></h1>

            <app-login></app-login>

            <div class="mt-4 d-flex justify-content-center p-footer">

                <p [innerHTML]="['LOGIN.HAVE_ACCOUNT' | translate]"></p> &nbsp;
                <p class="link-style" [innerHTML]="['LOGIN.REGISTER_NOW' | translate]" [routerLink]="['/authentication/sign-up']"></p>

            </div>
   
    </div>
