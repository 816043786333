import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QrScannerComponent} from './qr-scanner/qr-scanner.component';
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {ZXingScannerModule} from "@zxing/ngx-scanner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ScannerModule} from "./steps/scanner/scanner.module";
import {DiscountsManagerModule} from "./steps/discounts-manager/discounts-manager.module";
import {ScannerResultModule} from "./steps/scanner-result/scanner-result.module";

@NgModule({
  declarations: [
    QrScannerComponent
  ],
    imports: [
        CommonModule,
        CtButtonModule,
        ScannerModule,
        DiscountsManagerModule,
        ScannerResultModule,
        CtSkeletonModule
    ],
  exports: [QrScannerComponent]
})
export class QrScannerModule {
}
