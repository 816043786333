import {FacebookOAuthUserDataResponse} from "@ctsolution/ct-authentication/lib/ct-oauth/facebook-oauth.class";
import {GoogleOAuthUserDataResponse} from "@ctsolution/ct-authentication/lib/ct-oauth/google-oauth.class";
import {AppleOAuthIDToken} from "@ctsolution/ct-authentication";
import {CTBase} from "@ctsolution/ct-framework";

export class RegistrationGuest extends CTBase<RegistrationGuest> {

  Name: string | null = null;
  Surname: string | null = null;
  DateOfBirth: Date | null = null;
  Email: string | null = null;
  Password: string | null = null;
  Phone: string | null = null;
  TermsAndConditions: boolean = false;
  Newsletter: boolean = false;
  LocationOid: number = 0;
  LanguageCode: string = 'it';
  BrokerCode: string | null = null;

  constructor();
  constructor(model: RegistrationGuest);
  constructor(model?: RegistrationGuest) {

    super();
    if (model) this.getClass(model);

  }

  setBirthday(value: Date): RegistrationGuest {

    this.DateOfBirth = value;
    return this;

  }

  setBrokerCode(value: string): RegistrationGuest {

    this.BrokerCode = value;
    return this

  }

  public static createWithFacebookProvider(socialUser: FacebookOAuthUserDataResponse): RegistrationGuest {

    const user: RegistrationGuest = new RegistrationGuest();

    if (socialUser.email) {

      user.Email = socialUser.email;

    }

    if (socialUser.first_name) {

      user.Name = socialUser.first_name;

    }

    if (socialUser.last_name) {

      user.Surname = socialUser.last_name;

    }

    return user;

  }

  public static createWithGoogleProvider<T>(socialUser: GoogleOAuthUserDataResponse): RegistrationGuest {

    const user: RegistrationGuest = new RegistrationGuest();

    if (socialUser.email) {

      user.Email = socialUser.email;

    }

    if (socialUser.name) {

      user.Name = socialUser.name;

    }

    if (socialUser.family_name) {

      user.Surname = socialUser.family_name;

    }

    return user;

  }

  public static createWithAppleProvider(socialUser: AppleOAuthIDToken): RegistrationGuest {

    const user: RegistrationGuest = new RegistrationGuest();

    if (socialUser.email) {

      user.Email = socialUser.email;

    }

    return user;

  }

}
