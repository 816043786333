<div class="faq-cntr p-footer">

  <div class="container">

    <div class="mt-4 mb-3 text-center">
      <h1 class="primary-color"> FAQ </h1>
    </div>
  
    <div class="text-center mb-3 text-uppercase">
      <p [innerHtml]="'FAQ.SUBTITLE' | translate"></p>
    </div>
  
    <ct-expansion-panel *ngFor="let faq of this.faqs" [parameter]="this.getExpansionPanel(faq)">

        <div *ngIf="faq.content" [innerHTML]="faq.content | translate"></div>

    </ct-expansion-panel>
  
  </div>
  
</div>


