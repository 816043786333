<div class="personal-data-cntr position-relative mb-5">

  <app-profile-image></app-profile-image>

  <div class="text-center">

    <h2 class="mb-0">{{ viewModel.name }} {{ viewModel.surname ?? '' }}</h2>

    <p>{{ viewModel.email }}</p>

  </div>

</div>