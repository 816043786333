import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChartComponent} from './chart.component';
import {NgChartsModule} from 'ng2-charts';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    NgChartsModule,
    CtFrameworkModule
  ],
  declarations: [ChartComponent],
  exports: [ChartComponent]
})
export class ChartModule {
}
