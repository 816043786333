<div class="contacts-cntr container">

  <div class="d-flex justify-content-between align-items-baseline">

    <h1 class="primary-color mt-auto mb-auto">Victoria Card</h1>

     <mat-icon class="primary-color close-info-window-icon mt-auto mb-auto" (click)="close()">close</mat-icon>

  </div>

  <p class="mb-4" [innerHtml]="'GENERAL.INFO' | translate"><strong></strong></p>

  <div class="icon-text" (click)="call()">

    <img src="/assets/images/icons/info-detail/phone.svg" style="height: 20px; width: auto; margin-right: 12px;">
    {{ viewModel.ProjectConfigurations.tel }}

  </div>
  <div class="icon-text" (click)="mail()">

    <img src="/assets/images/icons/info-detail/mail.svg" style="height: 20px; width: auto; margin-right: 12px;">
    {{ viewModel.ProjectConfigurations.email }}

  </div>

  <div class="icon-text" (click)="googleMaps()">

    <img src="/assets/images/icons/info-detail/location.svg" class="info-detail-icon">
    {{ viewModel.ProjectConfigurations.address }}

  </div>

  <div class="mt-4">

    <p>
      {{
        "GENERAL.VAT"
          | translate: { vat: viewModel.ProjectConfigurations.VAT }
      }}
    </p>

  </div>

</div>