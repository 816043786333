import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportComponent } from './support.component';
import { CtButtonModule, CtFrameworkModule } from '@ctsolution/ct-framework';
import { ContactsModule } from './contacts/contacts.module';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    CtFrameworkModule,
    ContactsModule
  ],
  declarations: [SupportComponent],
  exports: [SupportComponent]
})
export class SupportModule { }
