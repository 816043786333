<div class="form-input-cntr">

  <ng-container *ngIf="textInputViewModel && textInputViewModel.control">

    <mat-form-field class="p-1 w-100" appearance="outline">


      <mat-label *ngIf="textInputViewModel.label">{{textInputViewModel.label }} </mat-label>


      <input
        matInput
        autocomplete="text-input-off"
        [value]="textInputViewModel.value"
        [type]="textInputViewModel.type ?? 'text'"
        [formControl]="textInputViewModel.control"
        [readonly]="textInputViewModel.readonly"
        [pattern]="textInputViewModel.pattern ?? ''"
        [placeholder]="textInputViewModel.placeholder ?? ''"
        (change)="change.emit()"
        (blur)="blur.emit()"
        >

        <div class="content-cntr" *ngIf="textInputViewModel.img">
          <img src={{textInputViewModel.img}} class="{{textInputViewModel.imgCSS}} ?? ''">
        </div>

      <mat-hint *ngIf="textInputViewModel.hint">
        {{textInputViewModel.hint}}
      </mat-hint>

      <mat-error *ngIf="textInputViewModel.control.hasError('min')" [innerHTML]="['FORM.MIN' | translate]">
      </mat-error>

      <mat-error *ngIf="textInputViewModel.control.hasError('required')" [innerHTML]="['FORM.REQUIRED' | translate]">
      </mat-error>

      <mat-error *ngIf="textInputViewModel.control.hasError('minlength')"
                 [innerHTML]="['FORM.MIN_LENGTH_REQUIRED' | translate]">
      </mat-error>

      <mat-error *ngIf="textInputViewModel.control.hasError('maxlength')"
                 [innerHTML]="['FORM.MAX_LENGTH_REQUIRED' | translate]">
      </mat-error>

      <mat-error *ngIf=" textInputViewModel.control.hasError('passwordStrength')"
                 [innerHTML]="['FORM.PASSWORD_STRENGTH_VALIDATOR' | translate]">
      </mat-error>

      <mat-error *ngIf="textInputViewModel.control.hasError('email')" [innerHTML]="['FORM.VALID_MAIL' | translate]">
      </mat-error>

      <mat-error *ngIf="textInputViewModel.control.hasError('passwordMatch')"
                 [innerHTML]="['FORM.PASSWORD_CONFIRM_INVALID'|translate]">
      </mat-error>

      <mat-error *ngIf="textInputViewModel.control.hasError('pattern')" [innerHTML]="['FORM.PATTERN'|translate]">
      </mat-error>

    </mat-form-field>

  </ng-container>

</div>
