import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationSearchBarComponent } from './location-search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CtFrameworkModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    CtFrameworkModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatGoogleMapsAutocompleteModule
  ],
  declarations: [LocationSearchBarComponent],
  exports: [LocationSearchBarComponent]
})
export class LocationSearchBarModule { }
