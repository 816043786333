<div class="map-info-window-cntr">

  <!-- <mat-icon class="primary-color close-info-window-icon">close</mat-icon> -->

  <div class="row" (click)="goInfoDetail()">

    <div class="col-4">

      <div [ngStyle]="{'background': 'url(' + this.getUrlImage() +') no-repeat center'}"
           class="logo-image box-shadow"></div>


      <p class="text-center text-uppercase pt-2 mb-2"><strong>{{detailCard.Name}}</strong></p>

    </div>

    <div class="col-8">

      <ng-container *ngIf="detailCard.Place">
        <div class="d-flex mt-2 ">

          <mat-icon class="primary-color map-info-icon">place</mat-icon>
          <p class="info-window-content">{{detailCard.Place.Name}}</p>

        </div>
      </ng-container>

      <div class="d-flex">

        <mat-icon class="color-green map-info-icon">schedule</mat-icon>
        <p class="info-window-content">{{ detailCard.IsOpen ? ['GENERAL.OPEN' | translate] : ['GENERAL.CLOSE' |
          translate] }}</p>

      </div>

      <p class="link-style" [innerHtml]="'CT-BUTTON.SHOW-SHOP' | translate " (click)="goInfoDetail()"></p>

    </div>

  </div>

</div>
