import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './navbar.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LocalizationSwitchSelectModule} from "./localization-switch-select/localization-switch-select.module";
import {BackButtonModule} from "../../_components/back-button/back-button.module";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    BackButtonModule,
    CtFrameworkModule,
    LocalizationSwitchSelectModule
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent]
})
export class NavbarModule {
}
