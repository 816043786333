import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareDiscountCodeComponent } from './share-discount-code.component';
import { CtButtonModule, CtFrameworkModule } from '@ctsolution/ct-framework';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {FormInputModule} from "../../../../../components/ct-framework/form-input/form-input.module";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    FormInputModule,
    FormsModule,
    ReactiveFormsModule,
    CtFrameworkModule
  ],
  declarations: [ShareDiscountCodeComponent],
  exports: [ShareDiscountCodeComponent]
})
export class ShareDiscountCodeModule { }
