import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {DiscountModule} from "./special-discount/discount.module";
import {BusinessCreateModule} from "./business-create/business-create.module";
import {BusinessReportModule} from "./business-report/business-report.module";
import {QrScannerModule} from "./_components/qr-scanner/qr-scanner.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    QrScannerModule,
    RouterModule.forChild([
      {
        path: 'store',
        loadChildren: () => BusinessCreateModule
      },
      {
        path: 'discounts',
        loadChildren: () => DiscountModule
      },
      {
        path: 'reports',
        loadChildren: () => BusinessReportModule
      }
    ])
  ]
})
export class BusinessAreaModule {
}
