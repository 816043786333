import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {environment} from 'src/environments/environment';
import {APP_CONTACTS} from "../../../app.module";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {


  viewModel = {

    ProjectConfigurations: APP_CONTACTS

  }

  constructor(
    private dialog: MatDialogRef<ContactsComponent>,
  ) {
  }

  ngOnInit() {
  }

  close() {

    this.dialog.close();

  }

  call = () => window.open(`tel:${this.viewModel.ProjectConfigurations.tel}`, '_blank');
  mail = () => window.open(`mailto:${this.viewModel.ProjectConfigurations.email}`, '_blank');
  googleMaps = () => window.open('https://maps.google.com/?q=' + this.viewModel.ProjectConfigurations.address, '_blank');

}
