import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimeRadioButtonComponent} from './time-radio-button.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    CtFrameworkModule
  ],
  declarations: [TimeRadioButtonComponent],
  exports: [TimeRadioButtonComponent]
})
export class TimeRadioButtonModule {
}
