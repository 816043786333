import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtCarouselIvyComponent } from './ct-carousel-ivy.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  imports: [
    CommonModule,
    IvyCarouselModule,
    CtFrameworkModule
  ],
  declarations: [CtCarouselIvyComponent],
  exports:[CtCarouselIvyComponent]
})
export class CtCarouselIvyModule { }
