import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileButtonComponent} from './profile-button.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FlexLayoutModule,
    RouterModule,
    CtFrameworkModule
  ],
  declarations: [ProfileButtonComponent],
  exports: [ProfileButtonComponent]
})
export class ProfileButtonModule {
}
