import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {CtCarouselIvyParameter} from 'src/app/components/ct-framework/ct-carousel-ivy/class/CtCarouselIvyParameter';
import {RoleHelperService} from 'src/app/core/library/role-helper.service';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";

const GUIDE_BASE_PATH: string = '/assets/images/guide/';

interface carouselGuideConfig {

  directory: string;
  count: number;

}

const BUSINESS_CONFIG: carouselGuideConfig = {

  directory: 'business',
  count: 4

}

const CUSTOMER_CONFIG: carouselGuideConfig = {

  directory: 'customer',
  count: 5

}

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  carouselParameter?: CtCarouselIvyParameter;

  constructor(
    public roleHelper: RoleHelperService,
    public dialogRef: MatDialogRef<GuideComponent>,
    public translate: TranslateService,
    private authenticationService: CtAuthenticationService) {
  }

  ngOnInit() {

    this.setup();

  }

  setup() {

    const configuration: carouselGuideConfig = this.getConfigurationByRole();

    const picturesPaths: string[] = this.getPicturesPathByConfig(configuration);

    this.carouselParameter = CtCarouselIvyParameter
      .Create(picturesPaths)
      .setHeight(550)
      .setArrows(false)
      .setDots(true)
      .setObjectFit('contain');

  }

  getConfigurationByRole(): carouselGuideConfig {

    if(!this.authenticationService.user || this.roleHelper.isCustomer()) {

      return CUSTOMER_CONFIG;

    }

    if (this.roleHelper.isBusiness() || this.roleHelper.isEmployee()) {

      return BUSINESS_CONFIG;

    }

    return CUSTOMER_CONFIG;

  }

  getPicturesPathByConfig(config: carouselGuideConfig): string[] {

    const paths: string[] = [];

    for (let i = 0; i < config.count; i++) {

      paths.push(`${GUIDE_BASE_PATH}/${config.directory}/${this.translate.currentLang}/page_${i + 1}.png`);

    }

    return paths;

  }

  close() {

    this.dialogRef.close();

  }

}








