<div class="home-cntr container p-footer">

    <div class="fixed-top">

        <app-search-bar></app-search-bar>

    </div>

    <div class="home-carousel-cntr m-auto box-shadow mt-5" *ngIf="carouselParameter">

        <ct-carousel-ivy [parameter]="carouselParameter"></ct-carousel-ivy>

    </div>

    <app-button-section></app-button-section>

</div>
