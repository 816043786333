<div class="share-coupon-friends-cntr pt-4">

    <div class="container text-center">

        <h2 class="mb-4 bolder" [innerHTML]="['SHARE.TITLE'|translate]"></h2>

        <p class="bolder mb-4" [innerHTML]="['SHARE.SUBTITLE'|translate]"></p>

        <div class="ticket-cntr">
          <app-ticket-discount-code></app-ticket-discount-code>
        </div>

        <app-share-discount-code></app-share-discount-code>

    </div>

</div>
