import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MyCouponConfiguration} from "./my-coupon.configuration";
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {AppGeneralService} from "../../../../../../../core/library/app-general.service";
import {CouponHelperService} from "../../../../../../../core/library/coupon-helper.service";
import {ScannerQrHelperService} from "../../../../../business-area/_components/qr-scanner/scanner-qr-helper.service";
import {CouponRequestDTO} from "../../../../../../../core/interfaces/coupon-request";
import {UserHelperService} from "../../../../../../../core/library/user-helper.service";
import {Coupon} from "../../../../../../../core/classes/coupon";

@Component({
  selector: 'app-my-coupon',
  templateUrl: './my-coupon.component.html',
  styleUrls: ['./my-coupon.component.scss']
})
export class MyCouponComponent implements OnInit {

  @Input() configuration!: MyCouponConfiguration;
  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();

  generateQRButton: CtButtonParameters = CtButtonParameters.create(() => {

    this.userHelperService
      .checkIfUserProfileIsEnabled()
      .then((enabled: boolean) => {

        if (enabled) {

          this.generateQR()

        }

      })

  })
    .setContent('CTBUTTON.QR-GENERA')
    .setCSS("generalButton generate-qr-button");

  shopDetailButton: CtButtonParameters = CtButtonParameters
    .create(() => this.shopDetail())
    .setContent('CT-BUTTON.SHOW-SHOP')
    .setCSS("generalButton ps-0 shop-detail-button");

  constructor(
    private general: AppGeneralService,
    private couponHelper: CouponHelperService,
    private scannerQrHelperService: ScannerQrHelperService,
    private userHelperService: UserHelperService) {
  }

  ngOnInit(): void {

    if(this.configuration.coupon?.CouponTemplate) {

      this.configuration.coupon.CouponTemplate = new Coupon(this.configuration.coupon?.CouponTemplate)

    }

  }

  generateQR() {

    if (this.configuration.coupon?.CouponTemplate?.isMyVictoriaCard()) {

      this.scannerQrHelperService.openQrGenerator();

    } else {

      const request: CouponRequestDTO = <CouponRequestDTO>{
        Code: this.configuration.coupon?.Code,
        CouponRequest: this.configuration.coupon?.CouponRequest
      };

      this.couponHelper.useCoupon(request)
        .beforeClosed()
        .subscribe(() => this.updateList.emit())

    }

  }


  shopDetail() {

    this.general.navigateTo('/research/list/detail', {queryParams: {id: this.configuration.coupon?.ProductOid}});

  }

}
