import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegistrationFormComponent} from './registration-form.component';
import {CtButtonModule, CtFrameworkModule} from '@ctsolution/ct-framework';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  LocalizationSwitchSelectModule
} from "../../../layout/full/navbar/localization-switch-select/localization-switch-select.module";
import {FormInputModule} from "../../../components/ct-framework/form-input/form-input.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    RouterModule,
    MatCheckboxModule,
    CtButtonModule,
    CtFrameworkModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormInputModule,
    LocalizationSwitchSelectModule
  ],
  declarations: [RegistrationFormComponent],
  exports: [RegistrationFormComponent],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'it-IT'}]
})
export class RegistrationFormModule {
}
