import {Injectable} from "@angular/core";
import {MatSnackBar, MatSnackBarRef, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  generalMessage(message: string,
                 interpolationTranslateParams: object | undefined = undefined,
                 duration: number = 3000,
                 position: MatSnackBarVerticalPosition = 'top',
                 panelClass: string = 'general-snackbar'): MatSnackBarRef<any> {

    let translateValue = this.translate.instant(message, interpolationTranslateParams);
    return this.snackbar.open(translateValue, 'X', {
      duration: duration,
      verticalPosition: position,
      panelClass: panelClass

    });
  }

}
