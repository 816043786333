import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {Product} from 'src/app/core/classes/product';
import {IFile} from "@ctsolution/ct-file-management";
import {MatDialog} from '@angular/material/dialog';
import {EditGalleryComponent} from './edit-gallery/edit-gallery.component';
import {BusinessProductController} from "../../../../../../core/controllers/business-product.controller";
import {FileType} from "../../../../../../core/enum/file-type.enum";
import {
  CtCarouselIvyParameter
} from "../../../../../../components/ct-framework/ct-carousel-ivy/class/CtCarouselIvyParameter";

@Component({
  selector: 'app-images-section',
  templateUrl: './images-section.component.html',
  styleUrls: ['./images-section.component.scss']
})
export class ImagesSectionComponent {

  @Input() productData: Product | undefined;

  carouselParameter: CtCarouselIvyParameter = CtCarouselIvyParameter
    .Create(['/assets/images/background-svg/back_subscribe.png'])
    .setHeight(200)
    .setArrows(false)
    .setDots(false);

  editHeaderImages: CtButtonParameters = CtButtonParameters
    .create(() => this.openGalleryDialog())
    .setMaterialIcon("edit")
    .setCSS("box-shadow edit-button", true);

  constructor(private dialog: MatDialog, private businessProductController: BusinessProductController) {
  }

  ngOnInit() {

    this.setCarousel();

  }

  getProductData() {

    this.businessProductController
      .list()
      .then((data: any) => {

        if (data.Result && data.Result.length > 0) {

          this.productData = data.Result[0];
          this.setCarousel();

        }

      });

  }

  private setCarousel() {

    if ((this.productData?.Files ?? []).length > 0) {

      const previews: string[] = (this.productData?.Files ?? [])
        .filter((element: IFile) => [FileType.OTHER, FileType.COVER].includes(element.Type) && (!!element.Position || element.Position === 0))
        .sort((a: IFile, b: IFile) => a.Position - b.Position)
        .map((file: IFile) => file.PreviewUrl);

      this.carouselParameter = CtCarouselIvyParameter.Create(previews)
        .setHeight(200).setArrows(false).setDots(false);

    }

  }

  openGalleryDialog() {

    if (this.productData) {

      this.dialog
        .open(EditGalleryComponent, {disableClose: true})
        .afterClosed()
        .subscribe((result: Product | null) => {

          if (result) {

            this.productData = result;
            this.setCarousel();

          }

        });

    }

  }

}
